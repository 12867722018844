<div class="container">
  <h2 class="title">{{ 'SIGNOUTMODAL.TITLE' | translate }}</h2>
  <mat-divider></mat-divider>
  <p class="description">{{ 'SIGNOUTMODAL.DESCRIPTION' | translate }}</p>
  <mat-divider></mat-divider>
  <mat-dialog-actions>
    <hlt-button
      class="cancel"
      [mat-dialog-close]="false"
      [text]="'BUTTONS.CANCEL' | translate"
    ></hlt-button>
    <hlt-button
      class="sign-out"
      [mat-dialog-close]="true"
      [text]="'BUTTONS.SIGNOUT' | translate"
    ></hlt-button>
  </mat-dialog-actions>
</div>
