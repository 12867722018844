import { AuthApiActions, AuthLogoutActions } from '@hlt-app/auth/actions';
import { createReducer, on } from '@ngrx/store';
import { auth } from 'firebase';

export const statusFeatureKey = 'status';

export interface State {
  user: auth.IdTokenResult | null;
}

export const initialState: State = {
  user: null
};

export const reducer = createReducer(
  initialState,
  on(AuthApiActions.loginSuccess, (state, { user }) => ({ ...state, user })),
  on(AuthLogoutActions.logout, () => initialState)
);

export const getFirebaseUser = (state: State) => state.user;
