import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthPipeGenerator, loggedIn } from '@angular/fire/auth-guard';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FireGuard implements CanActivate {
  constructor(
    private router: Router,
    private localize: LocalizeRouterService,
    private afAuth: AngularFireAuth
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const authPipeFactory =
      (next.data.authGuardPipe as AuthPipeGenerator) || (() => loggedIn);
    return this.afAuth.user.pipe(
      take(1),
      authPipeFactory(next, state),
      map((can) => {
        if (typeof can === 'boolean') {
          return can;
        } else {
          return this.router.createUrlTree(
            this.localize.translateRoute(can) as any[]
          );
        }
      })
    );
  }
}

export const canActivate = (
  pipe: AuthPipeGenerator,
  data = {},
  guards = []
) => ({
  canActivate: [FireGuard, ...guards],
  data: { authGuardPipe: pipe, ...data }
});
