import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { UserRoleEnum } from '@hlt-app/auth/models';
import { auth, User } from 'firebase';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompletedGuard implements CanActivate {
  constructor(
    private router: Router,
    private localize: LocalizeRouterService,
    private afAuth: AngularFireAuth
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.afAuth.user.pipe(
      take(1),
      switchMap((user: User) => from(user.getIdTokenResult())),
      switchMap((idTokenResult: auth.IdTokenResult) => {
        const claims = idTokenResult.claims;

        if (claims.role === UserRoleEnum.User && claims.completed) {
          return of(null);
        }
        return of(true);
      }),
      map((res) => {
        if (res === null) {
          return this.router.createUrlTree(
            this.localize.translateRoute(['/completed']) as any[]
          );
        }
        return true;
      })
    );
  }
}
