<div class="container--small" role="main">
  <h1 class="title">{{ 'LOGIN.TITLE' | translate }}</h1>
  <p class="description">{{ 'LOGIN.DESCRIPTION' | translate }}</p>

  <form (ngSubmit)="submit()" [formGroup]="form">
    <hlt-text-input
      [form]="form"
      [control]="form.get('email')"
      [placeholder]="'LOGIN.EMAILPLACEHOLDER' | translate"
      type="email"
      [label]="'LOGIN.EMAIL' | translate"
    ></hlt-text-input>

    <hlt-text-input
      [form]="form"
      [control]="form.get('password')"
      type="password"
      [label]="'LOGIN.PASSWORD' | translate"
    ></hlt-text-input>
    <ng-container *ngIf="error" class="register__error">
      <hlt-firebase-errors [error]="error"></hlt-firebase-errors>
    </ng-container>

    <div class="buttons">
      <a class="forgot" [routerLink]="'/reset-password' | localize">{{
        'LOGIN.FORGOTLINK' | translate
      }}</a>

      <hlt-button
        [text]="'LOGIN.LOGINBUTTON' | translate"
        type="submit"
      ></hlt-button>

      <hlt-route-button
        class="register-button"
        [text]="'LOGIN.REGISTERBUTTON' | translate"
        route="/register"
        [ghost]="true"
      ></hlt-route-button>
    </div>
  </form>
</div>
