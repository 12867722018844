import { isNumberString } from 'class-validator';
import { Answers, TypeAnswer } from '../interfaces/type.answer';

export { isNumberString } from 'class-validator';

export const requireAnswer = (
  requiredProperty: string,
  answers: Answers,
  requiree?: string
): TypeAnswer => {
  if (!answers || !answers.hasOwnProperty(requiredProperty)) {
    throw new Error(
      `${
        requiree || 'Unknown'
      }: preconditions not met, ${requiredProperty} missing`
    );
  }
  return answers[requiredProperty];
};

export const requireAnswerValue = (
  requiredProperty: string,
  answers: Answers,
  requiree?: string
) => {
  let answer: TypeAnswer = requireAnswer(requiredProperty, answers, requiree);
  if (answer === null) {
    throw new Error(
      `${
        requiree || 'Unknown'
      }: preconditions not met, ${requiredProperty} has not been answered`
    );
  }

  // we do a lot of comparisons to numbers.. so set these as numbers if it is a numeric string
  if (
    !!answer &&
    typeof answer === 'string' &&
    isNumberString(answer, { no_symbols: true })
  ) {
    answer = Number(answer);
  }

  return answer;
};

export const hasAnswerValue = (property: string, answers: Answers): boolean => {
  return (
    answers !== null &&
    answers.hasOwnProperty(property) &&
    answers[property] !== null
  );
};
