<div class="container--small" role="main">
  <form (ngSubmit)="submit()" [formGroup]="form">
    <h1 class="title">{{ 'FORGOTPASSWORD.TITLE' | translate }}</h1>
    <p class="description">{{ 'FORGOTPASSWORD.DESCRIPTION' | translate }}</p>
    <br />
    <p class="description">{{ 'FORGOTPASSWORD.GUIDE' | translate }}</p>

    <hlt-text-input
      [label]="'FORGOTPASSWORD.EMAIL' | translate"
      [form]="form"
      [control]="form.get('email')"
      [placeholder]="'FORGOTPASSWORD.EMAILPLACEHOLDER' | translate"
      type="email"
    ></hlt-text-input>

    <p *ngIf="requestStatus?.success">
      {{ 'RESETPASSWORD.CHECKEMAIL' | translate }}
    </p>

    <p *ngIf="requestStatus?.error">
      {{ 'RESETPASSWORD.NOUSER' | translate }}
    </p>

    <ng-container *ngIf="error">
      <hlt-firebase-errors [error]="error"></hlt-firebase-errors>
    </ng-container>

    <div class="buttons">
      <hlt-button
        *ngIf="!requestStatus?.success"
        class="button"
        [text]="'FORGOTPASSWORD.PASSWORDBUTTON' | translate"
        type="submit"
      ></hlt-button>
      <hlt-route-button
        *ngIf="requestStatus?.success"
        class="button"
        [text]="'FORGOTPASSWORD.BACKBUTTON' | translate"
      ></hlt-route-button>
    </div>

    <p class="contact">{{ 'FORGOTPASSWORD.CONTACT' | translate }}</p>
  </form>
</div>
