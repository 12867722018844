import { Answers } from '@hlt-shared/Questions/interfaces/type.answer';
import { createAction, props } from '@ngrx/store';

const setHabitData = createAction(
  '[Habit] Set Answer data',
  props<{ answers: Answers }>()
);

const saveHabit = createAction(
  '[Habit] Save answer data',
  props<{ answers: Answers }>()
);

const saveHabitSuccess = createAction('[Habit] Save Answer Data Success');

const saveHabitFailure = createAction(
  '[Habit] Save Answer Data Failure',
  props<{ error: any }>()
);

export const HabitActions = {
  setHabitData,
  saveHabit,
  saveHabitSuccess,
  saveHabitFailure
};
