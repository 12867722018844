import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { DiaryReducers } from '@hlt-app/study/reducers';
import { OptionGroupQuestion } from '@hlt-shared/Questions/classes/OptionGroupQuestion';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, first, map } from 'rxjs/operators';

@Component({
  selector: 'hlt-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
  @Input() public form: FormGroup;
  // @todo: type
  @Input() public question;
  public values: any = false;

  constructor(
    private store: Store,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (
      !!this.question &&
      !!this.question.key &&
      this.question.key === 'M_MTK'
    ) {
      this.store
        .pipe(select(DiaryReducers.selectStudyDiaryAnswers))
        .pipe(
          filter((answers) => !!answers),
          filter(
            (answers) => typeof answers.M_LTK !== 'undefined' && !!answers.M_LTK
          ),
          first(),
          map((answers) => {
            this.values = {
              M_LTK: `<span class="blue">${this.getLabel(
                answers,
                'M_LTK'
              )}</span>`,
              M_LAHTOAIKA: `<span class="blue">${answers.M_LAHTOAIKA}</span>`
            };
          })
        )
        .subscribe();
    }
  }

  getLabel(trip, key) {
    if (!!trip[key]) {
      let answer = trip[key];
      if (typeof answer === 'string') {
        answer = Number(answer);
      }
      const translateValue =
        (this.question as OptionGroupQuestion).getLabelFromValue(answer) ||
        null;

      if (translateValue) {
        return this.translateService.instant(translateValue);
      }
    }
    return '';
  }

  get control(): AbstractControl {
    return this.form.get(this.question.key) as AbstractControl;
  }
}
