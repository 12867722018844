import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FeaturesEntity } from '@hlt-app/shared/models/featureCollection/featuresEntity';
import { DigiTransitService } from '@hlt-app/shared/services/digi-transit.service';
import { Layer } from '@hlt-shared/Questions/interfaces/type.layers';
import { icon, latLng, LatLng, Map, marker, tileLayer } from 'leaflet';
import { of } from 'rxjs';
import { first, map, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'hlt-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit {
  public options = {
    layers: [
      tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        minZoom: 5
      })
    ],
    attributionControl: false,
    zoom: 13,
    center: latLng(60.192059, 24.945831)
  };

  public icon = {
    icon: icon({
      iconSize: [46, 57],
      iconAnchor: [23, 48],
      iconUrl: 'assets/svg/marker.svg',
      iconRetinaUrl: 'assets/svg/marker.svg',
      shadowUrl: ''
    })
  };

  public layers = [];

  public title = '';
  public leafletMap: Map;
  public locationFromInput;
  public locationFromUser;

  public geoJson: FeaturesEntity | null = null;

  @Output() closeMap = new EventEmitter<FeaturesEntity | null>();

  @Input() lat: FormControl;
  @Input() lon: FormControl;
  @Input() street: FormControl;
  @Input() localAdmin: FormControl;
  @Input() country: FormControl;

  constructor(private digiTransitService: DigiTransitService) {}

  ngOnInit(): void {
    if (!!this.lat && !!this.lon && !!this.lat.value && !!this.lon.value) {
      this.locationFromInput = latLng(this.lat.value, this.lon.value);
      this.layers = [marker(this.locationFromInput, this.icon)];
      this.title = this.getTitleString();
    } else if (!!this.street && !!this.localAdmin) {
      let searchString = '';
      const searchLayers: Array<Layer> = [];
      if (this.street.value) {
        searchString += this.street.value;
        searchLayers.push('address', 'street', 'venue', 'neighbourhood');
      }
      if (this.localAdmin.value) {
        if (searchString) searchString += ', ';
        searchString += this.localAdmin.value;
        searchLayers.push('localadmin');
      }
      if (searchString !== '') {
        this.digiTransitService
          .autocomplete(searchString.toLowerCase(), searchLayers)
          .pipe(
            take(1),
            map((feat) => {
              if (feat && feat.length) {
                return feat;
              }
              return false;
            }),
            switchMap((featEntity) => {
              if (featEntity) {
                return of(featEntity);
              } else if (
                !!this.localAdmin &&
                !!this.localAdmin.value &&
                this.localAdmin.value !== ''
              ) {
                return this.digiTransitService
                  .autocomplete(this.localAdmin.value.toLowerCase(), [
                    'localadmin'
                  ])
                  .pipe(first());
              } else {
                return of(null);
              }
            }),
            map((feat) => {
              if (feat && feat.length) {
                const firstEntity = feat[0];
                this.setLocFromAutocomplete(firstEntity);
              }
            })
          )
          .subscribe();
      }
    }
  }

  ngAfterViewInit() {
    if (!!this.leafletMap) {
      this.leafletMap.invalidateSize();
    }
  }

  setLocFromAutocomplete(firstEntity) {
    this.title = `${firstEntity.properties.name}, ${firstEntity.properties.localadmin}`;
    this.locationFromInput = latLng(
      firstEntity.geometry.coordinates[1],
      firstEntity.geometry.coordinates[0]
    );
    this.layers = [marker(this.locationFromInput, this.icon)];

    if (!!this.leafletMap) {
      this.leafletMap.setView(
        this.locationFromInput,
        this.leafletMap.getZoom()
      );
    }
  }

  getTitleString() {
    let searchString = '';
    if (!!this.street && !!this.localAdmin) {
      if (!!this.street.value && !!this.localAdmin.value) {
        searchString = `${this.street.value}, ${this.localAdmin.value}`;
      } else if (!!this.street.value) {
        searchString = `${this.street.value}`;
      } else if (!!this.localAdmin.value) {
        searchString = `${this.localAdmin.value}`;
      }
    }
    return searchString;
  }

  close() {
    this.closeMap.emit(null);
  }

  onMapReady(leafletMap: Map) {
    this.leafletMap = leafletMap;
    if (!!this.locationFromInput) {
      this.leafletMap.setView(
        this.locationFromInput,
        this.leafletMap.getZoom()
      );
    }
    if (!!this.locationFromUser) {
      this.leafletMap.setView(this.locationFromUser, this.leafletMap.getZoom());
    }
  }

  locate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.locationFromUser = latLng(
          position.coords.latitude,
          position.coords.longitude
        );
        this.reverseGeocode(this.locationFromUser);
        this.layers = [marker(this.locationFromUser, this.icon)];
        this.leafletMap.setView(
          this.locationFromUser,
          this.leafletMap.getZoom()
        );
      });
    }
  }

  reverseGeocode(latlng: LatLng) {
    this.digiTransitService
      .reverse(latlng)
      .pipe(
        first(),
        map((value) => {
          if (value.length) {
            const item = value[0];
            this.geoJson = item;
            this.title = `${item.properties.name}, ${item.properties.localadmin}`;
          } else {
            this.geoJson = null;
            this.title = null;
          }
        })
      )
      .subscribe();
  }

  setLocation(event: { latlng: LatLng }) {
    this.layers = [marker(event.latlng, this.icon)];
    this.leafletMap.setView(event.latlng, this.leafletMap.getZoom());
    this.reverseGeocode(event.latlng);
  }

  addLocation() {
    this.closeMap.emit(this.geoJson);
  }
}
