import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import * as firebase from 'firebase/app';
import { Subscription } from 'rxjs';

@Component({
  selector: 'hlt-phone-login',
  templateUrl: './phone-login.component.html',
  styleUrls: ['./phone-login.component.scss']
})
export class PhoneLoginComponent implements OnInit, OnDestroy, AfterViewInit {
  form: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, [
      Validators.required,
      Validators.minLength(6)
    ])
  });

  verifyForm: FormGroup = new FormGroup({
    code: new FormControl(null, [Validators.required, Validators.minLength(6)])
  });
  public signedIn = false;
  public error = '';
  public verifyError = '';
  private recaptchaVerifier;
  private resolver;
  private verificationId;
  private sub: Subscription;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private localize: LocalizeRouterService
  ) {}

  get email() {
    return this.form.get('email') as FormControl;
  }

  get password() {
    return this.form.get('password') as FormControl;
  }

  get code() {
    return this.verifyForm.get('code') as FormControl;
  }

  ngOnInit(): void {
    this.sub = this.afAuth.authState.subscribe(async (user) => {
      if (user) {
        const auth = await user.getIdTokenResult();

        if (!!auth.claims && auth.claims.role && auth.claims.role === 1) {
          this.router.navigate([this.localize.translateRoute('/study')]);
        }

        if (!!auth.claims && auth.claims.role && auth.claims.role === 5) {
          this.router.navigate([
            this.localize.translateRoute('/kantar/select-user')
          ]);
        }
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngAfterViewInit() {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      '2fa-captcha',
      {
        size: 'invisible'
      }
    );
  }

  async signIn() {
    if (this.form.valid) {
      try {
        await this.afAuth.auth.signInWithEmailAndPassword(
          this.email.value,
          this.password.value
        );
      } catch (err) {
        if (err.code === 'auth/multi-factor-auth-required') {
          // The user is enrolled in MFA, must be verified
          this.signedIn = true;
          this.resolver = err.resolver;
        } else {
          this.signedIn = false;
          this.error = err.message;
        }
      }

      const phoneOpts = {
        multiFactorHint: this.resolver.hints[0],
        session: this.resolver.session
      };

      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

      this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneOpts,
        this.recaptchaVerifier
      );
    }
  }

  async verify() {
    if (this.verifyForm.valid) {
      const cred = firebase.auth.PhoneAuthProvider.credential(
        this.verificationId,
        this.code.value
      );

      const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
        cred
      );

      const credential = await this.resolver
        .resolveSignIn(multiFactorAssertion)
        .catch((e) => {
          this.verifyError = e.message;
        });

      if (!!credential.user) {
        const auth = await credential.user.getIdTokenResult();
        if (!auth.claims.role) {
          this.router.navigate([
            this.localize.translateRoute('/kantar/register')
          ]);
        }
      }
    }
  }
}
