import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureCollection } from '@hlt-app/shared/models/featureCollection/featureCollection';
import { Layer } from '@hlt-shared/Questions/interfaces/type.layers';
import { LatLng } from 'leaflet';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DigiTransitService {
  private geocodingApi = 'https://api.digitransit.fi/geocoding/v1';
  private nomatimApi = 'https://nominatim.openstreetmap.org';

  constructor(private http: HttpClient) {}

  autocomplete(text: string, layers?: Layer[]) {
    const params = { text };
    Object.assign(params, layers ? { layers: layers.join(',') } : {});
    return this.http
      .get<FeatureCollection>(`${this.geocodingApi}/autocomplete`, {
        headers: {
          'digitransit-subscription-key': environment.digitransitSubscriptionKey
        },
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return res.body.features;
        })
      );
  }

  reverse(latLon: LatLng, layers?: Layer[]) {
    const params = {
      'point.lat': latLon.lat.toString(),
      'point.lon': latLon.lng.toString(),
      size: '1'
    };
    Object.assign(
      params,
      layers ? { layers: layers.join(',') } : { layers: 'address' }
    );
    return this.http
      .get<FeatureCollection>(`${this.geocodingApi}/reverse`, {
        headers: {
          'digitransit-subscription-key': environment.digitransitSubscriptionKey
        },
        observe: 'response',
        params
      })
      .pipe(
        switchMap((res) => {
          if (res.body.features.length) {
            return of(res.body.features);
          }
          return this.reverseNomatim(latLon);
        })
      );
  }

  reverseNomatim(latLon: LatLng) {
    const params = {
      lat: latLon.lat.toString(),
      lon: latLon.lng.toString(),
      format: 'geocodejson',
      zoom: '18'
      // @todo: this possibly should include frantic email address for reporting misuse
      // email: 'janne.aalto@frantic.com'
    };

    return this.http
      .get<FeatureCollection>(`${this.nomatimApi}/reverse`, {
        headers: {
          'digitransit-subscription-key': environment.digitransitSubscriptionKey
        },
        observe: 'response',
        params
      })
      .pipe(
        map((res) => {
          return res.body.features;
        }),
        map((feature) => {
          if (!!feature[0].properties.geocoding.country) {
            feature[0].properties.country =
              feature[0].properties.geocoding.country;
          }

          if (!!feature[0].properties.geocoding.city) {
            feature[0].properties.localadmin =
              feature[0].properties.geocoding.city;
          } else if (!!feature[0].properties.geocoding.locality) {
            feature[0].properties.localadmin =
              feature[0].properties.geocoding.locality;
          }

          if (!!feature[0].properties.geocoding.name) {
            feature[0].properties.name = feature[0].properties.geocoding.name;
          } else if (!!feature[0].properties.geocoding.street) {
            feature[0].properties.name = feature[0].properties.geocoding.street;
          }

          if (
            !!feature[0].properties.geocoding.housenumber &&
            !!feature[0].properties.name
          ) {
            feature[0].properties.name +=
              ' ' + feature[0].properties.geocoding.housenumber;
          }

          return feature;
        })
      );
  }
}
