import { OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { UserReducers } from '@hlt-app/study/reducers';
import { QuestionsController } from '@hlt-shared/Questions/classes/Questions.controller';
import { QuestionsFactory } from '@hlt-shared/Questions/classes/Questions.factory';
import { Answers } from '@hlt-shared/Questions/interfaces/type.answer';
import { TypeQuestion } from '@hlt-shared/Questions/interfaces/type.question';
import { TypeQuestionClasses } from '@hlt-shared/Questions/interfaces/type.questionClasses';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

export class FormController implements OnDestroy {
  public question$: BehaviorSubject<
    TypeQuestionClasses[]
  > = new BehaviorSubject(null);
  public total$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public current$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public completed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public change = 0;
  public changed$: BehaviorSubject<number> = new BehaviorSubject<number>(
    this.change
  );
  public questionsController: QuestionsController | null;

  protected questions: TypeQuestion[];
  protected Answers$: Observable<Answers>;
  protected type: string;
  protected completedRoute: string | boolean = false;
  protected backRoute = '/study';

  protected claims$: Observable<any> = this.store.pipe(
    select(UserReducers.selectUserClaims)
  );
  private guardian$: Observable<any> = this.store.pipe(
    select(UserReducers.selectUserGuardian)
  );
  private generateQuestions$: Subscription;

  private guardian: boolean;
  private claims: any;

  constructor(
    protected store: Store,
    protected router: Router,
    protected localize: LocalizeRouterService
  ) {}

  ngOnDestroy() {
    this.questionsController = null;
    if (this.generateQuestions$) {
      this.generateQuestions$.unsubscribe();
    }
  }

  generateQuestions(answers: Answers = {}) {
    this.generateQuestions$ = combineLatest([this.claims$, this.Answers$])
      .pipe(
        filter(([claims, values]) => !!claims),
        switchMap(([claims, values]) =>
          this.guardian$.pipe(map((guardian) => ({ claims, values, guardian })))
        ),
        map(({ claims, values, guardian }) => {
          const userAnswers = { ...values };
          if (userAnswers.completed) {
            delete userAnswers.completed;
          }
          return { claims, userAnswers, guardian };
        }),
        map(({ claims, userAnswers, guardian }) => {
          if (
            this.questionsController &&
            this.guardian === guardian &&
            this.claims === claims
          ) {
            this.setAnswer(userAnswers);
            this.setClaims(claims);
          } else {
            this.guardian = guardian;
            this.claims = claims;
            this.questionsController = new QuestionsFactory(
              this.questions,
              claims,
              {
                ...userAnswers,
                ...answers
              },
              guardian
            ).make();
            this.question$.next(this.questionsController.continue());
            this.total$.next(this.questionsController.count);
            this.current$.next(this.questionsController.currentPage());
            this.completed$.next(false);
            this.changed$.next(this.change++);
          }
        })
      )
      .subscribe();
  }

  getByKey(key) {
    return this.questionsController.getByKey(key, false);
  }

  setAnswer(answer: Answers): void {
    this.questionsController.setAnswers(answer);
    this.changed$.next(this.change++);
  }

  setClaims(claims: Answers): void {
    this.questionsController.setClaims(claims);
  }

  next() {
    const next = this.questionsController.next();
    this.question$.next(next);
    if (!next) {
      this.completed$.next(true);
    }

    this.total$.next(this.questionsController.count);
    this.current$.next(this.questionsController.currentPage());

    if (!next && typeof this.completedRoute === 'string') {
      this.questionsController.index = 0;
      this.question$.next(this.questionsController.current());
      this.total$.next(this.questionsController.count);
      this.current$.next(this.questionsController.currentPage());
      this.router.navigate([this.localize.translateRoute(this.completedRoute)]);
    }
  }

  init() {
    if (this.questionsController) {
      this.question$.next(this.questionsController.current());
      this.total$.next(this.questionsController.count);
      this.current$.next(this.questionsController.currentPage());
    }
  }

  reset() {
    if (this.questionsController) {
      this.completed$.next(false);
      this.questionsController.reset();
    }
  }

  destroy() {
    if (this.generateQuestions$) {
      this.generateQuestions$.unsubscribe();
    }
    if (this.questionsController) {
      this.questionsController = null;
    }
    this.completed$.next(false);
  }

  previous() {
    const prev = this.questionsController.previous();

    if (prev === null) {
      this.questionsController.index = 0;
      this.question$.next(this.questionsController.current());
      this.total$.next(this.questionsController.count);
      this.current$.next(this.questionsController.currentPage());
      this.router.navigate([this.localize.translateRoute(this.backRoute)]);
    }

    this.question$.next(prev);
    this.total$.next(this.questionsController.count);
    this.current$.next(this.questionsController.currentPage());

    return prev ? true : null;
  }

  getAnswers() {
    if (this.questionsController) {
      this.questionsController.continue();
      const allowedKeys = this.questionsController.allowedKeys;

      return allowedKeys.map((key) => {
        const question = this.questionsController.getByKey(key, false);
        return this.questionsController.getSiblings([question]);
      });
    }
  }
}
