import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { LocalizeParser } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
  selector: 'hlt-language-button',
  templateUrl: './language-button.component.html',
  styleUrls: ['./language-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LanguageButtonComponent implements OnInit, OnDestroy {
  public languages: Array<string> = [];
  public currentLang: string;
  @Input() menuClicked: boolean;
  private translationSub: Subscription;
  constructor(
    private translate: TranslateService,
    private localize: LocalizeParser
  ) {
    this.translationSub = this.translate
      .getTranslation(this.localize.currentLang)
      .subscribe((lang) => {
        for (const language in lang.LANGUAGES) {
          if (language) {
            this.languages.push(lang.LANGUAGES[language]);

            if (this.localize.currentLang.toUpperCase().includes(language)) {
              this.currentLang = lang.LANGUAGES[language];
            }
          }
        }
      });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.translationSub.unsubscribe();
  }
  changeLang(value: string) {
    const selectedLanguage = value;
    switch (selectedLanguage) {
      case 'Englanti':
      case 'English':
      case 'Англия': {
        if (this.menuClicked) {
          value = '/en/survey';
        } else {
          value = '/en';
        }
        break;
      }
      case 'Ruotsi':
      case 'Швеция':
      case 'Svenska': {
        if (this.menuClicked) {
          value = '/sv/undersökning';
        } else {
          value = '/sv';
        }
        break;
      }
      case 'Suomi':
      case 'Finnish':
      case 'Финляндия': {
        if (this.menuClicked) {
          value = '/fi/tutkimus';
        } else {
          value = '/fi';
        }
        break;
      }
      case 'Venäjä':
      case 'Russian':
      case 'Россия': {
        if (this.menuClicked) {
          value = '/ru/issledovanie';
        } else {
          value = '/ru';
        }
        break;
      }
      default: {
        if (this.menuClicked) {
          value = '/fi/tutkimus';
        } else {
          value = '/fi';
        }
        break;
      }
    }
    return value;
  }
}
