import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FormFactoryService } from '@hlt-app/shared/services/form-factory.service';
import { BackgroundReducers, UserReducers } from '@hlt-app/study/reducers';
import { BackgroundQuestionsService } from '@hlt-app/study/services/background-questions.service';
import { ArrayQuestion } from '@hlt-shared/Questions/classes/ArrayQuestion';
import { isNumberString } from '@hlt-shared/Questions/helper/require-answer';
import { isTypeKeyBooleanPair } from '@hlt-shared/Questions/interfaces/type.answer';
import {
  isOptionGroupQuestion,
  isOptionQuestion
} from '@hlt-shared/Questions/interfaces/type.questionClasses';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { DialogQuestionComponent } from '../dialog-question/dialog-question.component';

@Component({
  selector: 'hlt-array-question',
  templateUrl: './array-question.component.html',
  styleUrls: ['./array-question.component.scss']
})
export class ArrayQuestionComponent implements OnInit, OnDestroy {
  @Input() public form: FormGroup;
  @Input() public question: ArrayQuestion;
  public array: FormArray;
  public firstItem$: Observable<
    | {
        value: string;
        title: string;
      }[]
    | boolean
  >;
  public disableAdd = false;

  public maxLength: number;
  public totalAmount = 0;
  public guardian = false;
  private sub: Subscription;
  private guardianSub: Subscription;
  private disableAddSub: Subscription;
  private guardian$: Observable<any> = this.store.pipe(
    select(UserReducers.selectUserGuardian)
  );
  constructor(
    private store: Store,
    private dialog: MatDialog,
    private backgroundQuestionsService: BackgroundQuestionsService,
    private formFactoryService: FormFactoryService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.maxLength = this.question.validation.length as number;
    this.array = this.form.controls[this.question.key] as FormArray;
    this.firstItem$ = this.getFirstItem();

    // better place for this would be in radio-other, but we can't access the add more items button
    if (!!this.question && this.question.key === 'M_VAIHTOKULKUTAPA') {
      this.subscribeToOtherWayOfTraveling();
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.disableAddSub) {
      this.disableAddSub.unsubscribe();
    }
    if (this.guardianSub) {
      this.guardianSub.unsubscribe();
    }
  }

  get arrayControls(): any {
    return this.array.controls;
  }

  getFirstItem() {
    if (this.question.key !== 'T_PERHELKM') {
      return of(false);
    }
    this.guardianSub = this.guardian$.subscribe(
      (guardian) => (this.guardian = guardian)
    );
    this.totalAmount = 1;
    return this.store.pipe(
      select(BackgroundReducers.selectStudyBackgroundAnswers),
      map((answer) => ({
        T_SUKUPUOLI: answer.T_SUKUPUOLI,
        T_IKA: answer.T_IKA,
        T_AJOKORTTI: answer.T_AJOKORTTI
      })),
      map((answer) =>
        Object.keys(answer).map((key) => {
          const question = this.backgroundQuestionsService.getByKey(key);
          return this.getAnswer(question, answer[key]);
        })
      )
    );
  }

  translateInstant(key) {
    if (!!key && key !== '') {
      return this.translate.instant(key);
    }
    return key;
  }

  getValue(question, value): { value: string; title: string } {
    if (isOptionQuestion(question)) {
      if (isTypeKeyBooleanPair(value)) {
        if (value[1]) {
          return {
            title: '',
            value: this.translateInstant(question.getLabelFromValue(1))
          };
        } else {
          return {
            title: '',
            value: ''
          };
        }
      }

      if (value === null) {
        return {
          title: '',
          value: '-'
        };
      }

      return {
        title: '',
        value: this.translateInstant(question.getLabelFromValue(value))
      };
    } else if (isOptionGroupQuestion(question)) {
      return {
        title:
          this.translateInstant(question.getGroupTitleFromValue(value)) + ': ',
        value: this.translateInstant(question.getLabelFromValue(value))
      };
    } else {
      return { title: '', value };
    }
  }

  getAnswer(question, value): { value: string; title: string } {
    const valueLabel = this.getValue(question, value);
    if (valueLabel) {
      return question.prependAnswer &&
        ((valueLabel.value !== '' && valueLabel.value !== null) ||
          (valueLabel.title !== '' && valueLabel.title !== null))
        ? {
            ...valueLabel,
            title: this.translateInstant(question.getPrependAnswer())
          }
        : valueLabel;
    } else {
      return { title: '', value };
    }
  }

  getLabel(
    key: string,
    control: FormControl
  ): { value: string; title: string } {
    const value = control.value;
    const field = this.question.fields.find((f) => f.key === key);
    return this.getAnswer(field, value);
  }

  getControlLabel(keyValue: {
    key: string;
    value: FormControl;
  }): { value: string; title: string } {
    return this.getLabel(keyValue.key, keyValue.value);
  }

  openDialog(
    form: FormGroup,
    question: ArrayQuestion,
    index: number,
    newItem = false
  ) {
    const dialogRef = this.dialog.open(DialogQuestionComponent, {
      data: { form, question },
      position: { top: '2rem' },
      maxHeight: 'calc(100vh - 4rem)',
      minWidth: '80vw',
      panelClass: ['custom-dialog-container', this.question.key]
    });
    dialogRef.disableClose = true;
    this.sub = dialogRef
      .afterClosed()
      .pipe(
        filter((dialogForm) => !!dialogForm),
        filter((dialogForm) => dialogForm.pristine)
      )
      .subscribe(() => {
        if (newItem) {
          this.removeItemFromArray(index);
        }
        this.form.updateValueAndValidity();
      });
  }

  addItemToArray() {
    const fields = this.question.fields.map((field) => {
      field.answer = null;
      return field;
    });
    const child = new FormGroup(
      this.formFactoryService.createQuestions(fields)
    );
    this.array.push(child);
    this.openDialog(child, this.question, this.array.controls.length - 1, true);
  }

  removeItemFromArray(index: number) {
    this.array.removeAt(index);
  }

  drop(event) {
    const start = event.previousIndex;
    const stop = event.currentIndex;
    if (start !== stop) {
      const temp = this.array.at(start);
      this.array.removeAt(start);
      this.array.insert(stop, temp);
    }
  }

  // if q === M_VAIHTOKULKUTAPA
  // M_ONKOMUUKT -> 1 enable
  // M_ONKOMUUKT -> 2 disableAdd and empty
  subscribeToOtherWayOfTraveling() {
    const onkomuukt = this.form.get('M_ONKOMUUKT');
    if (!onkomuukt) {
      return;
    }

    this.disableAddSub = onkomuukt.valueChanges
      .pipe(startWith(onkomuukt.value as number))
      .subscribe((value) => {
        if (typeof value === 'string' && isNumberString(value)) {
          value = Number(value);
        }
        if (value === 1) {
          this.disableAdd = false;
        } else if (value === 2 || value === null) {
          this.disableAdd = true;
          this.array.clear();
        }
      });
  }
}
