<div class="form-input" [formGroup]="form">
  <label class="form-input__label" for="time" [id]="_id">{{ label }}</label>
  <ng-container *ngIf="mobile">
    <div class="">
      <input
        id="time"
        class="form-input__field"
        [attr.aria-labelledby]="_id"
        type="time"
        [formControl]="control"
      />
    </div>
  </ng-container>
  <ng-container *ngIf="!mobile">
    <hlt-form-time-input
      [label]="_id"
      [form]="timeForm"
      [hours]="hours"
      [minutes]="minutes"
    ></hlt-form-time-input>
  </ng-container>
  <hlt-form-errors
    [control]="control"
    [nextDayString]="nextDayString"
  ></hlt-form-errors>
</div>
