import { Answers } from '@hlt-shared/Questions/interfaces/type.answer';
import { createAction, props } from '@ngrx/store';

const setPreDiaryData = createAction(
  '[PreDiary] Set Answer data',
  props<{ answers: Answers }>()
);

const savePreDiary = createAction(
  '[PreDiary] Save answer data',
  props<{ answers: Answers }>()
);

const savePreDiarySuccess = createAction('[PreDiary] Save Answer Data Success');

const savePreDiaryFailure = createAction(
  '[PreDiary] Save Answer Data Failure',
  props<{ error: any }>()
);

export const PreDiaryActions = {
  setPreDiaryData,
  savePreDiary,
  savePreDiarySuccess,
  savePreDiaryFailure
};
