import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BackgroundReducers } from '@hlt-app/study/reducers';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, first, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'hlt-form-radios',
  templateUrl: './form-radios.component.html',
  styleUrls: ['./form-radios.component.scss']
})
export class FormRadiosComponent implements OnInit, OnDestroy {
  @Input() public control: FormControl;
  @Input() public form: FormGroup;
  @Input() public question: any;

  public disable = [];
  public infobox = false;

  private backgroundAnswers$ = this.store.pipe(
    select(BackgroundReducers.selectStudyBackgroundAnswers)
  );

  private infoSub: Subscription;

  constructor(private store: Store) {}

  ngOnInit(): void {
    if (
      !!this.question &&
      !!this.question.key &&
      this.question.key === 'T_VUOSITULOT'
    ) {
      this.filterAnswerValues();
    }
    if (
      !!this.question &&
      !!this.question.key &&
      this.question.key === 'T_KULJETUSTYO'
    ) {
      this.toggleInfobox();
    }
  }

  ngOnDestroy() {
    if (this.infoSub) {
      this.infoSub.unsubscribe();
    }
  }

  // @todo: fix this if time, this should not be made like this!
  filterAnswerValues() {
    // T_VUOSITULOT
    this.backgroundAnswers$
      .pipe(
        filter((answers) => !!answers && Object.keys(answers).length !== 0),
        filter((answers) => {
          if (!!answers.T_HLOVUOSITULOT) {
            let val = answers.T_HLOVUOSITULOT;
            if (typeof val === 'string') {
              val = Number(answers.T_HLOVUOSITULOT);
            }
            // 11 === En osaa sanoa tai en halua vastata
            if (val < 11 && val > 2) {
              return true;
            }
          }
          return false;
        }),
        map((answers) => {
          let val = answers.T_HLOVUOSITULOT;
          if (typeof val === 'string') {
            val = Number(answers.T_HLOVUOSITULOT);
          }
          return val;
        }),
        first(),
        map((value) => {
          switch (value) {
            case 10:
              return [7, 6, 5, 4, 3, 2, 1];
            case 9:
              return [5, 4, 3, 2, 1];
            case 8:
            case 7:
              return [3, 2, 1];
            case 6:
            case 5:
              return [2, 1];
            case 4:
            case 3:
              return [1];
          }
          return [];
        })
      )
      .subscribe((disable) => {
        this.disable = disable;
      });
  }

  toggleInfobox() {
    this.infoSub = this.control.valueChanges
      .pipe(
        startWith(this.control.value as number),
        filter((val) => !!val),
        map((val) => (typeof val === 'string' ? Number(val) : val)),
        map((val) =>
          val === 1 ? (this.infobox = true) : (this.infobox = false)
        )
      )
      .subscribe();
  }
}
