import { Component, OnInit } from '@angular/core';
import { AuthLogoutActions } from '@hlt-app/auth/actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'hlt-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(AuthLogoutActions.logoutConfirmationTrue());
  }
}
