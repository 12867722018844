import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSpinner } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@hlt-app/app-routing.module';
import {
  ClaimsFormComponent,
  LoginFormComponent,
  LogoutConfirmationDialogComponent,
  PasswordStrengthComponent,
  RegisterFormComponent,
  ResetPasswordFormComponent
} from '@hlt-app/auth/components';
import {
  ClaimsPageComponent,
  LoginPageComponent,
  LogoutComponent,
  PhoneLoginComponent,
  PhoneRegisterComponent,
  RegisterPageComponent,
  ResetPasswordComponent,
  SelectUserComponent
} from '@hlt-app/auth/containers';
import {
  AuthEffects,
  LoginEffects,
  RegisterEffects
} from '@hlt-app/auth/effects';
import { RouterEffects } from '@hlt-app/core/effects';
import { FireModule } from '@hlt-app/firebase';
import { SharedModule } from '@hlt-app/shared';
import { metaReducers, ROOT_REDUCERS } from '@hlt-app/shared/reducers';
import { SentryErrorHandler } from '@hlt-app/shared/services/sentry';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/browser';
import { FirebaseNgrxUserManagementModule } from '@xtream/firebase-ngrx-user-management';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { MaterialModule } from './material';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const COMPONENTS = [
  AppComponent,
  LoginPageComponent,
  LoginFormComponent,
  LogoutConfirmationDialogComponent,
  RegisterPageComponent,
  RegisterFormComponent,
  ClaimsPageComponent,
  ClaimsFormComponent,
  ResetPasswordComponent,
  ResetPasswordFormComponent,
  PhoneRegisterComponent,
  PhoneLoginComponent,
  SelectUserComponent,
  LogoutComponent,
  PasswordStrengthComponent
];

export const EFFECTS = [AuthEffects, RegisterEffects, LoginEffects];

let PROVIDERS = [];

if (environment.production) {
  PROVIDERS = [{ provide: ErrorHandler, useClass: SentryErrorHandler }];
  Sentry.init({
    dsn:
      'https://e1acf67bfdb140a7bf5db47cb3b95b1d@o128807.ingest.sentry.io/5333947',
    // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
    // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
    // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
    integrations: [
      new Sentry.Integrations.TryCatch({
        XMLHttpRequest: false
      })
    ]
  });
}

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgHttpLoaderModule.forRoot(),
    MaterialModule,
    AngularFireModule.initializeApp(environment.firebase),
    FireModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fi',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FirebaseNgrxUserManagementModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictActionWithinNgZone: false,
        strictActionTypeUniqueness: true
      }
    }),
    StoreRouterConnectingModule.forRoot(),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          name: 'HLT App'
        })
      : [],
    EffectsModule.forRoot([RouterEffects, ...EFFECTS]),
    CommonModule,
    SharedModule,
    AppRoutingModule
  ],
  providers: PROVIDERS,
  entryComponents: [LogoutConfirmationDialogComponent, MatSpinner],
  bootstrap: [AppComponent]
})
export class AppModule {}
