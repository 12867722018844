import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Answers, isTypeKeyBooleanPair } from '../interfaces/type.answer';

export const getTimeDelim = (time: any) => {
  if (typeof time === 'string' && time.indexOf('.') !== -1) {
    return '.';
  }
  if (typeof time === 'string' && time.indexOf(':') !== -1) {
    return ':';
  }
  return '';
};

export const getMoment = (time: any, nextDay = false) => {
  // @ts-ignore
  const moment = extendMoment(Moment);
  const delim = getTimeDelim(time);

  let date = '2000-01-01';
  if (nextDay) {
    date = '2000-01-02';
  }

  // since we only validate time, date does not matter, but we need it for moment
  return moment(date + ' ' + time + ' +0000', `YYYY-MM-DD HH${delim}mm Z`);
};

export const dateGetTranslationKey = (trip: any, key: any) => {
  let value = trip[key];

  if (!!trip[key]) {
    value = trip[key];
  }

  return `${key}_${value}.label`;
};

export const validateTimeArray = (
  answers: Answers[],
  newTrip: Answers | null = null,
  editingIndex: number | null = null
) => {
  const trips = [...answers];

  // @ts-ignore
  const moment = extendMoment(Moment);
  const timeArr = [];
  let newTime = null;

  if (editingIndex !== null) {
    trips.splice(editingIndex, 1);
  }

  if (!!newTrip && !!newTrip.M_LAHTOAIKA && !!newTrip.M_MAARAAIKA) {
    trips.push(newTrip);
  }

  if (!!newTrip && !!newTrip.M_LAHTOAIKA && !newTrip.M_MAARAAIKA) {
    newTime = getMoment(newTrip.M_LAHTOAIKA);
  }

  if (!!newTrip && !!newTrip.M_MAARAAIKA && !newTrip.M_LAHTOAIKA) {
    let newNextDay = false;
    if (
      !!newTrip.M_SEURAAVAPAIVA &&
      isTypeKeyBooleanPair(newTrip.M_SEURAAVAPAIVA)
    ) {
      if (newTrip.M_SEURAAVAPAIVA['1']) {
        newNextDay = true;
      }
    }

    newTime = getMoment(newTrip.M_MAARAAIKA, newNextDay);
  }

  if (trips.length > 1) {
    trips.sort((a, b) =>
      !!a && !!b && !!a.M_LAHTOAIKA && !!b.M_LAHTOAIKA
        ? getMoment(a.M_LAHTOAIKA).diff(getMoment(b.M_LAHTOAIKA))
        : 0
    );
  }

  for (const trip of trips) {
    if (!trip) {
      continue;
    }

    if (!trip.M_LAHTOAIKA || !trip.M_MAARAAIKA) {
      const err = new Error('missing M_LAHTOAIKA or M_MAARAAIKA');
      err.name = '1';
      throw err;
    }

    const from = getMoment(trip.M_LAHTOAIKA);

    let nextDay = false;
    if (!!trip.M_SEURAAVAPAIVA && isTypeKeyBooleanPair(trip.M_SEURAAVAPAIVA)) {
      if (trip.M_SEURAAVAPAIVA['1']) {
        nextDay = true;
      }
    }
    const to = getMoment(trip.M_MAARAAIKA, nextDay);

    if (from.isSameOrAfter(to)) {
      const err = new Error('M_MAARAAIKA is before or equal to M_LAHTOAIKA');
      err.name = '2';
      throw err;
    }

    const timeRange = moment.range([from, to]);

    if (!!newTime) {
      if (timeRange.contains(newTime as Moment.Moment, { excludeEnd: true })) {
        const translations = [
          dateGetTranslationKey(trip, 'M_LTK'),
          dateGetTranslationKey(trip, 'M_MTK'),
          `${trip.M_LAHTOAIKA}`,
          `${trip.M_MAARAAIKA}`,
          nextDay ? 'NEXTDAY' : ''
        ];
        const err = new Error(translations.join('|'));
        err.name = '3';
        throw err;
      }
    }

    timeArr.push(timeRange);
  }

  for (const [i, range] of timeArr.entries()) {
    let failedIndex = null;
    if (
      !timeArr.every((r, index) => {
        if (index === i) {
          return true;
        }

        if (r.overlaps(range)) {
          failedIndex = index;
          return false;
        }

        return true;
      })
    ) {
      let translations: any = [];
      if (failedIndex && !!trips[failedIndex]) {
        let next = false;
        if (
          // @ts-ignore
          !!trips[failedIndex].M_SEURAAVAPAIVA &&
          // @ts-ignore
          isTypeKeyBooleanPair(trips[failedIndex].M_SEURAAVAPAIVA)
        ) {
          // @ts-ignore
          if (trips[failedIndex].M_SEURAAVAPAIVA['1']) {
            next = true;
          }
        }
        translations = [
          dateGetTranslationKey(trips[failedIndex], 'M_LTK'),
          dateGetTranslationKey(trips[failedIndex], 'M_MTK'),
          // @ts-ignore
          `${trips[failedIndex].M_LAHTOAIKA}`,
          // @ts-ignore
          `${trips[failedIndex].M_MAARAAIKA}`,
          next ? 'NEXTDAY' : ''
        ];
      }

      const err = new Error(translations.join('|'));
      err.name = '4';
      throw err;
    }
  }

  return true;
};
