import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FeaturesEntity } from '@hlt-app/shared/models/featureCollection/featuresEntity';
import { DigiTransitService } from '@hlt-app/shared/services/digi-transit.service';
import { Question } from '@hlt-shared/Questions/classes/Question';
import { Layer } from '@hlt-shared/Questions/interfaces/type.layers';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  skip,
  startWith,
  switchMap
} from 'rxjs/operators';

@Component({
  selector: 'hlt-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss']
})
export class AddressInputComponent implements OnInit, OnDestroy {
  @Input() question: Question;
  @Input() form: FormGroup;
  @Input() map = false;
  @Input() datalist = false;

  public autoComplete$: Observable<any> = null;
  public controlName;
  public latControlName: string;
  public lonControlName: string;
  public localAdminControlName: string;
  public countryControlName: string;
  public selected = false;
  public showMap = false;
  public infoText = false;

  public kuntaInfoBox = false;

  private autoCompleteFields: {
    lat?: string;
    lon?: string;
    localAdmin?: string;
    country?: string;
  };

  private layers: Layer[] = ['address', 'street'];

  private sub: Subscription;
  private latLngSub: Subscription;
  private infoSub: Subscription;

  constructor(private digiTransitService: DigiTransitService) {}

  ngOnInit(): void {
    this.controlName = this.question.key;

    if (!!this.question.autocompleteFields) {
      this.autoCompleteFields = this.question.autocompleteFields;

      if (!!this.autoCompleteFields.lat) {
        this.latControlName = this.autoCompleteFields.lat;
      }
      if (!!this.autoCompleteFields.lon) {
        this.lonControlName = this.autoCompleteFields.lon;
      }
      if (!!this.autoCompleteFields.localAdmin) {
        this.localAdminControlName = this.autoCompleteFields.localAdmin;
      }
      if (!!this.autoCompleteFields.country) {
        this.countryControlName = this.autoCompleteFields.country;
      }
    }

    if (!!this.question.autocompleteLayers) {
      this.layers = this.question.autocompleteLayers;
    }

    this.autoComplete$ = this.mainControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((value) => {
        if (value !== '' && !!value) {
          // lookup from digitransit
          return this.lookup(value);
        } else {
          // if no value is present, return null
          return of(null);
        }
      })
    );

    if (!!this.lat && !!this.lon) {
      this.latLngSub = combineLatest([
        this.lat.valueChanges.pipe(startWith(this.lat.value as string)),
        this.lon.valueChanges.pipe(startWith(this.lon.value as string))
      ])
        .pipe(
          debounceTime(600),
          map(([lat, lon]) => {
            this.selected = false;
            if (
              !!lat &&
              lat !== '' &&
              lat !== null &&
              !!lon &&
              lon !== '' &&
              lon !== null
            ) {
              this.selected = true;
              this.detectChanges();
            }
            return this.selected;
          }),
          switchMap((selected) => {
            if (!selected) {
              return combineLatest(this.valueChanges()).pipe(
                switchMap(([main, town, ...rest]) => {
                  if (
                    !this.selected &&
                    !!main &&
                    main !== '' &&
                    !!town &&
                    town !== ''
                  ) {
                    return this.digiTransitService
                      .autocomplete(main + ', ' + town, this.layers)
                      .pipe(
                        distinctUntilChanged(),
                        first(),
                        map((value) => {
                          if (
                            !!value &&
                            value.length &&
                            value[0].properties.confidence === 1
                          ) {
                            this.setLocation(value[0]);
                          }
                        }),
                        catchError((error) => {
                          return of(null);
                        })
                      );
                  }
                  return of(null);
                })
              );
            }
            return of(null);
          })
        )
        .subscribe();
    }

    if (
      this.controlName === 'T_KOTIOSOITE' ||
      this.controlName === 'M_LOSOITE' ||
      this.controlName === 'M_MOSOITE'
    ) {
      this.infoSub = this.mainControl.valueChanges
        .pipe(
          debounceTime(300),
          startWith(this.mainControl.value as string),
          map((value) => {
            if (value === null || value === '') {
              this.infoText = false;
            } else {
              this.infoText = !/\d/.test(value);
            }
          })
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.latLngSub) {
      this.latLngSub.unsubscribe();
    }
    if (this.infoSub) {
      this.infoSub.unsubscribe();
    }
  }

  lookup(value: string): Observable<any> {
    return this.digiTransitService
      .autocomplete(value.toLowerCase(), this.layers)
      .pipe(
        catchError((error) => {
          return of(null);
        })
      );
  }

  get mainControl() {
    return this.form.get(this.controlName);
  }

  get localAdmin() {
    return this.form.get(this.localAdminControlName);
  }

  get country() {
    return this.form.get(this.countryControlName);
  }

  get lat() {
    return this.form.get(this.latControlName);
  }

  get lon() {
    return this.form.get(this.lonControlName);
  }

  optionSelected(event) {
    this.setValues(event.option.value);
    if (this.controlName === 'M_LKUNTA' || this.controlName === 'M_MKUNTA' || this.controlName === 'T_KOTIKUNTA') {
      this.setLocation(event.option.value);
    }
  }

  setValues(item: FeaturesEntity) {
    this.mainControl.setValue(item.properties.name);

    if (!!this.localAdmin && !!item.properties.localadmin) {
      this.localAdmin.setValue(item.properties.localadmin);
    }

    if (!!this.country && !!item.properties.country) {
      if (
        !(
          typeof item.properties.country === 'string' &&
          (item.properties.country.toLowerCase() === 'suomi' ||
            item.properties.country.toLowerCase() === 'finland')
        )
      ) {
        this.country.setValue(item.properties.country);
      }
    }

    this.setLocation(item);

    this.form.updateValueAndValidity();
  }

  setLocation(item) {
    if (!!this.lat && !!this.lon && !!item.geometry.coordinates) {
      let lat: any = item.geometry.coordinates[1];
      let lon: any = item.geometry.coordinates[0];

      if (typeof lat === 'number') {
        lat = lat.toString();
      }

      if (typeof lon === 'number') {
        lon = lon.toString();
      }

      this.lat.setValue(lat);
      this.lon.setValue(lon);
    }
  }

  valueChanges() {
    const changes = [
      this.mainControl.valueChanges.pipe(
        startWith(this.mainControl.value as string)
      )
    ];

    if (!!this.localAdmin) {
      changes.push(
        this.localAdmin.valueChanges.pipe(
          startWith(this.localAdmin.value as string)
        )
      );
    }

    if (!!this.country) {
      changes.push(
        this.country.valueChanges.pipe(startWith(this.country.value as string))
      );
      this.skipKuntaInfobox(this.country.value);
    }
    return changes;
  }

  detectChanges() {
    const changes = this.valueChanges();
    if (!!this.lat && !!this.lon) {
      this.sub = combineLatest(changes)
        .pipe(
          skip(1),
          first(),
          map(() => {
            this.lat.setValue(null);
            this.lon.setValue(null);
            this.form.updateValueAndValidity();
            this.selected = false;
          })
        )
        .subscribe();
    }
  }

  closeMap(geoJson: FeaturesEntity | null) {
    this.showMap = false;
    if (geoJson) {
      this.setValues(geoJson);
    }
  }

  openMap() {
    this.showMap = true;
  }

  skipKuntaInfobox(item) {
    if(typeof item === 'string' &&
      !(item.toLowerCase() === 'finland' || item.toLowerCase() === 'suomi')
    ) {
      this.kuntaInfoBox = true;
    } else {
      this.kuntaInfoBox = false;
    }
  }
}
