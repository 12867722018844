import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { Subscription } from 'rxjs';

@Component({
  selector: 'hlt-phone-register',
  templateUrl: './phone-register.component.html',
  styleUrls: ['./phone-register.component.scss']
})
export class PhoneRegisterComponent
  implements OnInit, OnDestroy, AfterViewInit {
  public submitted = false;
  public enrolled = false;
  public verified = false;
  public recentLogin = false;
  public error = '';
  public loginError = '';
  public verifyError = '';
  form: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, [
      Validators.required,
      Validators.minLength(6)
    ]),
    confirmPassword: new FormControl(null, [Validators.required]),
    phone: new FormControl(null, [
      Validators.required,
      Validators.pattern(
        '^([+]?358(\\s?|-?)50|0358(\\s?|-?)50|00358(\\s?|-?)50|[+]?358(\\s?|-?)4[0-9]{1}|0358(\\s?|-?)4[0-9]{1}|00358(\\s?|-?)4[0-9]{1})(\\s?|-?)(([0-9]{3,4})(\\s|\\-)?[0-9]{1,4})$'
      )
    ])
  });
  phoneForm: FormGroup = new FormGroup({
    phone: new FormControl(null, [
      Validators.required,
      Validators.pattern(
        '^([+]?358(\\s?|-?)50|0358(\\s?|-?)50|00358(\\s?|-?)50|[+]?358(\\s?|-?)4[0-9]{1}|0358(\\s?|-?)4[0-9]{1}|00358(\\s?|-?)4[0-9]{1})(\\s?|-?)(([0-9]{3,4})(\\s|\\-)?[0-9]{1,4})$'
      )
    ])
  });
  verifyForm: FormGroup = new FormGroup({
    code: new FormControl(null, [Validators.required, Validators.minLength(6)])
  });

  loginForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, [
      Validators.required,
      Validators.minLength(6)
    ])
  });
  private recaptchaVerifier;
  private cred;
  private verificationId;
  private sub: Subscription;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private localize: LocalizeRouterService
  ) {}

  get email() {
    return this.form.get('email') as FormControl;
  }

  get password() {
    return this.form.get('password') as FormControl;
  }

  get confirmPassword() {
    return this.form.get('confirmPassword') as FormControl;
  }

  get phone() {
    return this.form.get('phone') as FormControl;
  }

  get phoneFormPhone() {
    return this.phoneForm.get('phone') as FormControl;
  }

  get code() {
    return this.verifyForm.get('code') as FormControl;
  }

  get loginFormEmail() {
    return this.loginForm.get('email') as FormControl;
  }

  get loginFormPassword() {
    return this.loginForm.get('password') as FormControl;
  }

  ngOnInit(): void {
    this.sub = this.afAuth.authState.subscribe(async (user) => {
      if (user) {
        const auth = await user.getIdTokenResult();
        if (!!auth.claims && auth.claims.role && auth.claims.role === 1) {
          this.router.navigate([this.localize.translateRoute('/study')]);
        }

        if (!!auth.claims && auth.claims.role && auth.claims.role === 5) {
          this.router.navigate([
            this.localize.translateRoute('/kantar/select-user')
          ]);
        }

        if (
          Array.isArray(user.multiFactor.enrolledFactors) &&
          user.multiFactor.enrolledFactors.length !== 0
        ) {
          this.submitted = true;
          this.enrolled = true;
          this.verified = true;
        }

        this.submitted = true;
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngAfterViewInit() {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      '2fa-captcha',
      {
        size: 'invisible'
      }
    );
  }

  async submit() {
    if (this.form.valid) {
      this.cred = await this.afAuth.auth.createUserWithEmailAndPassword(
        this.email.value,
        this.password.value
      );

      await this.cred.user.sendEmailVerification();
      this.submitted = true;

      if (this.phone.value) {
        this.phoneFormPhone.setValue(this.phone.value);
      }
    }
  }

  async enroll() {
    if (this.phoneForm.valid) {
      const user = await this.afAuth.auth.currentUser;
      const session = await user.multiFactor.getSession();

      const phoneOpts = {
        phoneNumber: this.phoneFormPhone.value,
        session
      };

      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      this.error = '';
      this.enrolled = true;
      this.verificationId = await phoneAuthProvider
        .verifyPhoneNumber(phoneOpts, this.recaptchaVerifier)
        .catch((e) => {
          const val = this.email.value
            ? this.email.value
            : 'minkä täytit edellisellä sivulla';
          if (e.code === 'auth/requires-recent-login') {
            this.recentLogin = true;
          }
          this.error = e.message;
          this.enrolled = false;
        });
    }
  }

  async login() {
    if (this.loginForm.valid) {
      this.loginError = '';
      this.recentLogin = false;
      try {
        await this.afAuth.auth.signInWithEmailAndPassword(
          this.loginFormEmail.value,
          this.loginFormPassword.value
        );
      } catch (err) {
        this.recentLogin = true;
        this.loginError = err.message;
      }
    }
  }

  async verify() {
    if (this.verifyForm.valid) {
      const cred = firebase.auth.PhoneAuthProvider.credential(
        this.verificationId,
        this.verifyForm.value.code
      );

      const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
        cred
      );

      this.verifyError = '';
      const user = await this.afAuth.auth.currentUser;
      this.verified = true;
      await user.multiFactor
        .enroll(multiFactorAssertion, 'phone number')
        .catch((e) => {
          this.verified = false;
          this.verifyError = 'Tarkista koodi.';
        });
    }
  }
}
