import {
  customClaims,
  redirectLoggedInTo,
  redirectUnauthorizedTo
} from '@angular/fire/auth-guard';
import { UserRoleEnum } from '@hlt-app/auth/models';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

export const redirectUnauthorizedToLogin = () =>
  redirectUnauthorizedTo(['/login']);

export const redirectLoggedInToStudy = () => redirectLoggedInTo(['/study']);

const userRole = () =>
  pipe(
    customClaims,
    map((claims) => (claims.role >= UserRoleEnum.User ? true : ['/claims']))
  );
export const redirectUsersWithoutRoleToClaims = userRole;

export const phoneRole = () =>
  pipe(
    customClaims,
    map((claims) =>
      claims.role >= UserRoleEnum.Phone ? true : ['/kantar/login']
    )
  );

export const phoneLoginPageRedirect = () =>
  pipe(
    customClaims,
    map((claims) => {
      if (claims.role >= UserRoleEnum.Phone) {
        return ['/kantar/select-user'];
      } else if (claims.role === UserRoleEnum.User) {
        return ['/study'];
      }
      return true;
    })
  );
