import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hlt-start-navigation',
  templateUrl: './start-navigation.component.html',
  styleUrls: ['./start-navigation.component.scss']
})
export class StartNavigationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
