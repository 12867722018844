import { createAction, props } from '@ngrx/store';
import { auth } from 'firebase';

export const registerSuccess = createAction('[Auth/API] Register Success');

export const registerFailure = createAction(
  '[Auth/API] Register Failure',
  props<{ error: any }>()
);

export const loginSuccess = createAction(
  '[Auth/API] Login Success',
  props<{ user: auth.IdTokenResult }>()
);

export const registerClaims = createAction(
  '[Auth/API] Register Claims',
  props<{ token: string }>()
);

export const registerClaimsFailure = createAction(
  '[Auth/API] Register Claims Failure',
  props<{ error: any }>()
);

export const claimsFailure = createAction(
  '[Auth/API] Claims Failure',
  props<{ error: any }>()
);
