import { Component, OnInit } from '@angular/core';
import { registerClaims } from '@hlt-app/auth/actions/auth-api.actions';
import * as fromAuth from '@hlt-app/auth/reducers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'hlt-claims',
  templateUrl: './claims-page.component.html',
  styleUrls: ['./claims-page.component.scss']
})
export class ClaimsPageComponent implements OnInit {
  constructor(private store: Store<fromAuth.State>) {}

  ngOnInit(): void {}

  onSubmit(token: { token: string }): void {
    this.store.dispatch(registerClaims(token));
  }
}
