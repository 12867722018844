import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import * as firebase from 'firebase';
import UserCredential = firebase.auth.UserCredential;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private afAuth: AngularFireAuth,
    private http: HttpClient,
    private fns: AngularFireFunctions
  ) {}

  doSignUpWithCredentials(credentials: {
    email: string;
    password: string;
  }): Promise<UserCredential> {
    return this.afAuth.auth.createUserWithEmailAndPassword(
      credentials.email,
      credentials.password
    );
  }

  registerClaims(token: { token: string }) {
    const callable = this.fns.httpsCallable('setClaims');
    return callable({
      h_nettitunnus: token.token
    });
  }

  getJWTToken() {
    return this.afAuth.idTokenResult;
  }

  getUser() {
    return this.afAuth.user;
  }
}
