import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'hlt-route-button',
  templateUrl: './route-button.component.html',
  styleUrls: ['./route-button.component.scss']
})
export class RouteButtonComponent implements OnInit {
  @Input() text: string;
  @Input() route: string;
  @Input() disabled: boolean;
  @Input() public ghost: boolean;
  @Input() public undo: boolean;

  constructor(
    private router: Router,
    private localize: LocalizeRouterService
  ) {}

  ngOnInit(): void {}

  click() {
    this.router.navigate([this.localize.translateRoute(this.route)]);
  }
}
