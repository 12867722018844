import { Answers } from '@hlt-shared/Questions/interfaces/type.answer';
import { createAction, props } from '@ngrx/store';

const setDiaryData = createAction(
  '[Diary] Set Answer data',
  props<{ answers: Answers; index: number | null }>()
);

const resetDiaryData = createAction('[Diary] Reset Answer data');
const setEditDiaryData = createAction(
  '[Diary] Set Edit Answer data',
  props<{ answers: Answers; index: number | null }>()
);

const setDiaryCompleted = createAction(
  '[Diary] Set diary completed',
  props<{ completed: boolean; noTripsDone: boolean }>()
);

const saveDiary = createAction(
  '[Diary] Save answer data',
  props<{ answers: Answers; index: number | null }>()
);

const saveDiarySuccess = createAction('[Diary] Save Answer Data Success');

const saveDiaryFailure = createAction(
  '[Diary] Save Answer Data Failure',
  props<{ error: any }>()
);

const setTripsData = createAction(
  '[Diary] Set diary trips data',
  props<{ trips: Array<Answers>; completed: boolean }>()
);

const saveTrip = createAction(
  '[Diary] Save diary trips data',
  props<{ trip: Answers }>()
);

const saveTripsSuccess = createAction('[Diary] Save diary trips Success');

const saveTripsFailure = createAction(
  '[Diary] Save diary trips Failure',
  props<{ error: any }>()
);

const editTrip = createAction(
  '[Diary] Edit diary trips data',
  props<{ index: number; trip: Answers }>()
);

const deleteTrip = createAction(
  '[Diary] Delete diary trips data',
  props<{ index: number }>()
);

export const DiaryActions = {
  setDiaryData,
  resetDiaryData,
  setEditDiaryData,
  setDiaryCompleted,
  saveDiary,
  saveDiarySuccess,
  saveDiaryFailure,

  setTripsData,
  saveTrip,
  saveTripsSuccess,
  saveTripsFailure,
  editTrip,
  deleteTrip
};
