import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'hlt-form-navigation',
  templateUrl: './form-navigation.component.html',
  styleUrls: ['./form-navigation.component.scss']
})
export class FormNavigationComponent implements OnInit {
  @Input() title: string;
  @Input() current = 1;
  @Input() total = 1;
  @Output() public clicked = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  click() {
    this.clicked.emit(true);
  }
}
