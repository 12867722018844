import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormFactoryService } from '@hlt-app/shared/services/form-factory.service';
import { BackgroundReducers, DiaryReducers } from '@hlt-app/study/reducers';
import {
  Answers,
  isTypeKeyValuePair,
  TypeAnswer
} from '@hlt-shared/Questions/interfaces/type.answer';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { debounceTime, filter, first, map, startWith } from 'rxjs/operators';

type Location = {
  street?: TypeAnswer;
  localAdmin?: TypeAnswer;
  lat?: TypeAnswer;
  lon?: TypeAnswer;
  country?: TypeAnswer;
} | null;

@Component({
  selector: 'hlt-select-other-input',
  templateUrl: './select-other-input.component.html',
  styleUrls: ['./select-other-input.component.scss']
})
export class SelectOtherInputComponent implements OnInit, OnDestroy {
  @Input() public question;
  @Input() public form: FormGroup;
  public disabled = true;
  public group: FormGroup;
  public control: string;
  public otherField: any;
  public subscription: Subscription;
  public valueSubscription: Subscription;
  public visible = false;

  public streetControlName: string;
  public latControlName: string;
  public lonControlName: string;
  public localAdminControlName: string;
  public countryControlName: string;

  private preFillFields: {
    street?: string;
    lat?: string;
    lon?: string;
    localAdmin?: string;
    country?: string;
  };
  private trips$ = this.store.pipe(select(DiaryReducers.selectStudyDiaryTrips));
  private backgroundAnswers$ = this.store.pipe(
    select(BackgroundReducers.selectStudyBackgroundAnswers)
  );
  private startKey = 'M_LTK';
  private endKey = 'M_MTK';
  private firstTrip = false;
  private fillFromLastTrip = false;

  constructor(private formFactory: FormFactoryService, private store: Store) {}

  ngOnInit(): void {
    this.control = this.question.key;
    this.group = this.form.get(this.control) as FormGroup;
    this.otherField = this.formFactory.getOther(this.question.options);
    this.subscription = this.select.valueChanges
      .pipe(startWith(this.select.value as string))
      .subscribe((value) => this.selectValueChange(value));
    if (
      !!this.question &&
      (this.question.key === this.endKey || this.question.key === this.startKey)
    ) {
      if (this.question.preFillFields) {
        this.preFillFields = this.question.preFillFields;
        if (!!this.preFillFields.street) {
          this.streetControlName = this.preFillFields.street;
        }
        if (!!this.preFillFields.lat) {
          this.latControlName = this.preFillFields.lat;
        }
        if (!!this.preFillFields.lon) {
          this.lonControlName = this.preFillFields.lon;
        }
        if (!!this.preFillFields.localAdmin) {
          this.localAdminControlName = this.preFillFields.localAdmin;
        }
        if (!!this.preFillFields.country) {
          this.countryControlName = this.preFillFields.country;
        }
      }
      this.subscribeToValueChanges();
    }

    if (!!this.question && this.question.key === this.startKey) {
      this.prefillValueFromPreviousAnswers();
    }
  }

  prefillValueFromPreviousAnswers() {
    if (this.select.value === null) {
      // check if this is first/only trip -> set to home
      // if this is other trip -> set to last trips M_MTK
      this.trips$.pipe(first()).subscribe((answers) => {
        let selected = null;
        if (!answers || !Array.isArray(answers) || answers.length === 0) {
          // 1 === omakoti
          selected = 1;
          this.firstTrip = true;
        } else {
          const lastTrip = answers.slice(-1).pop();
          if (this.getValueFromTrip(lastTrip, this.endKey)) {
            selected = this.getValueFromTrip(lastTrip, this.endKey);
          }
        }

        if (typeof selected === 'number') {
          selected = selected.toString();
        }

        this.fillFromLastTrip = true;
        this.select.setValue(selected);
        this.form.updateValueAndValidity();
      });
    }
  }

  getValueFromTrip(trip, key) {
    if (
      !!trip &&
      !!trip[key] &&
      isTypeKeyValuePair(trip[key]) &&
      !!trip[key][key]
    ) {
      let answer = trip[key][key];
      if (typeof answer === 'string') {
        answer = Number(answer);
      }
      return answer;
    }
    return false;
  }

  getValueFromAnswer(answers, key) {
    if (!!answers[key]) {
      return answers[key];
    }
    return null;
  }

  getLocation(answers, from): Location {
    switch (from) {
      case 'background':
        return {
          street: this.getValueFromAnswer(answers, 'T_KOTIOSOITE'),
          localAdmin: this.getValueFromAnswer(answers, 'T_KOTIKUNTA'),
          lat: this.getValueFromAnswer(answers, 'T_KOTIOSOITE_LAT'),
          lon: this.getValueFromAnswer(answers, 'T_KOTIOSOITE_LON'),
          country: null
        };
      case 'start':
        return {
          street: this.getValueFromAnswer(answers, 'M_LOSOITE'),
          localAdmin: this.getValueFromAnswer(answers, 'M_LKUNTA'),
          lat: this.getValueFromAnswer(answers, 'M_LOSOITE_LAT'),
          lon: this.getValueFromAnswer(answers, 'M_LOSOITE_LON'),
          country: this.getValueFromAnswer(answers, 'M_LMAA')
        };
      case 'end':
        return {
          street: this.getValueFromAnswer(answers, 'M_MOSOITE'),
          localAdmin: this.getValueFromAnswer(answers, 'M_MKUNTA'),
          lat: this.getValueFromAnswer(answers, 'M_MOSOITE_LAT'),
          lon: this.getValueFromAnswer(answers, 'M_MOSOITE_LON'),
          country: this.getValueFromAnswer(answers, 'M_MMAA')
        };
      default:
        return null;
    }
  }

  subscribeToValueChanges() {
    this.valueSubscription = this.select.valueChanges
      .pipe(
        debounceTime(300),
        filter((answer) => !!answer),
        map((answer) => Number(answer)),
        filter((answer) => {
          if (this.fillFromLastTrip) {
            return true;
          }

          switch (answer) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 6:
            case 7:
            case 19:
              return true;
            default:
              this.reset();
              return false;
          }
        })
      )
      .subscribe((answer) => {
        if (answer === 1 && this.firstTrip) {
          this.setValueFromBackground();
        } else {
          this.setValueFromTrips(answer);
        }
        this.fillFromLastTrip = false;
      });
  }

  setValueFromBackground() {
    this.backgroundAnswers$
      .pipe(
        filter((answers) => !!answers && Object.keys(answers).length !== 0),
        first()
      )
      .subscribe((answers) => {
        const location = this.getLocation(answers, 'background');
        this.prefill(location);
      });
  }

  filterAnswers(trip: Answers, answer: number) {
    return !!(
      this.getValueFromTrip(trip, this.startKey) === answer ||
      this.getValueFromTrip(trip, this.endKey) === answer
    );
  }

  // @todo: get value from trips
  setValueFromTrips(answer: number) {
    this.trips$
      .pipe(
        filter((trips) => Array.isArray(trips) && trips.length > 0),
        first(),
        map((trips) => {
          return trips.filter((trip) => this.filterAnswers(trip, answer));
        }),
        filter((trips) => {
          if (Array.isArray(trips) && trips.length > 0) {
            return true;
          } else {
            this.reset();
            return false;
          }
        }),
        map((trips) => {
          const lastTrip = trips.slice(-1).pop();
          const start = this.getValueFromTrip(lastTrip, this.startKey);
          const end = this.getValueFromTrip(lastTrip, this.endKey);
          return { start, end, lastTrip };
        }),
        map(({ start, end, lastTrip }) => {
          let location: Location = null;
          if (start === answer) {
            location = this.getLocation(lastTrip, 'start');
          } else if (end === answer) {
            location = this.getLocation(lastTrip, 'end');
          }
          if (location) {
            this.prefill(location);
          }
        })
      )
      .subscribe();
  }

  prefill(location: Location) {
    if (!!this.street && !!location.street) {
      this.street.setValue(location.street);
    }
    if (!!this.localAdmin && !!location.localAdmin) {
      this.localAdmin.setValue(location.localAdmin);
    }
    if (!!this.lat && !!location.lat) {
      if (typeof location.lat === 'number') {
        location.lat = location.lat.toString();
      }

      this.lat.setValue(location.lat);
    }
    if (!!this.lon && !!location.lon) {
      if (typeof location.lon === 'number') {
        location.lon = location.lon.toString();
      }

      this.lon.setValue(location.lon);
    }
    if (!!this.country && !!location.country) {
      if (
        !(
          typeof location.country === 'string' &&
          (location.country.toLowerCase() === 'suomi' ||
            location.country.toLowerCase() === 'finland')
        )
      ) {
        this.country.setValue(location.country);
      }
    }
    this.form.updateValueAndValidity();
  }

  reset() {
    this.street.reset();
    this.localAdmin.reset();
    this.lat.reset();
    this.lon.reset();
    this.country.reset();
    this.form.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.valueSubscription) {
      this.valueSubscription.unsubscribe();
    }
  }

  selectValueChange(value: string) {
    let intValue: any = value;
    if (typeof intValue === 'string') {
      intValue = Number(intValue);
    }
    const option = this.getOption(intValue);
    if (option && option.other && option.other.key === this.otherField.key) {
      this.visible = true;
      this.other.enable();
    } else {
      this.visible = false;
      this.other.reset();
      this.other.disable();
    }
    this.form.updateValueAndValidity();
  }

  getOption(value) {
    return this.question.options.find((option) => option.value === value);
  }

  get select() {
    return this.form.get([this.control, this.question.key]) as FormControl;
  }

  get street() {
    return this.form.get(this.streetControlName);
  }

  get localAdmin() {
    return this.form.get(this.localAdminControlName);
  }

  get country() {
    return this.form.get(this.countryControlName);
  }

  get lat() {
    return this.form.get(this.latControlName);
  }

  get lon() {
    return this.form.get(this.lonControlName);
  }

  get other() {
    return this.form.get([this.control, this.otherField.key]) as FormControl;
  }
}
