import { Answers } from '@hlt-shared/Questions/interfaces/type.answer';
import { createAction, props } from '@ngrx/store';

const setBackgroundData = createAction(
  '[Background] Set Answer data',
  props<{ answers: Answers }>()
);

const saveBackground = createAction(
  '[Background] Save answer data',
  props<{ answers: Answers }>()
);

const saveBackgroundSuccess = createAction(
  '[Background] Save Answer Data Success'
);

const saveBackgroundFailure = createAction(
  '[Background] Save Answer Data Failure',
  props<{ error: any }>()
);

export const BackgroundActions = {
  setBackgroundData,
  saveBackground,
  saveBackgroundSuccess,
  saveBackgroundFailure
};
