import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'hlt-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss']
})
export class FormErrorsComponent implements OnInit {
  @Input() control: FormControl | FormArray;
  @Input() nextDayString = '';
  @Input() password = false;

  constructor() {}

  ngOnInit(): void {}
}
