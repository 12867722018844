<fieldset [formGroup]="form">
  <ng-container *ngFor="let option of question.options">
    <hlt-form-checkbox
      [control]="getCheck(option)"
      [group]="control"
      [label]="option.getLabel() | translate"
      [name]="question.key"
      [value]="option.value"
    ></hlt-form-checkbox>
  </ng-container>
</fieldset>
