<div id="2fa-captcha"></div>
<div class="container--small">
  <div class="loginform" *ngIf="!signedIn">
    <div class="content">
      <div class="header">
        <h1 class="header__title">{{ 'PHONELOGIN.TITLE' | translate }}</h1>
      </div>
      <form (ngSubmit)="signIn()" [formGroup]="form">
        <hlt-text-input
          [label]="'LOGIN.EMAIL' | translate"
          [form]="form"
          [control]="email"
          [placeholder]="'LOGIN.EMAILPLACEHOLDER' | translate"
          type="email"
        ></hlt-text-input>

        <hlt-text-input
          [label]="'LOGIN.PASSWORD' | translate"
          [form]="form"
          [control]="password"
          type="password"
        ></hlt-text-input>
        <p *ngIf="error" class="register__error">
          {{ error }}
        </p>

        <div class="buttons">
          <a class="forgot" [routerLink]="'/reset-password' | localize">{{
            'LOGIN.FORGOTLINK' | translate
          }}</a>

          <hlt-button
            [text]="'LOGIN.LOGINBUTTON' | translate"
            type="submit"
          ></hlt-button>

          <hlt-route-button
            class="register-button"
            [text]="'LOGIN.REGISTERBUTTON' | translate"
            route="/kantar/register"
            [ghost]="true"
          ></hlt-route-button>
        </div>
      </form>
    </div>
  </div>

  <div class="verify-phone" *ngIf="signedIn">
    <div class="content">
      <div class="header">
        <h1 class="header__title">{{ 'PHONELOGIN.TITLE' | translate }}</h1>
        <p class="header__desc">
          {{ 'PHONELOGIN.CONTENT' | translate }}
        </p>
      </div>

      <form (ngSubmit)="verify()" [formGroup]="verifyForm">
        <hlt-text-input
          [form]="verifyForm"
          [control]="code"
          [placeholder]="'000000'"
          [label]="'PHONELOGIN.CODE' | translate"
          type="text"
        ></hlt-text-input>
        <hlt-button
          [text]="'LOGIN.LOGINBUTTON' | translate"
          type="submit"
        ></hlt-button>
      </form>

      <p *ngIf="verifyError">{{ verifyError }}</p>
    </div>
  </div>
</div>
