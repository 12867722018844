<form [formGroup]="form" class="container" *ngIf="!disableAdd">
  <ng-container [formArrayName]="question.key">
    <p class="title" *ngIf="!question.main">
      {{ question.getTitle() | translate }}
    </p>
    <div
      class="icons"
      *ngIf="question.key !== 'M_VAIHTOKULKUTAPA' && arrayControls.length > 0"
    >
      <div class="icons__icon-set" *ngIf="question.guideTexts.edit">
        <mat-icon>edit</mat-icon>
        <span class="icons__explanation">
          {{ question.getGuideEdit() | translate }}</span
        >
      </div>
      <div
        class="icons__icon-set"
        aria-hidden="true"
        *ngIf="question.guideTexts.drag"
      >
        <mat-icon>open_with</mat-icon>
        <span class="icons__explanation">
          {{ question.getGuideDrag() | translate }}</span
        >
      </div>
      <div class="icons__icon-set" *ngIf="question.guideTexts.delete">
        <mat-icon>delete</mat-icon>
        <span class="icons__explanation">
          {{ question.getGuideDelete() | translate }}</span
        >
      </div>
    </div>

    <ng-container *ngIf="firstItem$ | async as firstItem">
      <div class="user-container">
        <mat-icon
          aria-hidden="false"
          aria-label="User icon"
          class="account-icon"
        >
          account_circle
        </mat-icon>
        <div class="user-info">
          <label class="user-title" *ngIf="!guardian">{{
            'USERINFO.ME' | translate
          }}</label>
          <label class="user-title" *ngIf="guardian">{{
            'USERINFO.CHOSEN' | translate
          }}</label>
          <p class="user-info info" *ngFor="let prop of firstItem">
            <strong *ngIf="prop.title !== ''">{{ prop.title }}</strong>
            {{ prop.value }}
          </p>
        </div>
      </div>
    </ng-container>

    <div
      class="example-list"
      cdkDropList
      (cdkDropListDropped)="drop($event)"
      *ngIf="arrayControls.length > 0"
    >
      <div
        class="user-container"
        cdkDrag
        *ngFor="let arrayItem of arrayControls; let index = index"
      >
        <mat-icon
          *ngIf="question.icon"
          aria-hidden="false"
          aria-label="User icon"
          class="account-icon"
        >
          {{ question.getIcon() | translate }}
        </mat-icon>
        <div *ngIf="!question.icon">
          <span class="amount-pointer">{{ index + 1 + totalAmount }}</span>
        </div>
        <div
          [ngClass]="{ 'study-title': question.icon === false }"
          class="user-info"
        >
          <label class="user-title">
            <span *ngIf="question.icon"> {{ index + 1 + totalAmount }}.</span>
            <span *ngIf="question.contentType">
              {{ question.getContentType() | translate }}
            </span>
          </label>

          <ng-container *ngFor="let control of arrayItem.controls | keyvalue">
            <p class="user-info info" *ngIf="control.key === 'PE_SUKUPUOLI'">
              <strong>{{ getControlLabel(control).title }}</strong>
              {{ getControlLabel(control).value }}
            </p>
          </ng-container>
          <ng-container *ngFor="let control of arrayItem.controls | keyvalue">
            <p class="user-info info" *ngIf="control.key === 'PE_IKA'">
              <strong>{{ getControlLabel(control).title }}</strong>
              {{ getControlLabel(control).value }}
            </p>
          </ng-container>
          <ng-container *ngFor="let control of arrayItem.controls | keyvalue">
            <p class="user-info info" *ngIf="control.key === 'PE_AJOKORTTI'">
              <strong>{{ getControlLabel(control).title }}</strong>
              {{ getControlLabel(control).value }}
            </p>
          </ng-container>
          <ng-container *ngFor="let control of arrayItem.controls | keyvalue">
            <p class="user-info info" *ngIf="control.key.indexOf('PE_') < 0">
              <strong>{{ getControlLabel(control).title }}</strong>
              {{ getControlLabel(control).value }}
            </p>
          </ng-container>
        </div>
        <div class="buttons">
          <button
            class="edit-button"
            (click)="openDialog(arrayItem, question, index)"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <mat-icon class="drag-button" cdkDragHandle aria-hidden="true"
            >open_with</mat-icon
          >
          <button class="delete-button" (click)="removeItemFromArray(index)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!maxLength || arrayControls.length < maxLength">
    <button class="add-button" (click)="addItemToArray()">
      {{ question.getLabel() | translate }}
      <mat-icon class="add-icon">add_circle</mat-icon>
    </button>
    <hlt-form-errors [control]="array"></hlt-form-errors>
  </ng-container>
</form>
