import * as fromAuth from '@hlt-app/auth/reducers/auth.reducer';
import * as fromRegisterPage from '@hlt-app/auth/reducers/register-page.reducer';
import * as fromRoot from '@hlt-app/shared/reducers';
import {
  Action,
  combineReducers,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

export const authFeatureKey = 'authModule';

export interface AuthState {
  [fromAuth.statusFeatureKey]: fromAuth.State;
  [fromRegisterPage.registerPageFeatureKey]: fromRegisterPage.State;
}

export interface State extends fromRoot.State {
  [authFeatureKey]: AuthState;
}

export function reducers(state: AuthState | undefined, action: Action) {
  return combineReducers({
    [fromAuth.statusFeatureKey]: fromAuth.reducer,
    [fromRegisterPage.registerPageFeatureKey]: fromRegisterPage.reducer
  })(state, action);
}

export const selectAuthState = createFeatureSelector<State, AuthState>(
  authFeatureKey
);

export const selectAuthStatusState = createSelector(
  selectAuthState,
  (state) => state.status
);

export const selectUser = createSelector(
  selectAuthStatusState,
  fromAuth.getFirebaseUser
);

export const selectRegisterPageState = createSelector(
  selectAuthState,
  (state) => state.registerPage
);

export const selectRegisterPagePending = createSelector(
  selectRegisterPageState,
  fromRegisterPage.getPending
);

export const selectRegisterPageError = createSelector(
  selectRegisterPageState,
  fromRegisterPage.getError
);
