<div class="map-container">
  <nav class="container">
    <div class="wrapper">
      <hlt-button
        [icon]="{
          icon: 'my_location',
          label: 'MAP.LOCATE' | translate
        }"
        (clicked)="locate()"
        [nav]="true"
      ></hlt-button>

      <h1 class="container__title">{{ title }}</h1>
      <hlt-button
        [icon]="{
          icon: 'close',
          label: 'MAP.CLOSE' | translate
        }"
        (clicked)="close()"
        [nav]="true"
      ></hlt-button>
    </div>
  </nav>

  <div
    style="height: calc(100vh - 70px);"
    leaflet
    [leafletOptions]="options"
    (leafletMapReady)="onMapReady($event)"
    [leafletLayers]="layers"
    (leafletClick)="setLocation($event)"
  ></div>

  <hlt-button
    class="ready-button"
    (clicked)="addLocation()"
    [text]="'BUTTONS.READY' | translate"
  ></hlt-button>
</div>
