import { UserActions } from '@hlt-app/study/actions';
import { createReducer, on } from '@ngrx/store';
import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;

export const UserFeatureKey = 'user';

export interface UserState {
  claims: {
    completed: boolean;
    h_otosalue_id: number;
    h_alle_15: boolean;
    h_tutkimuspvm: Timestamp;
    h_verkkoalku: Timestamp;
    h_verkkoloppu: Timestamp;
    h_nettitunnus: string;
  } | null;
  guardian: boolean;
}

export const initialState: UserState = {
  claims: null,
  guardian: false
};

export const reducer = createReducer(
  initialState,
  on(UserActions.setClaims, (state, { claims }) => ({
    ...state,
    claims
  })),
  on(UserActions.setGuardian, (state, { guardian }) => ({
    ...state,
    guardian
  }))
);

const getClaims = (state: UserState) => state.claims;
const getGuardian = (state: UserState) => state.guardian;

export const UserReducer = {
  reducer,
  getClaims,
  getGuardian
};
