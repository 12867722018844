import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AuthLogoutActions } from '@hlt-app/auth/actions';
import { LogoutConfirmationDialogComponent } from '@hlt-app/auth/components';
import { AuthService } from '@hlt-app/auth/services';
import { BackgroundQuestionsService } from '@hlt-app/study/services/background-questions.service';
import { DiaryQuestionsService } from '@hlt-app/study/services/diary-questions.service';
import { HabitQuestionsService } from '@hlt-app/study/services/habit-questions.service';
import { PreDiaryQuestionsService } from '@hlt-app/study/services/prediary-questions.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthActions } from '@xtream/firebase-ngrx-user-management';
import { concatMap, exhaustMap, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class AuthEffects {
  logoutConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthLogoutActions.logoutConfirmation),
      exhaustMap(() => {
        const dialogRef = this.dialog.open<
          LogoutConfirmationDialogComponent,
          undefined,
          boolean
        >(LogoutConfirmationDialogComponent, {
          panelClass: ['custom-dialog-container']
        });
        return dialogRef.afterClosed();
      }),
      map((result: boolean) => {
        return result
          ? AuthLogoutActions.logoutConfirmationTrue()
          : AuthLogoutActions.logoutConfirmationDismiss();
      })
    )
  );

  logoutConfirmationTrue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthLogoutActions.logoutConfirmationTrue),
      switchMap(() => {
        this.backgroundQuestionsService.destroy();
        this.habitQuestionsService.destroy();
        this.preDiaryQuestionsService.destroy();
        this.diaryQuestionsService.destroy();
        const callable = this.fns.httpsCallable('signOut');
        return callable({});
      }),
      concatMap(() => {
        return [new AuthActions.Logout(), AuthLogoutActions.logout()];
      })
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthLogoutActions.logout),
        tap(() =>
          this.ngZone.run(() => {
            this.router.navigate([this.localize.translateRoute('/welcome')]);
          })
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private localize: LocalizeRouterService,
    private ngZone: NgZone,
    private backgroundQuestionsService: BackgroundQuestionsService,
    private habitQuestionsService: HabitQuestionsService,
    private preDiaryQuestionsService: PreDiaryQuestionsService,
    private diaryQuestionsService: DiaryQuestionsService,
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth
  ) {}
}
