import { isNumberString } from 'class-validator';
import { cloneDeep, isEmpty } from 'lodash';
import { IArrayQuestion } from '../interfaces/interface.arrayQuestion';
import {
  Answers,
  isTypeKeyValuePair,
  isTypeKeyValuePairArray,
  TypeAnswer
} from '../interfaces/type.answer';
import { TypeQuestionClasses } from '../interfaces/type.questionClasses';
import { Question } from './Question';

export class ArrayQuestion extends Question {
  public icon: string | boolean;
  public contentType: string | boolean;

  public guideTexts?: {
    edit?: string;
    delete?: string;
    drag?: string;
  } | null;

  public skipText?: string | null;

  private _sampleVariables: Answers = null;
  private _fields!: TypeQuestionClasses[];

  constructor(
    question: IArrayQuestion,
    fields: TypeQuestionClasses[],
    guardian = false
  ) {
    super(question, guardian);
    this.icon = question.icon || false;
    this.contentType = question.contentType || false;
    this.guideTexts = question.guideTexts || null;
    this.skipText = question.skipText || null;
    this._fields = fields;
    return this;
  }

  get fields(): TypeQuestionClasses[] {
    let fields = this._fields;
    if (!!fields && this.sampleVariables) {
      fields = fields.filter(
        (q) => !(!!q.skip && q.skip({ ...this.sampleVariables }))
      );
    }
    return fields;
  }

  set fields(value: TypeQuestionClasses[]) {
    this._fields = value;
  }

  get sampleVariables(): Answers {
    return this._sampleVariables;
  }

  set sampleVariables(value: Answers) {
    this._sampleVariables = value;
  }

  getTitle() {
    return this.key + '_main' + (this.guardian ? '.guardian' : '') + '.title';
  }

  getLabel() {
    return this.key + '_main' + (this.guardian ? '.guardian' : '') + '.label';
  }

  getContent() {
    return this.key + '_main' + (this.guardian ? '.guardian' : '') + '.content';
  }

  getPlaceholder() {
    return this.key + '_main' + '.placeholder';
  }

  getPrependAnswer() {
    return this.key + '_main' + '.prependAnswer';
  }

  getSuffix() {
    return this.key + '_main' + '.suffix';
  }

  getIcon() {
    // return false if there is no icon
    if (!this.icon) {
      return '';
    }
    return this.key + '_main' + '.icon';
  }

  getContentType() {
    // return false if there is no contentType
    if (!this.contentType) {
      return '';
    }
    return this.key + '_main' + '.contentType';
  }

  getGuideEdit() {
    if (!!this.guideTexts && !!this.guideTexts.edit) {
      return this.key + '_main' + '.guide.edit';
    }
    return '';
  }

  getGuideDelete() {
    if (!!this.guideTexts && !!this.guideTexts.delete) {
      return this.key + '_main' + '.guide.delete';
    }
    return '';
  }

  getGuideDrag() {
    if (!!this.guideTexts && !!this.guideTexts.drag) {
      return this.key + '_main' + '.guide.drag';
    }
    return '';
  }

  getSkipText() {
    if (!!this.skipText) {
      return this.key + '_main' + '.skipText';
    }
    return '';
  }

  getNeededKeys(answers: Answers): string[] {
    const questions = this._fields.filter((q) => {
      return (
        !(!!q.skip && q.skip({ ...answers, ...this.sampleVariables })) &&
        q.required
      );
    });
    if (questions) {
      return questions.map((q) => q.key);
    }
    return [];
  }

  getAllowedKeys(answers: Answers): string[] {
    const questions = this._fields.filter((q) => {
      return !(!!q.skip && q.skip({ ...answers, ...this.sampleVariables }));
    });
    if (questions) {
      return questions.map((q) => q.key);
    }
    return [];
  }

  validate(value: TypeAnswer): boolean | string {
    if (value === null) {
      return true;
    }

    if (!this.required && isEmpty(value)) {
      return true;
    } else if (!isTypeKeyValuePairArray(value)) {
      return `value for ${this.key} needs to be a TypeKeyValuePair[]`;
    }

    if (!!this.validation && !!this.validation.length) {
      if (value.length !== this.validation.length) {
        return `value for ${this.key} needs to be a TypeKeyValuePair[] with length of ${this.validation.length}`;
      }
    }

    for (const val of value) {
      const neededKeys = this.getNeededKeys(val);
      if (Object.keys(val).length < neededKeys.length) {
        return `${Object.keys(val).join(
          ', '
        )} missing required answers. Needed ${neededKeys.join(', ')}`;
      }

      if (
        !!neededKeys &&
        !neededKeys.every((v) => Object.keys(val).includes(v))
      ) {
        return `${Object.keys(val).join(
          ', '
        )} missing required key. Needed ${neededKeys.join(', ')}`;
      }

      const allowedKeys = this.getAllowedKeys(val);

      if (Object.keys(val).length !== allowedKeys.length) {
        return `${Object.keys(val).join(
          ', '
        )} answer object not matching allowed questions ${allowedKeys.join(
          ', '
        )}`;
      }

      if (!Object.keys(val).every((v) => allowedKeys.includes(v))) {
        return `got: ${Object.keys(val).join(', ')} wanted: ${neededKeys.join(
          ', '
        )}`;
      }

      // throws if not valid
      this._fields.map((question) => {
        question.answer =
          typeof val[question.key] !== 'undefined' ? val[question.key] : null;
        // reset answer to null
        question.answer = null;
      });
    }

    return true;
  }

  normalizeValues(answer: TypeAnswer): TypeAnswer {
    if (isEmpty(answer)) {
      return answer;
    }

    if (isTypeKeyValuePairArray(answer)) {
      let value = cloneDeep(answer);
      value = value.map((val) => {
        if (isTypeKeyValuePair(val)) {
          Object.keys(val).forEach((key) => {
            if (typeof val[key] === 'string' && isNumberString(val[key])) {
              val[key] = Number(val[key]);
            }

            if (isTypeKeyValuePairArray(val[key])) {
              // @ts-ignore
              val[key] = this.normalizeValues(val[key]);
            }
          });
        }

        return val;
      });
      return value;
    }

    return answer;
  }

  set answer(answer: TypeAnswer) {
    let value: any = cloneDeep(answer);
    if (!isEmpty(value) && isTypeKeyValuePairArray(value)) {
      value = this.normalizeValues(value);
    }
    const valid = this.validate(value);
    if (valid === true) {
      this.value = value;
    } else if (typeof valid === 'string') {
      throw new Error(valid);
    }
  }

  get answer() {
    return this.value;
  }
}
