import { Platform } from '@angular/cdk/platform';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserReducers } from '@hlt-app/study/reducers';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

let inputTimeNextUniqueId = 0;

@Component({
  selector: 'hlt-form-time',
  templateUrl: './form-time.component.html',
  styleUrls: ['./form-time.component.scss']
})
export class FormTimeComponent implements OnInit, OnDestroy {
  @Input() public control: FormControl;
  @Input() public form: FormGroup;
  @Input() public label: string;

  private claims$: Observable<any> = this.store.pipe(
    select(UserReducers.selectUserClaims)
  );
  private claimsSub: Subscription;

  public date = moment();
  public nextDayString = '';

  // tslint:disable-next-line
  protected _uid = `time-input-${inputTimeNextUniqueId++}`;
  // tslint:disable-next-line
  public _id = this._uid;

  public timeForm = new FormGroup({
    hours: new FormControl(null, [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(2),
      Validators.min(0),
      Validators.max(23),
      Validators.pattern(/^\d+$/)
    ]),
    minutes: new FormControl(null, [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(2),
      Validators.min(0),
      Validators.max(59),
      Validators.pattern(/^\d+$/)
    ])
  });
  public mobile = false;

  private timeSub: Subscription;

  constructor(private platform: Platform, private store: Store) {}

  ngOnInit(): void {
    this.mobile = this.platform.ANDROID || this.platform.IOS;

    if (!this.mobile) {
      this.setUpTimeForm();
      this.updateControl();
    }

    this.claimsSub = this.claims$.subscribe((claims: any) => {
      if (!!claims) {
        this.date = moment(claims.h_tutkimuspvm._seconds * 1000);
        this.nextDayString = this.date.add(1, 'day').format('DD.MM.YYYY');
      }
    });
  }

  ngOnDestroy() {
    if (this.timeSub) {
      this.timeSub.unsubscribe();
    }
  }

  get hours() {
    return this.timeForm.get('hours') as FormControl;
  }

  get minutes() {
    return this.timeForm.get('minutes') as FormControl;
  }

  setUpTimeForm() {
    if (!!this.control.value) {
      this.splitValueToInputs(this.control.value);
    }
  }

  getTimeDelim(time: string) {
    if (time.indexOf('.') !== -1) {
      return '.';
    }
    if (time.indexOf(':') !== -1) {
      return ':';
    }
    return '';
  }

  normalizeValues(value) {
    return ('0' + value).slice(-2);
  }

  splitValueToInputs(value) {
    const delim = this.getTimeDelim(value);
    const parts = value.split(delim);
    if (parts.length === 2) {
      const hours = this.normalizeValues(parts[0]);
      const minutes = this.normalizeValues(parts[1]);
      this.hours.setValue(hours);
      this.minutes.setValue(minutes);
    }
  }

  updateControl() {
    this.timeSub = this.timeForm.valueChanges
      .pipe(
        startWith(
          this.timeForm.value as { hours: string; minutes: string } | null
        ),
        switchMap((value) => {
          return this.timeForm.statusChanges.pipe(
            map((status) => ({
              value,
              status
            }))
          );
        }),
        map(({ value, status }) => {
          if (status === 'INVALID') {
            this.control.setValue(null);
            return;
          }

          if (
            value === null ||
            value.hours === null ||
            value.minutes === null ||
            value.hours === '' ||
            value.minutes === ''
          ) {
            this.control.setValue(null);
          } else {
            this.control.setValue(
              this.normalizeValues(value.hours) +
                ':' +
                this.normalizeValues(value.minutes)
            );
          }
          this.control.markAsTouched();
          this.form.updateValueAndValidity();
        })
      )
      .subscribe();
  }
}
