import { cloneDeep, isEmpty } from 'lodash';
import { IOptionQuestion } from '../interfaces/interface.optionQuestion';
import {
  isTypeKeyBooleanPair,
  isTypeKeyValuePair,
  TypeAnswer
} from '../interfaces/type.answer';
import { OptionValue } from './OptionValue';
import { Question } from './Question';

export class OptionQuestion extends Question {
  private _originalQuestions: OptionValue[];

  public options!: OptionValue[]; // values for types select, radio, checkbox etc.. next is the key of the next question to answer // @todo: translations
  public otherChoice = false;
  public filterOptionsWithAnswersFrom?: string;
  public resetOption?: number[];

  constructor(
    question: IOptionQuestion,
    options: OptionValue[],
    guardian = false
  ) {
    super(question, guardian);
    this._originalQuestions = cloneDeep(options);
    this.options = options;
    if (!!question.resetOption && question.resetOption.length) {
      this.resetOption = question.resetOption;
    }
    if (!!question.filterOptionsWithAnswersFrom) {
      this.filterOptionsWithAnswersFrom = question.filterOptionsWithAnswersFrom;
    }
    this.setOther();
    return this;
  }

  getOption(value: number) {
    return this.options.find((opt) => opt.value === value);
  }

  getLabelFromValue(value: number) {
    const option = this.getOption(value);
    if (!!option) {
      return option.getLabel();
    }
    return '';
  }

  reset() {
    this.options = cloneDeep(this._originalQuestions);
  }

  filter(values: number[]) {
    this.options = this.options.filter((o) => values.includes(o.value));
  }

  setOther() {
    const others = this.getOtherChoises();
    this.otherChoice = !!others && !isEmpty(others);
  }

  getOtherChoises() {
    return this.options.filter((o) => !!o.other);
  }

  isOtherSelected(value: TypeAnswer) {
    const other = this.getOtherChoises();
    return !!(
      !!value &&
      isTypeKeyValuePair(value) &&
      other.find((option) => option.value === this.normalize(value[this.key]))
    );
  }

  isResetOptionSelected(value: TypeAnswer) {
    if (
      !!this.resetOption &&
      this.resetOption.length &&
      isTypeKeyBooleanPair(value)
    ) {
      const reset = this.resetOption.filter((r) => {
        return value[r.toString()];
      });

      if (reset.length) {
        return true;
      }
    }

    return false;
  }

  getSelectedOther(value: TypeAnswer): OptionValue | null | undefined {
    const other = this.getOtherChoises();
    return !!value && isTypeKeyValuePair(value)
      ? other.find((option) => option.value === this.normalize(value[this.key]))
      : null;
  }

  validateKeyBooleanCheckbox(value: TypeAnswer): boolean | string {
    if (value === null) {
      return true;
    }
    if (isTypeKeyBooleanPair(value)) {
      const keys = this.options.map((o) => o.value);
      const values = Object.keys(value);

      if (this.isResetOptionSelected(value)) {
        return true;
      } else if (keys.sort().join(',') === values.sort().join(',')) {
        const truthy = values.filter((v: string) => value[v]);
        if (this.validation && !!this.validation.max) {
          if (truthy.length > this.validation.max) {
            return `too many selections for ${this.key}`;
          }
        }

        if (this.validation && !!this.validation.min) {
          if (truthy.length < this.validation.min) {
            return `too few selections for ${this.key}`;
          }
        }

        if (this.required) {
          if (truthy.length < 1) {
            return `atleast one value must be selected for ${this.key}`;
          }
        }

        return true;
      } else {
        return `${this.key} answer value has too many or too few keys.
        Should have ${keys.sort().join(', ')}
        Has ${values.sort().join(', ')}`;
      }
    } else {
      return `${this.key} answer value is in wrong format. Should be TypeKeyBooleanPair`;
    }

    return true;
  }

  validateCheckbox(value: TypeAnswer) {
    if (value === null) {
      return true;
    }

    if (!Array.isArray(value)) {
      return `${this.key} answer value must be an array`;
    }

    for (const val of value) {
      const normalized = this.normalize(val);

      if (!this.options.find((option) => option.value === normalized)) {
        return `${this.key} Answer value must be one of predefined options. Value: ${normalized}`;
      }
    }

    if (this.validation && !!this.validation.max) {
      if (value.length > this.validation.max) {
        return `too many selections for ${this.key}`;
      }
    }

    if (this.validation && !!this.validation.min) {
      if (value.length < this.validation.min) {
        return `too few selections for ${this.key}`;
      }
    }

    if (this.required) {
      if (value.length < 1) {
        return `atleast one value must be selected for ${this.key}`;
      }
    }

    return true;
  }

  validate(value: TypeAnswer): boolean | string {
    if (
      value !== null &&
      !this.options.find((option) => option.value === value)
    ) {
      return `${this.key} Answer value must be one of predefined options. Value: ${value}`;
    }
    return true;
  }

  set answer(answer: TypeAnswer) {
    let value: any = cloneDeep(answer);
    value = this.normalize(value);

    let valid: boolean | string = false;
    if (
      this.otherChoice &&
      this.isOtherSelected(value) &&
      isTypeKeyValuePair(value)
    ) {
      // validate other
      // get other TypeQuestionClasses
      // --> set answer --> validate
      const answeredQuestion = this.getSelectedOther(value);
      // other but values are not right
      if (!!answeredQuestion && !!answeredQuestion.other) {
        // throws if not valid

        value[answeredQuestion.other.key] = this.normalize(
          value[answeredQuestion.other.key]
        ) as string | number;

        value[this.key] = this.normalize(value[this.key]) as string | number;

        answeredQuestion.other.answer = value[answeredQuestion.other.key];
        valid = this.validate(value[this.key]);
      } else {
        valid = `Value for answer ${
          this.key
        } is not valid, choice seems to be other,
        but other question with key is not found.
        Answer: ${JSON.stringify(value)}
        Others: ${JSON.stringify(this.getOtherChoises())}
        `;
      }
    } else if (this.type === 'checkbox') {
      valid = this.validateKeyBooleanCheckbox(value);
      // when input is set to disabled, angular does not send the other values, so we need to add those

      if (valid && this.isResetOptionSelected(value)) {
        const all = this.options
          .map((o) => ({ [o.value]: false }))
          .reduce((acc, curr) => ({ ...acc, ...curr }));
        value = { ...all, ...value };
      }
    } else {
      if (
        (this.controlType === 'radioOtherControl' ||
          this.controlType === 'selectOtherControl') &&
        isTypeKeyValuePair(value)
      ) {
        value[this.key] = this.normalize(value[this.key]) as string | number;
        valid = this.validate(value[this.key]);
      } else {
        value = this.normalize(value);
        valid = this.validate(value);
      }
    }

    if (valid === true) {
      this.value = value;
    } else if (typeof valid === 'string') {
      throw new Error(valid);
    }
  }

  get answer() {
    return this.value;
  }

  toObject() {
    return { ...this };
  }
}
