import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Credentials } from '@hlt-app/auth/models';

@Component({
  selector: 'hlt-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit {
  @Input() error!: any | null;
  @Output() submitted = new EventEmitter<Credentials>();
  form: FormGroup = new FormGroup(
    {
      token: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [
        Validators.required,
        Validators.pattern('^(?=.*?[A-ZÄÖÅ])(?=.*?[a-zäåö])(?=.*?[0-9]).{8,}$')
      ]),
      confirmPassword: new FormControl(null, [Validators.required])
    },
    this.passwordConfirming.bind(this)
  );

  constructor() {}

  @Input()
  set pending(isPending: boolean) {
    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  ngOnInit(): void {}

  passwordConfirming(frm: FormGroup): any {
    if (frm.get('password').value !== frm.get('confirmPassword').value) {
      this.form.controls.confirmPassword.setErrors({
        passwordsMismatch: true
      });
      return { passwordsMismatch: true };
    }
    return null;
  }

  submit() {
    if (this.form.valid) {
      this.submitted.emit(this.form.value);
    }
  }
}
