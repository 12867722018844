import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'hlt-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss']
})
export class FormCheckboxComponent implements OnInit {
  @Input() public control: FormControl;
  @Input() public group: FormGroup;
  @Input() public label: string;
  @Input() public name: string;
  @Input() public value: number;

  constructor() {}

  ngOnInit() {}
}
