import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hlt-firebase-errors',
  templateUrl: './firebase-errors.component.html',
  styleUrls: ['./firebase-errors.component.scss']
})
export class FirebaseErrorsComponent implements OnInit {
  @Input() error: any;

  constructor() {}

  ngOnInit(): void {}
}
