<label class="form-checkbox" [formGroup]="group">
  <input
    class="form-checkbox__input"
    type="checkbox"
    [name]="name"
    [formControl]="control"
  />
  <span class="form-checkbox__dummy" aria-hidden="true">
    <mat-icon class="checked-icon">check_box</mat-icon>
  </span>
  <span class="form-checkbox__label">{{ label }}</span>
</label>
