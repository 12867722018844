import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormFactoryService } from '@hlt-app/shared/services/form-factory.service';
import { isNumberString } from '@hlt-shared/Questions/helper/require-answer';
import { TypeQuestionClasses } from '@hlt-shared/Questions/interfaces/type.questionClasses';
import { Subscription } from 'rxjs';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';

let inputNextUniqueId = 0;

@Component({
  selector: 'hlt-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit, OnDestroy {
  @Input() floatLabel = 'always';
  @Input() type = 'text';
  @Input() placeholder = '';
  @Input() control: FormControl;
  @Input() form: FormGroup;
  @Input() label = '';
  @Input() autocomplete?;
  @Input() autocompleteConnectedTo?;
  @Input() question?: TypeQuestionClasses;
  @Input() map = false;
  @Input() distance = false;
  @Input() password = false;

  // tslint:disable-next-line
  protected _uid = `text-input-${inputNextUniqueId++}`;
  // tslint:disable-next-line
  protected _id = this._uid;

  @Input()
  get idFor(): string {
    return this._id;
  }
  set idFor(value: string) {
    this._id = value || this._uid;
  }

  public visible = false;
  public show: boolean;
  public infobox = false;
  public hidden = false;

  private sub: Subscription;
  private distanceSub: Subscription;

  constructor(private formFactoryService: FormFactoryService) {}

  getControl(controlName) {
    return this.form.get(controlName) as FormControl;
  }

  ngOnInit(): void {
    this.show =
      (this.type === 'password' && !this.visible) ||
      (this.type === 'text' && this.visible);

    if (!!this.question && this.question.key === 'T_SAHKOPPLKM') {
      this.addElectricbikeValidations();

      this.form.setValidators([
        this.formFactoryService.maxCombinedValueValidator('T_PPLKM', [
          'T_SAHKOPPLKM'
        ])
      ]);
    }

    if (!!this.question && this.question.key === 'A_SUORITE') {
      this.warnAboutTooManyKilometers();
    }

    if (!!this.question && this.question.type === 'hidden') {
      this.hidden = true;
    }

    if (
      !!this.question &&
      this.question.key === 'M_HENKILOMAARA' &&
      this.control.value === null
    ) {
      this.control.setValue('1');
      this.form.updateValueAndValidity();
    }
    if (this.distance) {
      this.distanceSub = this.control.valueChanges
        .pipe(
          filter((val) => {
            if (typeof val === 'number') {
              return false;
            }
            if (val.length < 2) {
              return false;
            }
            return val.startsWith('0');
          }),
          map((val) => val.replace(/^0+/, ''))
        )
        .subscribe((val) => {
          this.control.setValue(val);
          this.form.updateValueAndValidity();
        });
    }
  }

  // @todo: this should probably be its own input or atleast somewhere else.
  warnAboutTooManyKilometers() {
    this.sub = this.control.valueChanges
      .pipe(startWith(this.control.value as number), debounceTime(300))
      .subscribe((value) => {
        if (typeof value === 'string' && isNumberString(value)) {
          value = Number(value);
        }
        this.infobox = value >= 50000;
      });
  }

  // @todo: this should probably be its own input or atleast somewhere else.
  addElectricbikeValidations() {
    const otherControl = this.getControl('T_PPLKM');
    if (otherControl) {
      this.sub = otherControl.valueChanges
        .pipe(startWith(otherControl.value as number), debounceTime(300))
        .subscribe((value) => {
          if (value === 0 || value === '0' || value === null) {
            this.control.reset();
            this.control.disable();
          } else {
            this.control.enable();
          }
        });
    }
  }

  changeVisibility() {
    this.type = this.visible ? 'password' : 'text';
    this.visible = !this.visible;
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    if (this.distanceSub) {
      this.distanceSub.unsubscribe();
    }
  }
}
