<ng-container [formGroup]="form">
  <hlt-select-input
    [control]="select"
    [form]="group"
    [label]="question.getLabel() | translate"
    [options]="question.options"
    [groups]="question?.groups || null"
  ></hlt-select-input>
  <hlt-form-errors [control]="select"></hlt-form-errors>
  <hlt-question
    *ngIf="visible"
    [form]="group"
    [question]="otherField"
  ></hlt-question>
</ng-container>
