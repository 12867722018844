import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings
} from '@gilsdav/ngx-translate-router';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';
import {
  ClaimsPageComponent,
  LoginPageComponent,
  LogoutComponent,
  PhoneLoginComponent,
  PhoneRegisterComponent,
  RegisterPageComponent,
  ResetPasswordComponent,
  SelectUserComponent
} from '@hlt-app/auth/containers';
import {
  canActivate,
  phoneLoginPageRedirect,
  phoneRole,
  redirectLoggedInToStudy,
  redirectUnauthorizedToLogin,
  redirectUsersWithoutRoleToClaims
} from '@hlt-app/auth/guards';
import { AnswerTimeExpiredGuard } from '@hlt-app/auth/guards/answer-time-expired.guard';
import { CompletedGuard } from '@hlt-app/auth/guards/completed.guard';
import { NotCompletedGuard } from '@hlt-app/auth/guards/not-completed.guard';
import { NotFoundComponent } from '@hlt-app/core/containers';
import {
  AnswerTimeEndedComponent,
  DrawComponent,
  QuestionareCompletedComponent
} from '@hlt-app/shared/components';
import { TranslateService } from '@ngx-translate/core';

export function HttpLoaderFactory(
  translate: TranslateService,
  location: Location,
  settings: LocalizeRouterSettings,
  http: HttpClient
) {
  return new LocalizeRouterHttpLoader(
    translate,
    location,
    { ...settings, alwaysSetPrefix: true },
    http
  );
}

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./core/core.module').then((m) => m.CoreModule)
  },
  {
    path: 'login',
    component: LoginPageComponent,
    ...canActivate(redirectLoggedInToStudy, { title: 'PAGETITLES.LOGIN' })
  },
  {
    path: 'claims',
    component: ClaimsPageComponent,
    ...canActivate(redirectUnauthorizedToLogin, { title: 'PAGETITLES.CLAIMS' })
  },
  {
    path: 'register',
    component: RegisterPageComponent,
    data: { title: 'PAGETITLES.REGISTER' }
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: { title: 'PAGETITLES.RESETPASSWORD' }
  },
  {
    path: 'study',
    loadChildren: () =>
      import('./study/study.module').then((m) => m.StudyModule),
    ...canActivate(
      redirectUsersWithoutRoleToClaims,
      {
        title: 'PAGETITLES.STUDY',
        class: 'corner'
      },
      [CompletedGuard, AnswerTimeExpiredGuard]
    )
  },
  {
    path: 'kantar/register',
    component: PhoneRegisterComponent,
    ...canActivate(phoneLoginPageRedirect, {
      title: 'PAGETITLES.KANTARREGISTER'
    })
  },
  {
    path: 'kantar/login',
    component: PhoneLoginComponent,
    ...canActivate(phoneLoginPageRedirect, { title: 'PAGETITLES.KANTARLOGIN' })
  },
  {
    path: 'kantar/select-user/:h_nettitunnus',
    component: SelectUserComponent,
    ...canActivate(phoneRole, { title: 'PAGETITLES.KANTARSELECTUSER' })
  },
  {
    path: 'kantar/select-user',
    component: SelectUserComponent,
    ...canActivate(phoneRole, { title: 'PAGETITLES.KANTARSELECTUSER' })
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: { title: 'PAGETITLES.LOGOUT' }
  },
  {
    path: 'draw',
    component: DrawComponent,
    data: { title: 'PAGETITLES.SUMMARY' },
    canActivate: [NotCompletedGuard]
  },
  {
    path: 'completed',
    component: QuestionareCompletedComponent,
    data: { title: '' }
  },
  {
    path: 'answer-time-ended',
    component: AnswerTimeEndedComponent,
    data: { title: '' }
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: { title: 'PAGETITLES.NOTFOUND' }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false }),
    LocalizeRouterModule.forRoot(routes, {
      defaultLangFunction: (
        languages: string[],
        cachedLang?: string,
        browserLang?: string
      ) => {
        if (!!cachedLang) {
          return cachedLang;
        } else {
          return 'fi';
        }
      },
      parser: {
        provide: LocalizeParser,
        useFactory: HttpLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
      }
    })
  ],
  exports: [RouterModule, LocalizeRouterModule]
})
export class AppRoutingModule {}
