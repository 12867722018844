import { hasAnswerValue, requireAnswerValue } from '../helper/require-answer';
import { Answers } from '../interfaces/type.answer';
// import { Answers, isTypeKeyBooleanPair } from '../interfaces/type.answer';
import { TypeQuestion } from '../interfaces/type.question';

export const habitQuestions: TypeQuestion[] = [
  {
    key: 'T_KAUSILIPPU',
    main: true,
    title: 'Onko sinulla voimassaolevaa joukkoliikenteen kausilippua?',
    guardianTitle: 'Onko hänellä voimassaolevaa joukkoliikenteen kausilippua?',
    label: 'Onko sinulla voimassaolevaa joukkoliikenteen kausilippua?',
    controlType: 'radioControl',
    type: 'radio',
    required: true,
    value: null,
    validation: {},
    next: 'T_PPLKM',
    options: [
      {
        key: 'T_KAUSILIPPU_1',
        label: 'Kyllä',
        value: 1
      },
      {
        key: 'T_KAUSILIPPU_2',
        label: 'Ei',
        value: 2
      }
    ]
  },

  {
    key: 'T_PPLKM',
    main: true,
    title: 'Kuinka monta toimivaa polkupyörää taloudessasi on?',
    guardianTitle: 'Kuinka monta toimivaa polkupyörää hänen taloudessaan on?',
    label: 'Polkupyörien määrä',
    placeholder: 'Esim. 2',
    controlType: 'textControl',
    type: 'text',
    required: false,
    value: null,
    validation: {
      min: 0,
      pattern: /^\d+$/
    },
    siblings: ['T_SAHKOPPLKM'],
    next: 'T_AUTOT',
    skip: (answers: Answers) => {
      const areaAnswerValue = requireAnswerValue(
        'h_otosalue_id',
        answers,
        'T_HAYMP'
      ) as number;
      return areaAnswerValue !== 1;
    }
  },
  {
    key: 'T_SAHKOPPLKM',
    main: false,
    label: 'Kuinka moni näistä on sähköpyörä?',
    placeholder: 'Esim. 0',
    controlType: 'textControl',
    type: 'text',
    required: false,
    value: null,
    validation: {
      min: 0,
      pattern: /^\d+$/
    }
  },

  {
    key: 'T_AUTOT',
    main: true,
    title:
      'Kuinka monta henkilöautoa kotitaloudessasi on vakituisesti käytössä? Lisää jokainen.',
    guardianTitle:
      'Kuinka monta henkilöautoa hänen kotitaloudessaan on vakituisesti käytössä? Lisää jokainen.',
    label: 'Lisää henkilöauto',
    controlType: 'formArrayControl',
    guideTexts: {
      edit: '= muokkaa henkilöauton tietoja',
      delete: '= poista henkilöauto',
      drag: '= siirrä ylös tai alas raahaamalla'
    },
    skipText: 'Ei henkilöautoja',
    icon: 'directions_car',
    contentType: 'auto',
    type: '',
    required: false,
    value: null,
    validation: {},
    next: 'T_HAKULJ',
    fields: [
      {
        key: 'A_TYOSUHDE',
        main: false,
        label: 'Onko tämä työsuhdeauto?',
        prependAnswer: 'Työsuhdeauto:',
        controlType: 'radioControl',
        type: 'radio',
        required: true,
        validation: {},
        value: null,
        // skip: (answers: Answers) => {
        //   const areaAnswerValue = requireAnswerValue(
        //     'h_otosalue_id',
        //     answers,
        //     'A_TYOSUHDE'
        //   ) as number;
        //   return areaAnswerValue !== 99;
        // },
        options: [
          {
            key: 'A_TYOSUHDE_1',
            label: 'Kyllä',
            value: 1
          },
          {
            key: 'A_TYOSUHDE_2',
            label: 'Ei',
            value: 2
          }
        ]
      }
      // {
      //   key: 'A_SUORITE',
      //   main: false,
      //   label:
      //     'Ajokilometrit viimeisen 12 kuukauden aikana? Jos olet vaihtanut autoasi vuoden sisällä, laske yhteen  entisen ja nykyisen auton kilometrit 12 kuukauden aikana samalle riville. ',
      //   guardianLabel:
      //     'Ajokilometrit viimeisen 12 kuukauden aikana? Jos autoa on vaihdettu vuoden sisällä, laske yhteen entisen ja nykyisen auton kilometrit 12 kuukauden aikana samalle riville. ',
      //   placeholder: 'Esim. 12 000',
      //   prependAnswer: 'Ajokilometrit viimeisen 12kk aikana:',
      //   controlType: 'textControl',
      //   type: 'text',
      //   required: false,
      //   validation: {
      //     min: 0,
      //     pattern: /^\d+$/
      //   },
      //   value: null,
      //   skip: (answers: Answers) => {
      //     if (!hasAnswerValue('A_EOS', answers)) {
      //       return false;
      //     }
      //     const answerValue = requireAnswerValue('A_EOS', answers, 'A_SUORITE');
      //     if (isTypeKeyBooleanPair(answerValue)) {
      //       return answerValue['1'];
      //     } else {
      //       return false;
      //     }
      //   }
      // },
      // {
      //   key: 'A_EOS',
      //   main: false,
      //   label: 'En tiedä ajokilometrejä viimeisen 12 kuukauden ajalta ',
      //   placeholder: '',
      //   prependAnswer: '',
      //   controlType: 'checkboxControl',
      //   type: 'checkbox',
      //   required: false,
      //   validation: {},
      //   value: null,
      //   options: [
      //     {
      //       key: 'A_EOS_1',
      //       value: 1,
      //       label: 'En osaa sanoa ajokilometrejä viimeisen 12 kuukauden ajalta',
      //       guardianLabel:
      //         'Hän ei osaa sanoa ajokilometrejä viimeisen 12 kuukauden ajalta'
      //     }
      //   ]
      // }
    ]
  },

  {
    key: 'T_HAKULJ',
    main: true,
    title:
      'Kuinka usein sinulla on käytettävissäsi henkilöauto matkoihisi niin, että ajat itse?',
    label:
      'Kuinka usein sinulla on käytettävissäsi henkilöauto matkoihisi niin, että ajat itse?',
    controlType: 'radioControl',
    type: 'radio',
    required: true,
    value: null,
    validation: {},
    next: 'T_HAMATK',
    skip: (answers: Answers) => {
      if (!hasAnswerValue('T_AJOKORTTI', answers)) {
        return true;
      }
      const licenceAnswerValue = requireAnswerValue(
        'T_AJOKORTTI',
        answers,
        'T_HAKULJ'
      );
      return licenceAnswerValue !== 1;
    },
    options: [
      {
        key: 'T_HAKULJ_1',
        label: 'Aina tai lähes aina',
        value: 1
      },
      {
        key: 'T_HAKULJ_2',
        label: 'Joskus',
        value: 2
      },
      {
        key: 'T_HAKULJ_3',
        label: 'Ei koskaan',
        value: 3
      },
      {
        key: 'T_HAKULJ_4',
        label: 'En osaa sanoa',
        guardianLabel: 'Ei osaa sanoa',
        value: 4
      }
    ]
  },

  {
    key: 'T_HAMATK',
    main: true,
    title:
      'Kuinka usein sinulla on käytettävissäsi henkilöauto matkoihisi niin, että joku toinen ajaa?',
    guardianTitle:
      'Kuinka usein hänellä on käytettävissään henkilöauto matkoihinsa niin, että joku toinen ajaa?',
    label:
      'Kuinka usein sinulla on käytettävissäsi henkilöauto matkoihisi niin, että joku toinen ajaa?',
    controlType: 'radioControl',
    type: 'radio',
    required: true,
    value: null,
    validation: {},
    next: 'T_HAYMP',
    options: [
      {
        key: 'T_HAMATK_1',
        label: 'Aina tai lähes aina',
        value: 1
      },
      {
        key: 'T_HAMATK_2',
        label: 'Joskus',
        value: 2
      },
      {
        key: 'T_HAMATK_3',
        label: 'Ei koskaan',
        value: 3
      },
      {
        key: 'T_HAMATK_4',
        label: 'En osaa sanoa',
        guardianLabel: 'Ei osaa sanoa',
        value: 4
      }
    ]
  },

  {
    key: 'T_HAYMP',
    main: true,
    title:
      'Oletko vähentänyt henkilöauton käyttöä ympäristösyistä viimeisen 12 kuukauden aikana?',
    guardianTitle:
      'Onko hän vähentänyt henkilöauton käyttöä ympäristösyistä viimeisen 12 kuukauden aikana?',
    label:
      'Oletko vähentänyt henkilöauton käyttöä ympäristösyistä viimeisen 12 kuukauden aikana?',
    controlType: 'radioControl',
    type: 'radio',
    required: false,
    value: null,
    validation: {},
    skip: (answers: Answers) => {
      const areaAnswerValue = requireAnswerValue(
        'h_otosalue_id',
        answers,
        'T_HAYMP'
      ) as number;
      if ([3].indexOf(areaAnswerValue) === -1) {
        return true;
      }
      if (!hasAnswerValue('T_IKA', answers)) {
        return false;
      }
      const ageAnswerValue = requireAnswerValue('T_IKA', answers, 'T_HAYMP');
      return !!(ageAnswerValue && ageAnswerValue < 15);
    },
    next: 'T_USEINHA',
    options: [
      {
        key: 'T_HAYMP_1',
        label: 'Kyllä, paljon',
        value: 1
      },
      {
        key: 'T_HAYMP_2',
        label: 'Kyllä, jonkin verran',
        value: 2
      },
      {
        key: 'T_HAYMP_3',
        label: 'En ole vähentänyt',
        guardianLabel: 'Ei ole vähentänyt ',
        value: 3
      },
      {
        key: 'T_HAYMP_4',
        label: 'En, koska en ole aikaisemminkaan juuri käyttänyt henkilöautoa',
        guardianLabel:
          'Ei, koska ei ole aikaisemminkaan juuri käyttänyt henkilöautoa ',
        value: 4
      },
      {
        key: 'T_HAYMP_5',
        label: 'En osaa sanoa',
        guardianLabel: 'Ei osaa sanoa',
        value: 5
      }
    ]
  },

  {
    key: 'T_LESTEET',
    main: false,
    title: 'Onko sinulla jokin liikkumista haittaava este? Voit valita useita.',
    guardianTitle:
      'Onko hänellä jokin liikkumista haittaava este? Voit valita useita.',
    label: 'Onko sinulla jokin liikkumista haittaava este? Voit valita useita.',
    controlType: 'checkboxControl',
    type: 'checkbox',
    required: false,
    value: null,
    validation: {},
    resetOption: [1, 9],
    next: null,
    skip: (answers: Answers) => {
      const areaAnswerValue = requireAnswerValue(
        'h_otosalue_id',
        answers,
        'T_HAYMP'
      ) as number;
      return areaAnswerValue !== 99;
    },
    options: [
      {
        key: 'T_LESTEET_1',
        label: 'Ei liikkumis- tai toimintaesteitä',
        value: 1
      },
      {
        key: 'T_LESTEET_2',
        label: 'Vaikeus seistä tai kävellä',
        value: 2
      },
      {
        key: 'T_LESTEET_3',
        label: 'Vaikeus nousta tai laskeutua portaita',
        value: 3
      },
      {
        key: 'T_LESTEET_4',
        label: 'Heikko näkö tai sokeus',
        value: 4
      },
      {
        key: 'T_LESTEET_5',
        label: 'Vaikeus kuulla tai kuurous',
        value: 5
      },
      {
        key: 'T_LESTEET_6',
        label: 'Ymmärtämisen tai osaamisen vaikeus tai muistivaikeus',
        value: 6
      },
      {
        key: 'T_LESTEET_7',
        label:
          'Heikkovoimaisuus (esim. vaikeus kantaa tai kuljettaa tavaroita)',
        value: 7
      },
      {
        key: 'T_LESTEET_8',
        label: 'Muu este',
        value: 8
      },
      {
        key: 'T_LESTEET_9',
        label: 'En osaa sanoa tai en halua vastata',
        guardianLabel: 'Ei osaa sanoa tai ei halua vastata',
        value: 9
      }
    ]
  },

  {
    key: 'T_USEINHA',
    main: true,
    title:
      'Kuinka usein käytit seuraavia kulkutapoja viimeisen kahden kuukauden aikana?',
    guardianTitle:
      'Kuinka usein hän käytti seuraavia kulkutapoja viimeisen kahden kuukauden aikana?',
    label: 'Henkilöauto',
    controlType: 'selectControl',
    type: 'select',
    required: false,
    value: null,
    validation: {},
    siblings: ['T_USEINJL', 'T_USEINJK', 'T_USEINPP'],
    next: 'T_INTPALVELUT',
    skip: (answers: Answers) => {
      const areaAnswerValue = requireAnswerValue(
        'h_otosalue_id',
        answers,
        'T_HAYMP'
      ) as number;
      return areaAnswerValue !== 99;
    },
    options: [
      {
        key: 'T_USEINHA_1',
        label: '5–7 päivänä viikossa',
        value: 1
      },
      {
        key: 'T_USEINHA_2',
        label: '2–4 päivänä viikossa',
        value: 2
      },
      {
        key: 'T_USEINHA_3',
        label: 'Yhtenä päivänä viikossa',
        value: 3
      },
      {
        key: 'T_USEINHA_4',
        label: 'Harvemmin',
        value: 4
      },
      {
        key: 'T_USEINHA_5',
        label: 'En ole käyttänyt viimeisen kahden kuukauden aikana',
        guardianLabel: 'Ei ole käyttänyt viimeisen kahden kuukauden aikana',
        value: 5
      },
      {
        key: 'T_USEINHA_6',
        label: 'En osaa sanoa',
        guardianLabel: 'Ei osaa sanoa',
        value: 6
      }
    ]
  },
  {
    key: 'T_USEINJL',
    main: false,
    label: 'Joukkoliikenne',
    controlType: 'selectControl',
    type: 'select',
    required: false,
    value: null,
    validation: {},
    options: [
      {
        key: 'T_USEINJL_1',
        label: '5–7 päivänä viikossa',
        value: 1
      },
      {
        key: 'T_USEINJL_2',
        label: '2–4 päivänä viikossa',
        value: 2
      },
      {
        key: 'T_USEINJL_3',
        label: 'Yhtenä päivänä viikossa',
        value: 3
      },
      {
        key: 'T_USEINJL_4',
        label: 'Harvemmin',
        value: 4
      },
      {
        key: 'T_USEINJL_5',
        label: 'En ole käyttänyt viimeisen kahden kuukauden aikana',
        guardianLabel: 'Ei ole käyttänyt viimeisen kahden kuukauden aikana',
        value: 5
      },
      {
        key: 'T_USEINJL_6',
        label: 'En osaa sanoa',
        guardianLabel: 'Ei osaa sanoa',
        value: 6
      }
    ]
  },
  {
    key: 'T_USEINJK',
    main: false,
    label: 'Kävely (koko matka)',
    controlType: 'selectControl',
    type: 'select',
    required: false,
    value: null,
    validation: {},
    options: [
      {
        key: 'T_USEINJK_1',
        label: '5–7 päivänä viikossa',
        value: 1
      },
      {
        key: 'T_USEINJK_2',
        label: '2–4 päivänä viikossa',
        value: 2
      },
      {
        key: 'T_USEINJK_3',
        label: 'Yhtenä päivänä viikossa',
        value: 3
      },
      {
        key: 'T_USEINJK_4',
        label: 'Harvemmin',
        value: 4
      },
      {
        key: 'T_USEINJK_5',
        label: 'En ole käyttänyt viimeisen kahden kuukauden aikana',
        guardianLabel: 'Ei ole käyttänyt viimeisen kahden kuukauden aikana',
        value: 5
      },
      {
        key: 'T_USEINJK_6',
        label: 'En osaa sanoa',
        guardianLabel: 'Ei osaa sanoa',
        value: 6
      }
    ]
  },
  {
    key: 'T_USEINPP',
    main: false,
    label: 'Pyöräily (koko matka)',
    controlType: 'selectControl',
    type: 'select',
    required: false,
    value: null,
    validation: {},
    options: [
      {
        key: 'T_USEINPP_1',
        label: '5–7 päivänä viikossa',
        value: 1
      },
      {
        key: 'T_USEINPP_2',
        label: '2–4 päivänä viikossa',
        value: 2
      },
      {
        key: 'T_USEINPP_3',
        label: 'Yhtenä päivänä viikossa',
        value: 3
      },
      {
        key: 'T_USEINPP_4',
        label: 'Harvemmin',
        value: 4
      },
      {
        key: 'T_USEINPP_5',
        label: 'En ole käyttänyt viimeisen kahden kuukauden aikana',
        guardianLabel: 'Ei ole käyttänyt viimeisen kahden kuukauden aikana',
        value: 5
      },
      {
        key: 'T_USEINPP_6',
        label: 'En osaa sanoa',
        guardianLabel: 'Ei osaa sanoa',
        value: 6
      }
    ]
  },

  {
    key: 'T_INTPALVELUT',
    main: true,
    title:
      'Mitä seuraavista palveluista olet käyttänyt viimeisen kahden kuukauden aikana?',
    guardianTitle:
      'Mitä seuraavista palveluista hän on käyttänyt viimeisen kahden kuukauden aikana?',
    label:
      'Mitä seuraavista palveluista olet käyttänyt viimeisen kahden kuukauden aikana?',
    controlType: 'checkboxControl',
    type: 'checkbox',
    required: false,
    value: null,
    resetOption: [7],
    validation: {},
    next: 'T_PANOSTUSJK',
    options: [
      {
        key: 'T_INTPALVELUT_1',
        label: 'Kimppakyydin sopiminen internetissä',
        value: 1
      },
      {
        key: 'T_INTPALVELUT_2',
        label: 'Yhteiskäyttöauto tai vuokra-auto',
        value: 2
      },
      {
        key: 'T_INTPALVELUT_3',
        label: 'Kaupunkipyörä, tavallinen',
        value: 3
      },
      {
        key: 'T_INTPALVELUT_8',
        label: 'Kaupunkipyörä, sähköavusteinen',
        value: 8
      },
      {
        key: 'T_INTPALVELUT_4',
        label: 'Yhteiskäyttöinen sähköpotkulauta (esim. Voi, Tier, Lime)',
        value: 4
      },
      {
        key: 'T_INTPALVELUT_5',
        label:
          'Eri kulkutapoja yhdistelevä maksullinen liikkumispalvelu',
        value: 5
      },
      {
        key: 'T_INTPALVELUT_6',
        label: 'Ruokaostosten ja päivittäistavaroiden kotiinkuljetus',
        value: 6
      },
      {
        key: 'T_INTPALVELUT_9',
        label: 'Valmiin ruuan tilaaminen kotiin',
        value: 9
      },
      {
        key: 'T_INTPALVELUT_7',
        label: 'En mitään näistä',
        guardianLabel: 'Ei mitään näistä',
        value: 7
      }
    ]
  },

  {
    key: 'T_TYOMATKAEDUT',
    main: false,
    title: 'Mitä seuraavista liikkumiseen liittyvistä työsuhde-eduista käytät?',
    label: 'Mitä seuraavista liikkumiseen liittyvistä työsuhde-eduista käytät?',
    controlType: 'checkboxControl',
    type: 'checkbox',
    required: false,
    value: null,
    resetOption: [11],
    validation: {},
    next: null,
    skip: (answers: Answers) => {
      const areaAnswerValue = requireAnswerValue(
        'h_otosalue_id',
        answers,
        'T_TYOMATKAEDUT'
      ) as number;
      if (!hasAnswerValue('T_ANSIOTYO', answers)) {
        return true;
      }
      const workingAnswerValue = requireAnswerValue(
        'T_ANSIOTYO',
        answers,
        'T_TYOMATKAEDUT'
      ) as number;
      return (
        areaAnswerValue !== 99 ||
        [1, 2, 3, 4].indexOf(workingAnswerValue) === -1
      );
    },
    options: [
      {
        key: 'T_TYOMATKAEDUT_1',
        label: 'Työsuhdeauto',
        value: 1
      },
      {
        key: 'T_TYOMATKAEDUT_2',
        label: 'Työnantajan täysin maksama pysäköinti työpaikalla',
        value: 2
      },
      {
        key: 'T_TYOMATKAEDUT_3',
        label: 'Työnantajan osittain maksama pysäköinti työpaikalla',
        value: 3
      },
      {
        key: 'T_TYOMATKAEDUT_4',
        label: 'Työnantajan tukema joukkoliikennelippu',
        value: 4
      },
      {
        key: 'T_TYOMATKAEDUT_5',
        label: 'Työnantajan tuki pyörän ostamiseen tai huoltoon',
        value: 5
      },
      {
        key: 'T_TYOMATKAEDUT_6',
        label: 'Työnantajan tarjoama polkupyörän pysäköintipaikka',
        value: 6
      },
      {
        key: 'T_TYOMATKAEDUT_7',
        label: 'Korkealaatuiset suihku- ja pukeutumistilat',
        value: 7
      },
      {
        key: 'T_TYOMATKAEDUT_8',
        label: 'Yhteiskäyttöautot',
        value: 8
      },
      {
        key: 'T_TYOMATKAEDUT_9',
        label: 'Työnantajan maksama kaupunkipyörä tai yhteiskäyttöpolkupyörä',
        value: 9
      },
      {
        key: 'T_TYOMATKAEDUT_10',
        label: 'Muu etu',
        value: 10
      },
      {
        key: 'T_TYOMATKAEDUT_11',
        label: 'En mitään näistä',
        guardianLabel: 'Ei mitään näistä',
        value: 11
      }
    ]
  },

  {
    key: 'T_PANOSTUSJK',
    main: true,
    title:
      'Kuinka paljon asuinkunnassasi pitäisi mielestäsi panostaa seuraaviin asioihin?',
    guardianTitle:
      'Kuinka paljon hänen asuinkunnassaan pitäisi hänen mielestään panostaa seuraaviin asioihin?',
    label: 'Jalankulun olosuhteet',
    controlType: 'selectControl',
    type: 'select',
    required: false,
    value: null,
    validation: {},
    next: 'T_CO2VAHENNYS',
    siblings: ['T_PANOSTUSPP', 'T_PANOSTUSJL', 'T_PANOSTUSHA', 'T_PANOSTUSETA'],
    skip: (answers: Answers) => {
      const areaAnswerValue = requireAnswerValue(
        'h_otosalue_id',
        answers,
        'T_PANOSTUSJK'
      ) as number;
      if ([2, 5, 6, 7, 8, 9, 10, 11, 12].indexOf(areaAnswerValue) === -1) {
        return true;
      }
      if (!hasAnswerValue('T_IKA', answers)) {
        return false;
      }
      const ageAnswerValue = requireAnswerValue(
        'T_IKA',
        answers,
        'T_PANOSTUSJK'
      );
      return !!(ageAnswerValue && ageAnswerValue < 15);
    },
    options: [
      {
        key: 'T_PANOSTUSJK_1',
        label: 'Merkittävästi enemmän kuin nyt',
        value: 1
      },
      {
        key: 'T_PANOSTUSJK_2',
        label: 'Hieman enemmän kuin nyt',
        value: 2
      },
      {
        key: 'T_PANOSTUSJK_3',
        label: 'Saman verran kuin nyt',
        value: 3
      },
      {
        key: 'T_PANOSTUSJK_4',
        label: 'Hieman vähemmän kuin nyt',
        value: 4
      },
      {
        key: 'T_PANOSTUSJK_5',
        label: 'Merkittävästi vähemän kuin nyt',
        value: 5
      },
      {
        key: 'T_PANOSTUSJK_6',
        label: 'En osaa sanoa',
        guardianLabel: 'Ei osaa sanoa',
        value: 6
      }
    ]
  },
  {
    key: 'T_PANOSTUSPP',
    main: false,
    label: 'Pyöräliikenteen olosuhteet',
    controlType: 'selectControl',
    type: 'select',
    required: false,
    value: null,
    validation: {},
    options: [
      {
        key: 'T_PANOSTUSPP_1',
        label: 'Merkittävästi enemmän kuin nyt',
        value: 1
      },
      {
        key: 'T_PANOSTUSPP_2',
        label: 'Hieman enemmän kuin nyt',
        value: 2
      },
      {
        key: 'T_PANOSTUSPP_3',
        label: 'Saman verran kuin nyt',
        value: 3
      },
      {
        key: 'T_PANOSTUSPP_4',
        label: 'Hieman vähemmän kuin nyt',
        value: 4
      },
      {
        key: 'T_PANOSTUSPP_5',
        label: 'Merkittävästi vähemän kuin nyt',
        value: 5
      },
      {
        key: 'T_PANOSTUSPP_6',
        label: 'En osaa sanoa',
        guardianLabel: 'Ei osaa sanoa',
        value: 6
      }
    ]
  },
  {
    key: 'T_PANOSTUSJL',
    main: false,
    label: 'Joukkoliikennepalvelut',
    controlType: 'selectControl',
    type: 'select',
    required: false,
    value: null,
    validation: {},
    options: [
      {
        key: 'T_PANOSTUSJL_1',
        label: 'Merkittävästi enemmän kuin nyt',
        value: 1
      },
      {
        key: 'T_PANOSTUSJL_2',
        label: 'Hieman enemmän kuin nyt',
        value: 2
      },
      {
        key: 'T_PANOSTUSJL_3',
        label: 'Saman verran kuin nyt',
        value: 3
      },
      {
        key: 'T_PANOSTUSJL_4',
        label: 'Hieman vähemmän kuin nyt',
        value: 4
      },
      {
        key: 'T_PANOSTUSJL_5',
        label: 'Merkittävästi vähemän kuin nyt',
        value: 5
      },
      {
        key: 'T_PANOSTUSJL_6',
        label: 'En osaa sanoa',
        guardianLabel: 'Ei osaa sanoa',
        value: 6
      }
    ]
  },
  {
    key: 'T_PANOSTUSHA',
    main: false,
    label: 'Henkilöautoilun olosuhteet',
    controlType: 'selectControl',
    type: 'select',
    required: false,
    value: null,
    validation: {},
    options: [
      {
        key: 'T_PANOSTUSHA_1',
        label: 'Merkittävästi enemmän kuin nyt',
        value: 1
      },
      {
        key: 'T_PANOSTUSHA_2',
        label: 'Hieman enemmän kuin nyt',
        value: 2
      },
      {
        key: 'T_PANOSTUSHA_3',
        label: 'Saman verran kuin nyt',
        value: 3
      },
      {
        key: 'T_PANOSTUSHA_4',
        label: 'Hieman vähemmän kuin nyt',
        value: 4
      },
      {
        key: 'T_PANOSTUSHA_5',
        label: 'Merkittävästi vähemän kuin nyt',
        value: 5
      },
      {
        key: 'T_PANOSTUSHA_6',
        label: 'En osaa sanoa',
        guardianLabel: 'Ei osaa sanoa',
        value: 6
      }
    ]
  },
  {
    key: 'T_PANOSTUSETA',
    main: false,
    label: 'Liikenteen vähentäminen etätyötä lisäämällä',
    controlType: 'selectControl',
    type: 'select',
    required: false,
    value: null,
    validation: {},
    skip: (answers: Answers) => {
      const areaAnswerValue = requireAnswerValue(
        'h_otosalue_id',
        answers,
        'T_HAYMP'
      ) as number;
      return [5, 8, 9, 12].indexOf(areaAnswerValue) === -1;
    },
    options: [
      {
        key: 'T_PANOSTUSETA_1',
        label: 'Merkittävästi enemmän kuin nyt',
        value: 1
      },
      {
        key: 'T_PANOSTUSETA_2',
        label: 'Hieman enemmän kuin nyt',
        value: 2
      },
      {
        key: 'T_PANOSTUSETA_3',
        label: 'Saman verran kuin nyt',
        value: 3
      },
      {
        key: 'T_PANOSTUSETA_4',
        label: 'Hieman vähemmän kuin nyt',
        value: 4
      },
      {
        key: 'T_PANOSTUSETA_5',
        label: 'Merkittävästi vähemän kuin nyt',
        value: 5
      },
      {
        key: 'T_PANOSTUSETA_6',
        label: 'En osaa sanoa',
        guardianLabel: 'Ei osaa sanoa',
        value: 6
      }
    ]
  },

  {
    key: 'T_CO2VAHENNYS',
    main: true,
    title:
      'Kuinka tärkeänä pidät liikenteen hiilidioksidipäästöjen vähentämistä?',
    guardianTitle:
      'Kuinka tärkeänä hän pitää liikenteen hiilidioksidipäästöjen vähentämistä?',
    label:
      'Kuinka tärkeänä pidät liikenteen hiilidioksidipäästöjen vähentämistä?',
    controlType: 'radioControl',
    type: 'radio',
    required: false,
    value: null,
    validation: {},
    next: null,
    skip: (answers: Answers) => {
      const areaAnswerValue = requireAnswerValue(
        'h_otosalue_id',
        answers,
        'T_HAYMP'
      ) as number;
      if ([3].indexOf(areaAnswerValue) === -1) {
        return true;
      }
      if (!hasAnswerValue('T_IKA', answers)) {
        return false;
      }
      const ageAnswerValue = requireAnswerValue(
        'T_IKA',
        answers,
        'T_CO2VAHENNYS'
      );
      return !!(ageAnswerValue && ageAnswerValue < 15);
    },
    options: [
      {
        key: 'T_CO2VAHENNYS_1',
        label: 'Erittäin tärkeänä',
        value: 1
      },
      {
        key: 'T_CO2VAHENNYS_2',
        label: 'Melko tärkeänä',
        value: 2
      },
      {
        key: 'T_CO2VAHENNYS_3',
        label: 'En kovin tärkeänä',
        guardianLabel: 'Ei kovin tärkeänä',
        value: 3
      },
      {
        key: 'T_CO2VAHENNYS_4',
        label: 'En lainkaan tärkeänä',
        guardianLabel: 'Ei lainkaan tärkeänä',
        value: 4
      },
      {
        key: 'T_CO2VAHENNYS_5',
        label: 'En osaa sanoa',
        guardianLabel: 'Ei osaa sanoa',
        value: 5
      }
    ]
  }
];
