import { HabitActions } from '@hlt-app/study/actions';
import { createReducer, on } from '@ngrx/store';
import { QuestionsState } from '.';

const initialState: QuestionsState = {
  answers: {},
  error: null,
  pending: false
};

const reducer = createReducer(
  initialState,
  on(HabitActions.setHabitData, (state, { answers }) => ({
    ...state,
    answers,
    pending: false,
    error: null
  })),
  on(HabitActions.saveHabit, (state, { answers }) => ({
    ...state,
    answers: { ...state.answers, ...answers },
    pending: true
  })),
  on(HabitActions.saveHabitSuccess, (state) => ({
    ...state,
    pending: false,
    error: null
  })),
  // tslint:disable-next-line: no-shadowed-variable
  on(HabitActions.saveHabitFailure, (state, { error }) => ({
    ...state,
    pending: false,
    error
  }))
);

const getAnswers = (state: QuestionsState) => state.answers;
const pending = (state: QuestionsState) => state.pending;
const error = (state: QuestionsState) => state.error;

export const HabitQuestionsFeatureKey = 'habitQuestions';

export const HabitQuestionsReducer = {
  reducer,
  getAnswers,
  pending,
  error
};
