import { Component, OnDestroy, OnInit } from '@angular/core';
import * as fromAuth from '@hlt-app/auth/reducers';
import { FirestoreService } from '@hlt-app/study/services/firestore.service';
import { select, Store } from '@ngrx/store';
import { auth } from 'firebase';
import { Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'hlt-questionare-completed',
  templateUrl: './questionare-completed.component.html',
  styleUrls: ['./questionare-completed.component.scss']
})
export class QuestionareCompletedComponent implements OnInit, OnDestroy {
  public user$: Observable<auth.IdTokenResult> = this.store.pipe(
    select(fromAuth.selectUser)
  );
  public phoneUser = false;
  public guardian = false;
  private userSub: Subscription;
  constructor(
    private firestoreService: FirestoreService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.userSub = this.user$
      .pipe(
        filter((user) => !!user),
        switchMap((user) => this.loadData(user)),
        map((guardian) => (this.guardian = guardian))
      )
      .subscribe();
  }

  loadData(user) {
    if (user.claims.role > 1) {
      this.phoneUser = true;
      return this.handlePhoneUser(user);
    }
    return this.handleRegularUser(user);
  }

  handlePhoneUser(user) {
    return this.firestoreService.getSelectedUser(user.claims.user_id).pipe(
      switchMap((claims) => {
        if (!claims.h_alle_15) {
          return of(false);
        }
        return this.firestoreService.getGuardian(claims.h_nettitunnus).pipe(
          map((guardian) => {
            return guardian;
          })
        );
      })
    );
  }

  handleRegularUser(user) {
    if (!user.claims.h_alle_15) {
      return of(false);
    }
    return this.firestoreService.getGuardian(user.claims.h_nettitunnus).pipe(
      map((guardian) => {
        return guardian;
      })
    );
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }
}
