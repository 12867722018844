import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Credentials } from '@hlt-app/auth/models';

@Component({
  selector: 'hlt-claims-form',
  templateUrl: './claims-form.component.html',
  styleUrls: ['./claims-form.component.scss']
})
export class ClaimsFormComponent implements OnInit {
  @Output() submitted = new EventEmitter<Credentials>();

  form: FormGroup = new FormGroup({
    token: new FormControl(null, [Validators.required])
  });

  constructor() {}

  ngOnInit(): void {}

  submit() {
    if (this.form.valid) {
      this.submitted.emit(this.form.value);
    }
  }
}
