import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { createEffect } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, mergeMap, tap } from 'rxjs/operators';

@Injectable()
export class RouterEffects {
  private previousClass: string;

  updateTitle$ = createEffect(
    () =>
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap((route) => route.data),
        map((data) => {
          if (this.previousClass) {
            document.body.classList.remove(this.previousClass);
            this.previousClass = undefined;
          }

          if (!!data.class) {
            document.body.classList.add(data.class);
            this.previousClass = data.class;
          }

          return data;
        }),
        map((data) =>
          !!data.title
            ? `${this.translateService.instant(data.title)} | HLT`
            : 'HLT'
        ),
        tap((title) => this.titleService.setTitle(title))
      ),
    {
      dispatch: false
    }
  );

  constructor(
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {}
}
