<div class="container" [formGroup]="form">
  <label class="label" [for]="idFor">{{ label }}</label>

  <div>
    <input
      [id]="idFor"
      [formControl]="control"
      [placeholder]="placeholder"
      [type]="type"
      list="datalist"
    />
    <datalist id="datalist">
      <ng-container *ngFor="let kunta of datalistKunta">
        <option>{{ kunta }}</option>
      </ng-container>
    </datalist>
  </div>
  <hlt-form-errors [control]="control"></hlt-form-errors>
  <hlt-info-textbox
    *ngIf="infoText$ | async"
    [alert]="false"
    [text]="'KUNTA.INFOTEXT' | translate"
  ></hlt-info-textbox>
</div>
