import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'hlt-form-radio',
  templateUrl: './form-radio.component.html',
  styleUrls: ['./form-radio.component.scss']
})
export class FormRadioComponent implements OnInit {
  @Input() public control: FormControl;
  @Input() public group: FormGroup;
  @Input() public label: string;
  @Input() public name: string;
  @Input() public value: any;

  @Input()
  set inactive(value) {
    this.disabled = value;
  }

  public disabled = null;

  constructor() {}

  ngOnInit() {}
}
