<div class="select" [formGroup]="form">
  <label>{{ label }}</label>
  <select [formControl]="control">
    <option value="null" disabled>{{ 'DEFAULTS.SELECT' | translate }}</option>
    <ng-container *ngIf="!groups">
      <option *ngFor="let option of options" [value]="option.value">
        {{ option.getLabel() | translate }}
      </option>
    </ng-container>
    <ng-container *ngIf="groups">
      <ng-container *ngFor="let group of groups">
        <optgroup [label]="group.getTitle() | translate">
          <option *ngFor="let option of group.options" [value]="option.value">
            {{ option.getLabel() | translate }}
          </option>
        </optgroup>
      </ng-container>
    </ng-container>
  </select>
  <hlt-form-errors [control]="control"></hlt-form-errors>
</div>
