import { cloneDeep, isEmpty } from 'lodash';
import { IArrayQuestion } from '../interfaces/interface.arrayQuestion';
import { IOption } from '../interfaces/interface.option';
import { IOptionGroupQuestion } from '../interfaces/interface.optionGroupQuestion';
import { IOptionQuestion } from '../interfaces/interface.optionQuestion';
import { Answers, TypeAnswer } from '../interfaces/type.answer';
import { TypeQuestion } from '../interfaces/type.question';
import { TypeQuestionClasses } from '../interfaces/type.questionClasses';
import { ArrayQuestion } from './ArrayQuestion';
import { OptionGroup } from './OptionGroup';
import { OptionGroupQuestion } from './OptionGroupQuestion';
import { OptionQuestion } from './OptionQuestion';
import { OptionValue } from './OptionValue';
import { Question } from './Question';
import { QuestionsController } from './Questions.controller';

// @todo: WIP
export class QuestionsFactory {
  private _originalQuestions: TypeQuestion[];
  private _questions: TypeQuestion[];
  private _sampleVariables: Answers;
  private _answers: Answers;
  private guardian = false;

  constructor(
    questions: TypeQuestion[],
    sampleVariables: Answers,
    answers?: Answers,
    guardian = false
  ) {
    this._originalQuestions = cloneDeep(questions);
    this._questions = questions;
    this._sampleVariables = sampleVariables || null;
    this._answers = answers || null;
    this.guardian = guardian;
    return this;
  }

  get questions(): TypeQuestion[] {
    return this._questions;
  }

  set questions(value: TypeQuestion[]) {
    this._questions = value;
  }

  reset(): void {
    this._questions = cloneDeep(this._originalQuestions);
  }

  make(): QuestionsController {
    if (isEmpty(this.questions)) {
      throw new Error('trying to make questions with empty object');
    }
    const qs = cloneDeep(this.questions);

    const questionsClasses: TypeQuestionClasses[] = qs.map((question) =>
      this.initQuestion(question, question.value)
    );

    return new QuestionsController(
      questionsClasses,
      this._sampleVariables,
      this._answers
    );
  }

  static isOptionQuestion(question: TypeQuestion): question is IOptionQuestion {
    return (question as IOptionQuestion).options !== undefined;
  }

  static isOptionGroupQuestion(
    question: TypeQuestion
  ): question is IOptionGroupQuestion {
    return (question as IOptionGroupQuestion).groups !== undefined;
  }

  static isArrayQuestion(question: TypeQuestion): question is IArrayQuestion {
    return (question as IArrayQuestion).fields !== undefined;
  }

  getAnswer(key: string): TypeAnswer {
    return this._answers && this._answers.hasOwnProperty(key)
      ? this._answers[key]
      : null;
  }

  initOptionValue(option: IOption, answer: TypeAnswer): OptionValue {
    if (isEmpty(option)) {
      throw new Error('Trying to init option value with empty object');
    }
    let question: TypeQuestionClasses | null = null;
    if (!!option.other) {
      question = this.initQuestion(option.other, option.other.value);
    }
    return new OptionValue(option, question, this.guardian);
  }

  initOptionQuestion(
    question: IOptionQuestion,
    answer: TypeAnswer
  ): OptionQuestion {
    if (isEmpty(question.options)) {
      throw new Error('Trying to init option question without options');
    }
    const options: OptionValue[] = question.options.map((option) =>
      this.initOptionValue(option, answer)
    );
    const q = new OptionQuestion(question, options, this.guardian);
    q.answer = answer;
    return q;
  }
  initOptionGroupQuestion(
    question: IOptionGroupQuestion,
    answer: TypeAnswer
  ): OptionQuestion {
    if (isEmpty(question.groups)) {
      throw new Error('Trying to init option group question without groups');
    }

    const groups: OptionGroup[] = question.groups.map((group) => {
      const options: OptionValue[] = group.options.map((option) =>
        this.initOptionValue(option, answer)
      );
      return new OptionGroup(
        {
          key: group.key,
          title: group.title,
          options: options
        },
        this.guardian
      );
    });

    const q = new OptionGroupQuestion(question, groups, this.guardian);
    q.answer = answer;
    return q;
  }

  initArrayQuestion(
    question: IArrayQuestion,
    answer: TypeAnswer
  ): ArrayQuestion {
    if (isEmpty(question.fields)) {
      throw new Error('Trying to init array question without fields');
    }

    const fields: TypeQuestionClasses[] = question.fields.map((field) => {
      return this.initQuestion(field, field.value);
    });

    const q = new ArrayQuestion(question, fields, this.guardian);
    q.sampleVariables = this._sampleVariables;
    q.answer = answer;
    return q;
  }

  initQuestion(
    question: TypeQuestion,
    answer: TypeAnswer
  ): TypeQuestionClasses {
    if (QuestionsFactory.isOptionGroupQuestion(question)) {
      return this.initOptionGroupQuestion(question, answer);
    } else if (QuestionsFactory.isOptionQuestion(question)) {
      return this.initOptionQuestion(question, answer);
    } else if (QuestionsFactory.isArrayQuestion(question)) {
      return this.initArrayQuestion(question, answer);
    } else {
      const q = new Question(question, this.guardian);
      q.answer = question.value;
      return q;
    }
  }
}
