/* istanbul ignore file */

import { isEmpty } from 'lodash';

export const isTypeKeyValuePair = (
  answer: TypeAnswer
): answer is TypeKeyValuePair => {
  return (
    !!answer &&
    !Array.isArray(answer) &&
    typeof answer !== 'string' &&
    Object.keys(answer).length > 0
  );
};

export const isTypeKeyBooleanPair = (
  answer: TypeAnswer
): answer is TypeKeyBooleanPair => {
  return (
    isTypeKeyValuePair(answer) &&
    Object.keys(answer).every((k) => typeof answer[k] === 'boolean')
  );
};

export const isTypeKeyValuePairArray = (
  answer: TypeAnswer
): answer is TypeKeyValuePair[] => {
  return (
    !!answer &&
    Array.isArray(answer) &&
    !isEmpty(answer) &&
    Object.keys(answer[0]).length > 0
  );
};

export type TypeKeyBooleanPair = {
  [key: string]: boolean;
};

export type TypeKeyValuePair = {
  [key: string]:
    | string
    | number
    | boolean
    | null
    | TypeKeyValuePair[]
    | TypeKeyBooleanPair;
};

export type TypeAnswer =
  | TypeKeyValuePair
  | TypeKeyValuePair[]
  | string
  | number
  | boolean
  | null;

export type Answers = {
  [key: string]: TypeAnswer; // (A)
} | null;
