import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OptionQuestion } from '@hlt-shared/Questions/classes/OptionQuestion';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { wspKunta } from './kuntListJson';

let inputNextUniqueId = 0;

@Component({
  selector: 'hlt-text-input-datalist',
  templateUrl: './text-input-datalist.component.html',
  styleUrls: ['./text-input-datalist.component.scss']
})
export class TextInputDatalistComponent implements OnInit {
  @Input() type = 'search';
  @Input() placeholder = '';
  @Input() control: FormControl;
  @Input() form: FormGroup;
  @Input() label = '';
  @Input() autocomplete?;
  @Input() autocompleteConnectedTo?;
  @Input() question?: OptionQuestion;

  // tslint:disable-next-line
  protected _uid = `text-input-datalist-${inputNextUniqueId++}`;
  // tslint:disable-next-line
  protected _id = this._uid;

  @Input()
  get idFor(): string {
    return this._id;
  }
  set idFor(value: string) {
    this._id = value || this._uid;
  }

  public datalistKunta: Array<string>;
  public controlName;
  public infoText$: Observable<boolean>;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.controlName = this.question.key;

    if (this.controlName === 'M_LKUNTA') {
      this.infoText$ = this.showKuntaValidationWarning('M_LMAA');
    } else if (this.controlName === 'M_MKUNTA') {
      this.infoText$ = this.showKuntaValidationWarning('M_MMAA');
    } else if (this.controlName === 'T_KOTIKUNTA') {
      this.infoText$ = this.kuntaValidationInBackgroundQuestion();
    }

    const lang = this.translateService.currentLang;
    this.datalistKunta = wspKunta.map((k) => k[lang] || k['fi']);
  }

  private showKuntaValidationWarning(maaControlName: string) {
    const maa$ = this.form.get(maaControlName).valueChanges.pipe(startWith(''));
    const kunta$ = this.mainControl.valueChanges;
    return combineLatest([maa$, kunta$]).pipe(
      map(([maa, kunta]) => {
        if (!kunta || !isFinland(maa)) {
          return false;
        }
        return wspKunta.every((el) => el.fi !== kunta && el.sv !== kunta);
      })
    );
  }

  private kuntaValidationInBackgroundQuestion() {
    return this.mainControl.valueChanges.pipe(
      map((kunta) => {
        if (!kunta) {
          return false;
        }
        return wspKunta.every((el) => el.fi !== kunta && el.sv !== kunta);
      })
    );
  }

  get mainControl() {
    return this.form.get(this.controlName);
  }
}

function isFinland(maa: string) {
  if (!maa) {
    return true;
  }
  const lcMaa = maa.toLowerCase();
  return lcMaa === 'finland' || lcMaa === 'suomi';
}
