import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OptionGroup } from '@hlt-shared/Questions/classes/OptionGroup';
import { OptionQuestion } from '@hlt-shared/Questions/classes/OptionQuestion';
import { OptionValue } from '@hlt-shared/Questions/classes/OptionValue';

@Component({
  selector: 'hlt-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss']
})
export class SelectInputComponent implements OnInit {
  @Input() control: FormControl;
  @Input() form: FormGroup;
  @Input() label: string;
  @Input() labelPosition = 'after';
  @Input() options: OptionValue[];
  @Input() groups: OptionGroup[] | null = null;
  @Input() question?: OptionQuestion;

  constructor() {}

  ngOnInit(): void {}
}
