import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hlt-info-textbox',
  templateUrl: './info-textbox.component.html',
  styleUrls: ['./info-textbox.component.scss']
})
export class InfoTextboxComponent implements OnInit {
  @Input() alert = false;
  @Input() text: string;

  constructor() {}

  ngOnInit(): void {}
}
