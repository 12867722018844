<ng-container [formGroup]="form">
  <ng-container *ngFor="let option of question.options">
    <hlt-form-radio
      [control]="radio"
      [name]="question.key"
      [group]="group"
      [label]="option.getLabel() | translate"
      [value]="option.value"
    ></hlt-form-radio>
  </ng-container>
  <hlt-form-errors [control]="radio"></hlt-form-errors>
  <hlt-question [form]="group" [question]="otherField"></hlt-question>
</ng-container>
