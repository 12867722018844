import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AuthApiActions, RegisterPageActions } from '@hlt-app/auth/actions';
import { AuthService } from '@hlt-app/auth/services';
import { SpinnerService } from '@hlt-app/auth/services/spinner.service';
import { FirestoreService } from '@hlt-app/study/services/firestore.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthActions, User } from '@xtream/firebase-ngrx-user-management';
import { auth } from 'firebase';
import { from, of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  switchMap,
  take,
  tap
} from 'rxjs/operators';

@Injectable()
export class RegisterEffects {
  register$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RegisterPageActions.register),
      map((credentials) => {
        this.spinnerService.show();
        return credentials;
      }),
      exhaustMap((credentials) => {
        return from(
          this.authService.doSignUpWithCredentials({
            email: credentials.email,
            password: credentials.password
          })
        ).pipe(
          concatMap((credential) => [
            new AuthActions.RegistrationSuccess({
              user: new User(
                credential.user.uid,
                credential.user.displayName,
                credential.user.email,
                credential.user.phoneNumber,
                credential.user.photoURL,
                credential.user.emailVerified
              )
            }),
            AuthApiActions.registerClaims({ token: credentials.token })
          ]),
          catchError((error) => of(AuthApiActions.registerFailure({ error })))
        );
      })
    );
  });

  registerFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthApiActions.registerFailure),
        tap(() => this.spinnerService.hide())
      );
    },
    { dispatch: false }
  );

  registerClaims$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthApiActions.registerClaims),
      exhaustMap((token) =>
        this.authService.registerClaims(token).pipe(
          map(() => AuthApiActions.registerSuccess()),
          catchError((error) => {
            return of(AuthApiActions.registerClaimsFailure({ error }));
          })
        )
      )
    );
  });

  registerClaimsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthApiActions.registerClaimsFailure),
      map((error) => AuthApiActions.claimsFailure(error)),
      tap(() => {
        this.spinnerService.hide();
        this.router.navigate([this.localize.translateRoute('/claims')]);
      })
    );
  });

  registerSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthApiActions.registerSuccess),
      switchMap(() => this.authService.getUser().pipe(take(1))),
      filter((userObj) => !!userObj),
      switchMap((userObj) =>
        from(userObj.getIdTokenResult(true)).pipe(
          filter((user) => !!user),
          filter((user) => !!user.claims.role),
          switchMap((user: auth.IdTokenResult) => {
            return this.firestoreService
              .setLogin({
                login: {
                  time: new Date(user.authTime).getTime(),
                  browser: window.navigator.userAgent
                },
                h_nettitunnus: user.claims.h_nettitunnus
              })
              .pipe(map(() => user));
          }),
          map((user) => {
            this.spinnerService.hide();
            this.router.navigate([this.localize.translateRoute('/study')]);
            return AuthApiActions.loginSuccess({ user });
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private localize: LocalizeRouterService,
    private spinnerService: SpinnerService,
    private firestoreService: FirestoreService
  ) {}
}
