import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { BackgroundReducers } from '@hlt-app/study/reducers';
import { FormController } from '@hlt-app/study/services/form-controller';
import { backgroundQuestions } from '@hlt-shared/Questions/data/backgroundQuestions';
import { select, Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class BackgroundQuestionsService extends FormController {
  protected questions = backgroundQuestions;
  protected Answers$ = this.store.pipe(
    select(BackgroundReducers.selectStudyBackgroundAnswers)
  );
  protected type = 'background';
  protected completedRoute = '/study/background-completed';

  constructor(
    protected store: Store,
    protected router: Router,
    protected localize: LocalizeRouterService
  ) {
    super(store, router, localize);
  }
}
