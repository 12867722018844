<div class="container--small">
  <h1>
    {{
      !guardian
        ? ('DRAW.TITLE' | translate)
        : ('DRAW.TITLEGUARDIAN' | translate)
    }}
  </h1>
  <ng-container *ngIf="testiryhma === 3; else oldGiftText">
    <p class="description">
      {{
        !guardian
          ? ('DRAW.DESCRIPTION_NEWGIFT' | translate)
          : ('DRAW.DESCRIPTIONGUARDIAN_NEWGIFT' | translate)
      }}
    </p>
  </ng-container>
  <ng-template #oldGiftText>
    <p class="description">
      {{
        !guardian
          ? ('DRAW.DESCRIPTION' | translate)
          : ('DRAW.DESCRIPTIONGUARDIAN' | translate)
      }}
    </p>
  </ng-template>

  <form (ngSubmit)="submit()" [formGroup]="form">
    <hlt-text-input
      [form]="form"
      [control]="name"
      [placeholder]="'PLACEHOLDER.FIRSTANDSUR' | translate"
      [label]="('LABEL.FIRSTANDSUR' | translate) + ' *'"
      type="text"
    ></hlt-text-input>
    <hlt-text-input
      [form]="form"
      [control]="email"
      [placeholder]="'PLACEHOLDER.EMAIL' | translate"
      [label]="'LABEL.EMAIL' | translate"
      type="email"
    ></hlt-text-input>

    <ng-container
      *ngIf="phone.touched && email.touched && form.errors?.atLeastOne"
    >
      <span class="material-icons error" role="alert"
        >error
        <p class="error__text error__text--yellow">
          {{ 'ERRORS.EITHERPHONEOREMAIL' | translate }}
        </p>
      </span>
    </ng-container>

    <hlt-text-input
      [form]="form"
      [control]="phone"
      [placeholder]="'PLACEHOLDER.PHONENUMBER' | translate"
      [label]="'LABEL.PHONENUMBER' | translate"
      type="text"
    ></hlt-text-input>

    <ng-container
      *ngIf="phone.touched && email.touched && form.errors?.atLeastOne"
    >
      <span class="material-icons error" role="alert"
        >error
        <p class="error__text error__text--yellow">
          {{ 'ERRORS.EITHERPHONEOREMAIL' | translate }}
        </p>
      </span>
    </ng-container>

    <hlt-button
      [text]="
        !guardian
          ? ('BUTTONS.SENDANDEND' | translate)
          : ('BUTTONS.SENDANDENDGUARDIAN' | translate)
      "
      type="submit"
    ></hlt-button>
  </form>
  <div class="button">
    <hlt-button
      [underline]="true"
      [text]="
        !guardian
          ? ('BUTTONS.NOTPARTICIPATE' | translate)
          : ('BUTTONS.NOTPARTICIPATEGUARDIAN' | translate)
      "
      (clicked)="skip()"
    ></hlt-button>
  </div>
</div>
