<div class="container--small" role="main">
  <div class="header">
    <h1 class="header__title">{{ 'REGISTER.TITLE' | translate }}</h1>
  </div>
  <form (ngSubmit)="submit()" [formGroup]="form">
    <div class="content">
      <hlt-text-input
        [form]="form"
        [control]="form.get('token')"
        [placeholder]="'REGISTER.USERNAMEPLACEHOLDER' | translate"
        [label]="'REGISTER.HEADER' | translate"
      ></hlt-text-input>

      <hlt-button
        [text]="'REGISTER.CONFIRM' | translate"
        type="submit"
      ></hlt-button>
    </div>
  </form>
  <br /><br />
  <hlt-route-button
    [text]="'BUTTONS.SIGNOUT' | translate"
    route="/logout"
  ></hlt-route-button>
</div>
