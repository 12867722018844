import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { MatSpinner } from '@angular/material/progress-spinner';
import { AuthState } from '@hlt-app/auth/reducers';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthActions } from '@xtream/firebase-ngrx-user-management';
import { Subscription } from 'rxjs';

@Component({
  selector: 'hlt-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  onLangChange: Subscription = undefined;
  public matSpinner = MatSpinner;

  constructor(
    private store: Store<AuthState>,
    public el: ElementRef,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.updateLanguage();
    this.onLangChange = this.translate.onLangChange.subscribe(() => {
      this.updateLanguage();
    });

    this.store.dispatch(new AuthActions.GetUser());
  }

  updateLanguage(): void {
    const lang = document.createAttribute('lang');
    lang.value = this.translate.currentLang;
    this.el.nativeElement.parentElement.parentElement.attributes.setNamedItem(
      lang
    );
  }

  ngOnDestroy() {
    if (this.onLangChange !== undefined) {
      this.onLangChange.unsubscribe();
    }
  }
}
