import { Injectable } from '@angular/core';
import { SpinnerVisibilityService } from 'ng-http-loader';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  defaultBlockState = {
    startTimeouts: [],
    stopTimeouts: [],
    startCallCount: 0,
    stopCallCount: 0
  };
  state = { ...this.defaultBlockState };

  private delayStart = 220;
  private delayStop = 400;
  private timerStart = 0;

  constructor(private spinner: SpinnerVisibilityService) {}

  show() {
    const delay = this.delayStart ?? 0;
    this.state.startCallCount += 1;

    if (this.state.startCallCount === 1) {
      this.timerStart = new Date().getTime() + delay;
    }

    const startTimeout = setTimeout(() => {
      this.spinner.show();
    }, delay);

    this.state.startTimeouts.push(startTimeout);
  }

  hide() {
    this.state.stopCallCount += 1;
    const timerVisibleTime = new Date().getTime() - this.timerStart;

    if (
      timerVisibleTime < 0 ||
      this.state.startCallCount < this.state.stopCallCount
    ) {
      this.clear();
    } else if (this.state.startCallCount === this.state.stopCallCount) {
      // remove all timeouts
      [...this.state.startTimeouts, ...this.state.stopTimeouts].forEach(
        clearTimeout
      );
      const delay = this.delayStop || 0;
      let timeout = timerVisibleTime - delay;

      if (timeout > 0) {
        timeout = 0;
      } else if (timeout < 0) {
        timeout = timeout * -1;
      }

      const stopTimeout = setTimeout(() => {
        this.spinner.hide();
        window.scroll({
          top: 0,
          left: 0
        });
      }, timeout);

      this.state.stopTimeouts.push(stopTimeout);

      // reset everything else
      this.state.startCallCount = 0;
      this.state.stopCallCount = 0;
    }
  }

  clear() {
    window.scroll({
      top: 0,
      left: 0
    });
    [...this.state.startTimeouts, ...this.state.stopTimeouts].forEach(
      clearTimeout
    );
    this.state.startCallCount = 0;
    this.state.stopCallCount = 0;
    this.spinner.hide();
  }
}
