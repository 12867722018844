import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'hlt-box-button',
  templateUrl: './box-button.component.html',
  styleUrls: ['./box-button.component.scss']
})
export class BoxButtonComponent implements OnInit {
  @Input() pointer: number;
  @Input() mainText: string;
  @Input() subDescription: string;
  @Input() showArrow = true;
  @Input() remaining: number;
  @Input() completed = false;
  @Input() route: string;
  @Input() disabled = true;

  constructor(
    private router: Router,
    private localize: LocalizeRouterService
  ) {}

  ngOnInit(): void {}

  navigate() {
    if (!this.disabled && this.route) {
      this.router.navigate([this.localize.translateRoute(this.route)]);
    }
  }
}
