import { createAction, props } from '@ngrx/store';
import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;

const loadUserData = createAction(
  '[User Data] Load Data',
  props<{ h_nettitunnus: string }>()
);

const setClaims = createAction(
  '[User Data] Set Claims',
  props<{
    claims: {
      completed: boolean;
      h_otosalue_id: number;
      h_alle_15: boolean;
      h_tutkimuspvm: Timestamp;
      h_verkkoalku: Timestamp;
      h_verkkoloppu: Timestamp;
      h_nettitunnus: string;
    } | null;
  }>()
);

const setGuardian = createAction(
  '[User Data] Set Guardian',
  props<{ guardian: boolean }>()
);

export const UserActions = {
  loadUserData,
  setClaims,
  setGuardian
};
