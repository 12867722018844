import { isEqual, uniqWith } from 'lodash';
import { hasAnswerValue, requireAnswerValue } from '../helper/require-answer';
import { Answers, isTypeKeyValuePairArray } from '../interfaces/type.answer';
import { TypeQuestion } from '../interfaces/type.question';
export const preDiaryQuestions: TypeQuestion[] = [
  {
    key: 'T_KULJETUSTYO',
    main: true,
    title:
      'Onko työsi päätehtävä ihmisten tai tavaroiden kuljettaminen tai liikennevälineessä työskentely?',
    content:
      'Esimerkiksi bussinkuljettaja, konduktööri, taksinkuljettaja, kuorma-autonkuljettaja, postinkantaja, kukkalähetti, pizzalähetti jne. liikkuvat ammattimaisesti, koska tavaroiden tai ihmisten kuljetus on työn päätehtävä. ' +
      '\n\n' +
      'Sen sijaan esimerkiksi myyntiedustaja, putkimies, rakennusmies ja kotisairaanhoitaja eivät liiku ammattimaisesti, koska työn päätehtävä ei ole ihmisten tai tavaroiden kuljetus, vaan liikkuminen on edellytys varsinaisen työn suorittamiselle.',
    label:
      'Onko työsi päätehtävä ihmisten tai tavaroiden kuljettaminen tai liikennevälineessä työskentely?',
    controlType: 'radioControl',
    type: 'radio',
    required: false,
    value: null,
    validation: {},
    skip: (answers: Answers) => {
      if (!hasAnswerValue('T_IKA', answers)) {
        return true;
      }

      const ageAnswerValue = requireAnswerValue(
        'T_IKA',
        answers,
        'T_KULJETUSTYO'
      );

      return !!(ageAnswerValue && ageAnswerValue < 15);
    },
    next: 'T_TYOTUTKIMUSPV',
    options: [
      {
        key: 'T_KULJETUSTYO_1',
        label: 'Kyllä',
        value: 1
      },
      {
        key: 'T_KULJETUSTYO_2',
        label: 'Ei',
        value: 2
      }
    ]
  },
  {
    key: 'T_TYOTUTKIMUSPV',
    main: true,
    title: 'Teitkö ansiotyötä tutkimuspäivänä?',
    label: 'Teitkö ansiotyötä tutkimuspäivänä?',
    controlType: 'radioControl',
    type: 'radio',
    required: true,
    value: null,
    validation: {},
    next: null,
    skip: (answers: Answers) => {
      if (!hasAnswerValue('T_KODINULKOP', answers)) {
        return true;
      }
      const workingAnswerValue = requireAnswerValue(
        'T_KODINULKOP',
        answers,
        'T_TYOTUTKIMUSPV'
      ) as number;
      return [1].indexOf(workingAnswerValue) === -1;
    },
    options: [
      {
        key: 'T_TYOTUTKIMUSPV_1',
        label: 'En tehnyt töitä tutkimuspäivänä',
        value: 1
      },
      {
        key: 'T_TYOTUTKIMUSPV_2',
        label: 'Tein töitä pääosan työpäivästä työantajan toimipisteessä',
        value: 2
      },
      {
        key: 'T_TYOTUTKIMUSPV_3',
        label: 'Tein töitä työantajan toimipisteessäni ja etänä',
        value: 3
      },
      {
        key: 'T_TYOTUTKIMUSPV_4',
        label: 'Tein töitä vain etänä',
        value: 4
      },
      {
        key: 'T_TYOTUTKIMUSPV_5',
        label: 'Olin työhön liittyvällä matkalla',
        value: 5
      }
    ]
  }
];

export const diaryQuestions: TypeQuestion[] = [
  // Mistä ja mihin aikaan lähdit liikkeelle?
  // Mistä ja mihin aikaan hän lähti liikkeelle?
  // https://projects.invisionapp.com/d/main#/console/19519946/414930925/preview
  // Sn: 3
  {
    key: 'M_LTK',
    main: true,
    title: 'Mistä ja mihin aikaan lähdit liikkeelle?',
    guardianTitle: 'Mistä ja mihin aikaan hän lähti liikkeelle?',
    label: 'Lähtöpaikan tyyppi',
    placeholder: 'Valitse',
    controlType: 'selectPrefillControl',
    type: 'select',
    required: true,
    value: null,
    validation: {},
    preFillFields: {
      street: 'M_LOSOITE',
      lat: 'M_LOSOITE_LAT',
      lon: 'M_LOSOITE_LON',
      localAdmin: 'M_LKUNTA',
      country: 'M_LMAA'
    },
    siblings: [
      'M_LMAA',
      'M_LKUNTA',
      'M_LOSOITE',
      'M_LOSOITE_LAT',
      'M_LOSOITE_LON',
      'M_LAHTOAIKA'
    ],
    next: 'M_MTK',

    groups: [
      {
        key: 'M_LTK_GROUP_1',
        title: 'Koti',
        options: [
          {
            key: 'M_LTK_1',
            label: 'Oma koti',
            value: 1
          },
          {
            key: 'M_LTK_2',
            label: 'Muu vakituinen asunto (esim. kakkosasunto)',
            value: 2
          }
        ]
      },
      {
        key: 'M_LTK_GROUP_2',
        title: 'Työ, opiskelu, koulu ja  hoitopaikka',
        options: [
          {
            key: 'M_LTK_3',
            label: 'Oma ensisijainen työpaikka',
            value: 3
          },
          {
            key: 'M_LTK_4',
            label: 'Oma muu työpaikka',
            value: 4
          },
          {
            key: 'M_LTK_5',
            label: 'Työhön liittyvä kohde tai työasiointipaikka',
            value: 5
          },
          {
            key: 'M_LTK_6',
            label: 'Oma koulu tai opiskelupaikka',
            value: 6
          },
          {
            key: 'M_LTK_7',
            label: 'Oma hoitopaikka tai esikoulu',
            value: 7
          }
        ]
      },
      {
        key: 'M_LTK_GROUP_3',
        title: 'Kyyditys, saattaminen',
        options: [
          {
            key: 'M_LTK_8',
            label:
              'Pysähdyspaikka henkilön noutamista/jättämistä varten (esim. asema tai perheenjäsenen työpaikka, koulu, hoitopaikka)',
            value: 8
          }
        ]
      },
      {
        key: 'M_LTK_GROUP_4',
        title: 'Ostos- tai asiointipaikka',
        options: [
          {
            key: 'M_LTK_9',
            label: 'Päivittäistavaroiden ostopaikka',
            value: 9
          },
          {
            key: 'M_LTK_10',
            label:
              'Asiointipaikka (pankki, toimisto, lääkäri, posti, kampaamo jne.)',
            value: 10
          },
          {
            key: 'M_LTK_11',
            label: 'Kauppakeskus',
            value: 11
          },
          {
            key: 'M_LTK_12',
            label: 'Muu ostospaikka',
            value: 12
          },
          {
            key: 'M_LTK_13',
            label: 'Ravintola, kahvila, lounaspaikka tai vastaava',
            value: 13
          },
          {
            key: 'M_LTK_14',
            label: 'Pubi, baari, yökerho',
            value: 14
          }
        ]
      },
      {
        key: 'M_LTK_GROUP_5',
        title: 'Liikunta, ulkoilu ja urheilu',
        options: [
          {
            key: 'M_LTK_15',
            label: 'Liikunta- tai ulkoilupaikka',
            value: 15
          },
          {
            key: 'M_LTK_16',
            label: 'Luontoalue',
            value: 16
          }
        ]
      },
      {
        key: 'M_LTK_GROUP_6',
        title: 'Vapaa-aika',
        options: [
          {
            key: 'M_LTK_17',
            label: 'Vierailupaikka (sukulaisten, ystävien, tuttavien luona)',
            value: 17
          },
          {
            key: 'M_LTK_18',
            label: 'Kulttuurikohde tai muu viihde- tai juhlatilaisuus',
            value: 18
          },
          {
            key: 'M_LTK_19',
            label: 'Kesämökki tai vapaa-ajan asunto',
            value: 19
          },
          {
            key: 'M_LTK_20',
            label:
              'Muu vapaa-ajan kohde (esim. harrastuspaikka tai matkailukohde)',
            value: 20
          }
        ]
      },
      {
        key: 'M_LTK_GROUP_7',
        title: 'Yöpyminen',
        options: [
          {
            key: 'M_LTK_21',
            label: 'Hotelli tai muu tilapäinen yöpymispaikka',
            value: 21
          }
        ]
      },
      {
        key: 'M_LTK_GROUP_8',
        title: 'Muu',
        options: [
          {
            key: 'M_LTK_22',
            label: 'Muu kohde',
            value: 22
          }
        ]
      }
    ]
  },
  {
    key: 'M_LOSOITE',
    main: false,
    label: 'Kadun tai tien nimi ja talon numero',
    placeholder: 'Esim. Aleksanterinkatu 20',
    controlType: 'mapControl',
    autocompleteFields: {
      lat: 'M_LOSOITE_LAT',
      lon: 'M_LOSOITE_LON',
      localAdmin: 'M_LKUNTA',
      country: 'M_LMAA'
    },
    autocompleteLayers: ['address', 'street', 'venue', 'neighbourhood'],
    type: 'text',
    required: true,
    value: null,
    validation: {
      minLength: 1
    }
  },
  {
    key: 'M_LOSOITE_LAT',
    main: false,
    label: '',
    placeholder: '',
    controlType: 'textControl',
    type: 'hidden',
    required: false,
    value: null,
    validation: {}
  },
  {
    key: 'M_LOSOITE_LON',
    main: false,
    label: '',
    placeholder: '',
    controlType: 'textControl',
    type: 'hidden',
    required: false,
    value: null,
    validation: {}
  },
  {
    key: 'M_LKUNTA',
    main: false,
    label: 'Kunta',
    placeholder: 'Esim. Helsinki',
    controlType: 'datalistControl',
    autocompleteLayers: ['localadmin'],
    type: 'text',
    required: true,
    value: null,
    validation: {
      minLength: 1
    }
  },
  {
    key: 'M_LMAA',
    main: false,
    label: 'Lähtöpaikan maa, jos muu kuin Suomi',
    placeholder: 'Esim. Ruotsi',
    controlType: 'textControl',
    type: 'text',
    required: false,
    value: null,
    validation: {}
  },
  {
    key: 'M_LAHTOAIKA',
    main: false,
    label: 'Lähtöaika',
    placeholder: 'Esim. 15.00',
    controlType: 'timeControl',
    type: 'time',
    required: true,
    value: null,
    validation: {
      pattern: /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/,
      startTime: true
    }
  },

  // Lähdit klo M_LAHTOAIKA paikasta M_LTK. Mihin saavuit ja mihin aikaan?
  // Hän lähti klo M_LAHTOAIKA paikasta M_LTK. Mihin hän saapui ja mihin aikaan?
  // https://projects.invisionapp.com/d/main#/console/19519946/414930926/preview#project_console
  // Sn: 4

  {
    key: 'M_MTK',
    main: true,
    title:
      'Lähdit klo {{M_LAHTOAIKA}} paikasta {{M_LTK}}. Mihin saavuit ja mihin aikaan?',
    guardianTitle:
      'Hän lähti klo {{M_LAHTOAIKA}} paikasta {{M_LTK}}. Mihin hän saapui ja mihin aikaan?',
    label: 'Määränpään tyyppi',
    placeholder: 'Valitse',
    controlType: 'selectPrefillControl',
    type: 'select',
    required: true,
    value: null,
    validation: {},
    preFillFields: {
      street: 'M_MOSOITE',
      lat: 'M_MOSOITE_LAT',
      lon: 'M_MOSOITE_LON',
      localAdmin: 'M_MKUNTA',
      country: 'M_MMAA'
    },
    siblings: [
      'M_MMAA',
      'M_MKUNTA',
      'M_MOSOITE',
      'M_MOSOITE_LAT',
      'M_MOSOITE_LON',
      'M_SEURAAVAPAIVA',
      'M_MAARAAIKA'
    ],
    next: 'M_KTAVAT',
    groups: [
      {
        key: 'M_MTK_GROUP_1',
        title: 'Koti',
        options: [
          {
            key: 'M_MTK_1',
            label: 'Oma koti',
            value: 1
          },
          {
            key: 'M_MTK_2',
            label: 'Muu vakituinen asunto (esim. kakkosasunto)',
            value: 2
          }
        ]
      },
      {
        key: 'M_MTK_GROUP_2',
        title: 'Työ, opiskelu, koulu ja hoitopaikka',
        options: [
          {
            key: 'M_MTK_3',
            label: 'Oma ensisijainen työpaikka',
            value: 3
          },
          {
            key: 'M_MTK_4',
            label: 'Oma muu työpaikka',
            value: 4
          },
          {
            key: 'M_MTK_5',
            label: 'Työhön liittyvä kohde tai työasiointipaikka',
            value: 5
          },
          {
            key: 'M_MTK_6',
            label: 'Oma koulu tai opiskelupaikka',
            value: 6
          },
          {
            key: 'M_MTK_7',
            label: 'Oma hoitopaikka tai esikoulu',
            value: 7
          }
        ]
      },
      {
        key: 'M_MTK_GROUP_3',
        title: 'Kyyditys, saattaminen',
        options: [
          {
            key: 'M_MTK_8',
            label:
              'Pysähdyspaikka henkilön noutamista/jättämistä varten (esim. asema tai perheenjäsenen työpaikka, koulu, hoitopaikka)',
            value: 8
          }
        ]
      },
      {
        key: 'M_MTK_GROUP_4',
        title: 'Ostos- tai asiointipaikka',
        options: [
          {
            key: 'M_MTK_9',
            label: 'Päivittäistavaroiden ostopaikka',
            value: 9
          },
          {
            key: 'M_MTK_10',
            label:
              'Asiointipaikka (pankki, toimisto, lääkäri, posti, kampaamo jne.)',
            value: 10
          },
          {
            key: 'M_MTK_11',
            label: 'Kauppakeskus',
            value: 11
          },
          {
            key: 'M_MTK_12',
            label: 'Muu ostospaikka',
            value: 12
          },
          {
            key: 'M_MTK_13',
            label: 'Ravintola, kahvila, lounaspaikka tai vastaava',
            value: 13
          },
          {
            key: 'M_MTK_14',
            label: 'Pubi, baari, yökerho',
            value: 14
          }
        ]
      },
      {
        key: 'M_MTK_GROUP_5',
        title: 'Liikunta, ulkoilu ja urheilu',
        options: [
          {
            key: 'M_MTK_15',
            label: 'Liikunta- tai ulkoilupaikka',
            value: 15
          },
          {
            key: 'M_MTK_16',
            label: 'Luontoalue',
            value: 16
          }
        ]
      },
      {
        key: 'M_MTK_GROUP_6',
        title: 'Vapaa-aika',
        options: [
          {
            key: 'M_MTK_17',
            label: 'Vierailupaikka (sukulaisten, ystävien, tuttavien luona)',
            value: 17
          },
          {
            key: 'M_MTK_18',
            label: 'Kulttuurikohde tai muu viihde- tai juhlatilaisuus',
            value: 18
          },
          {
            key: 'M_MTK_19',
            label: 'Kesämökki tai vapaa-ajan asunto',
            value: 19
          },
          {
            key: 'M_MTK_20',
            label:
              'Muu vapaa-ajan kohde (esim. harrastuspaikka tai matkailukohde)',
            value: 20
          }
        ]
      },
      {
        key: 'M_MTK_GROUP_7',
        title: 'Yöpyminen',
        options: [
          {
            key: 'M_MTK_21',
            label: 'Hotelli tai muu tilapäinen yöpymispaikka',
            value: 21
          }
        ]
      },
      {
        key: 'M_MTK_GROUP_8',
        title: 'Muu',
        options: [
          {
            key: 'M_MTK_22',
            label: 'Muu kohde',
            value: 22
          }
        ]
      }
    ]
  },
  {
    key: 'M_MOSOITE',
    main: false,
    label: 'Kadun tai tien nimi ja talon numero',
    placeholder: 'Esim. Aleksanterinkatu 20',
    controlType: 'mapControl',
    autocompleteFields: {
      lat: 'M_MOSOITE_LAT',
      lon: 'M_MOSOITE_LON',
      localAdmin: 'M_MKUNTA',
      country: 'M_MMAA'
    },
    autocompleteLayers: ['address', 'street', 'venue', 'neighbourhood'],
    type: 'text',
    required: true,
    value: null,
    validation: {
      minLength: 1
    }
  },

  {
    key: 'M_MOSOITE_LAT',
    main: false,
    label: '',
    placeholder: '',
    controlType: 'textControl',
    type: 'hidden',
    required: false,
    value: null,
    validation: {}
  },
  {
    key: 'M_MOSOITE_LON',
    main: false,
    label: '',
    placeholder: '',
    controlType: 'textControl',
    type: 'hidden',
    required: false,
    value: null,
    validation: {}
  },
  {
    key: 'M_MKUNTA',
    main: false,
    label: 'Kunta',
    placeholder: 'Esim. Helsinki',
    controlType: 'datalistControl',
    autocompleteLayers: ['localadmin'],
    type: 'text',
    required: true,
    value: null,
    validation: {
      minLength: 1
    }
  },
  {
    key: 'M_MMAA',
    main: false,
    label: 'Määränpään maa, jos muu kuin Suomi',
    placeholder: 'Esim. Ruotsi',
    controlType: 'textControl',
    type: 'text',
    required: false,
    value: null,
    validation: {}
  },
  {
    key: 'M_SEURAAVAPAIVA',
    main: false,
    label: 'Matka päättyy seuraavan vuorokauden aikana',
    placeholder: '',
    controlType: 'checkboxControl',
    type: 'checkbox',
    required: false,
    value: null,
    validation: {},
    options: [
      {
        key: 'M_SEURAAVAPAIVA_1',
        value: 1,
        label: 'Matka päättyy seuraavan vuorokauden aikana'
      }
    ]
  },
  {
    key: 'M_MAARAAIKA',
    main: false,
    label: 'Saapumisaika',
    placeholder: 'Esim. 15.00',
    controlType: 'timeControl',
    type: 'time',
    required: true,
    value: null,
    validation: {
      pattern: /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/,
      endTime: true
    }
  },

  // Millaisia kulkutapoja käytit matkan aikana? (Käytetyt kulkutavat järjestyksessä aikajärjestyksessä lähtöpaikasta määränpäähän)

  // Lisää kaikki käyttämäsi kulkutavat yksi kerrallaan aikajärjestyksessä. Muista merkitä myös yli 100 metrin kävelyosuudet.
  // Jos vaihdoit kulkutapaa kesken matkan, esim. bussista toiseen, lisää molemmat bussit erikseen.

  // Millaisia kulkutapoja hän käytti matkan aikana?

  // Lisää kaikki hänen käyttämänsä kulkutavat yksi kerrallaan aikajärjestyksessä.Muista merkitä myös yli 100 metrin kävelyosuudet.
  // Jos hän vaihtoi kulkutapaa kesken matkan, esim.bussista toiseen, lisää molemmat bussit erikseen.

  // https://projects.invisionapp.com/d/main#/console/19519946/414930927/preview#project_console
  // sn: 5

  // @todo: checkthis!! this might change to a different input
  {
    key: 'M_KTAVAT',
    main: true,
    title: 'Millaisia kulkutapoja käytit matkan aikana?',
    guardianTitle: 'Millaisia kulkutapoja hän käytti matkan aikana?',
    content:
      'Lisää kaikki käyttämäsi kulkutavat yksi kerrallaan aikajärjestyksessä. Muista merkitä myös yli 100 metrin kävelyosuudet. \n' +
      '\n' +
      'Jos vaihdoit kulkutapaa kesken matkan, esim. bussista toiseen, lisää molemmat bussit erikseen.',
    guardianContent:
      'Lisää kaikki hänen käyttämänsä kulkutavat yksi kerrallaan aikajärjestyksessä. Muista merkitä myös yli 100 metrin kävelyosuudet. \n' +
      ' \n' +
      'Jos hän vaihtoi kulkutapaa kesken matkan, esim. bussista toiseen, lisää molemmat bussit erikseen.',
    label: 'Lisää kulkutapa',
    controlType: 'formArrayControl',
    guideTexts: {
      edit: '= muokkaa kulkutapaa',
      delete: '= poista kulkutapa',
      drag: '= siirrä ylös tai alas raahaamalla'
    },
    type: '',
    required: true,
    value: null,
    validation: {
      // minLength: 1
    },
    next: 'M_PITUUS',
    fields: [
      {
        key: 'M_KTAVAT',
        main: false,
        label: 'Lisää kulkutapa',
        controlType: 'groupedRadioControl',
        type: 'radio',
        required: true,
        validation: {},
        value: null,
        groups: [
          {
            key: 'M_KTAVAT_GROUP_1',
            title: 'Kävely, pyöräily ja ulkoliikunta',
            options: [
              {
                key: 'M_KTAVAT_1',
                label: 'Jalankulku (kävely, juoksu, pyörätuoli, rollaattori)',
                value: 1
              },
              {
                key: 'M_KTAVAT_2',
                label: 'Polkupyörä (myös sähköpyörä)',
                value: 2
              },
              {
                key: 'M_KTAVAT_3',
                label: 'Kaupunkipyörä',
                value: 3
              },
              {
                key: 'M_KTAVAT_4',
                label: 'Potkulauta (myös sähköpotkulauta)',
                value: 4
              },
              {
                key: 'M_KTAVAT_5',
                label: 'Muu liikunnallinen kulkutapa',
                value: 5
              }
            ]
          },
          {
            key: 'M_KTAVAT_GROUP_2',
            title: 'Henkilö- tai pakettiauto',
            options: [
              {
                key: 'M_KTAVAT_6',
                label: 'Henkilöauto (kuljettajana)',
                value: 6
              },
              {
                key: 'M_KTAVAT_7',
                label: 'Henkilöauto (matkustajana)',
                value: 7
              },
              {
                key: 'M_KTAVAT_8',
                label: 'Pakettiauto (kuljettajana)',
                value: 8
              },
              {
                key: 'M_KTAVAT_9',
                label: 'Pakettiauto (matkustajana)',
                value: 9
              }
            ]
          },
          {
            key: 'M_KTAVAT_GROUP_3',
            title: 'Taksi- ja kutsuliikenne',
            options: [
              {
                key: 'M_KTAVAT_10',
                label: 'Taksi',
                value: 10
              },
              {
                key: 'M_KTAVAT_11',
                label: 'Koulutaksi',
                value: 11
              },
              {
                key: 'M_KTAVAT_12',
                label: 'Kutsubussi tai -taksi',
                value: 12
              }
            ]
          },
          {
            key: 'M_KTAVAT_GROUP_4',
            title: 'Bussi',
            options: [
              {
                key: 'M_KTAVAT_13',
                label: 'Lähiliikenteen bussi',
                value: 13
              },
              {
                key: 'M_KTAVAT_14',
                label: 'Kaukoliikenteen linja-auto',
                value: 14
              },
              {
                key: 'M_KTAVAT_15',
                label:
                  'Tilausbussi (esim. turistibussi tai koululaisretkibussi)',
                value: 15
              },
              {
                key: 'M_KTAVAT_16',
                label: 'Muu bussi',
                value: 16
              }
            ]
          },
          {
            key: 'M_KTAVAT_GROUP_5',
            title: 'Raideliikenne',
            options: [
              {
                key: 'M_KTAVAT_17',
                label: 'Lähi- tai taajamajuna',
                value: 17
              },
              {
                key: 'M_KTAVAT_18',
                label: 'Kaukojuna',
                value: 18
              },
              {
                key: 'M_KTAVAT_19',
                label: 'Raitiovaunu',
                value: 19
              },
              {
                key: 'M_KTAVAT_20',
                label: 'Metro',
                value: 20
              },
              {
                key: 'M_KTAVAT_21',
                label: 'Muu raideliikenne',
                value: 21
              }
            ]
          },
          {
            key: 'M_KTAVAT_GROUP_6',
            title: 'Ilmaliikenne',
            options: [
              {
                key: 'M_KTAVAT_22',
                label: 'Reittilento',
                value: 22
              },
              {
                key: 'M_KTAVAT_23',
                label: 'Tilauslento',
                value: 23
              },
              {
                key: 'M_KTAVAT_24',
                label: 'Muu lentokone (pienkone, helikopteri)',
                value: 24
              },
              {
                key: 'M_KTAVAT_25',
                label: 'Muu moottoroimaton ilmaliikenne',
                value: 25
              }
            ]
          },
          {
            key: 'M_KTAVAT_GROUP_7',
            title: 'Vesiliikenne',
            options: [
              {
                key: 'M_KTAVAT_26',
                label: 'Soutuvene',
                value: 26
              },
              {
                key: 'M_KTAVAT_27',
                label: 'Purjevene',
                value: 27
              },
              {
                key: 'M_KTAVAT_28',
                label: 'Moottorivene',
                value: 28
              },
              {
                key: 'M_KTAVAT_29',
                label: 'Laiva/autolautta/pika-alus',
                value: 29
              },
              {
                key: 'M_KTAVAT_30',
                label: 'Lossi/yhteysalus/lautta',
                value: 30
              },
              {
                key: 'M_KTAVAT_31',
                label: 'Muu vesiliikenne',
                value: 31
              }
            ]
          },
          {
            key: 'M_KTAVAT_GROUP_8',
            title: 'Muu ajoneuvo',
            options: [
              {
                key: 'M_KTAVAT_32',
                label: 'Moottoripyörä',
                value: 32
              },
              {
                key: 'M_KTAVAT_33',
                label: 'Mopo',
                value: 33
              },
              {
                key: 'M_KTAVAT_34',
                label: 'Mopoauto',
                value: 34
              },
              {
                key: 'M_KTAVAT_35',
                label: 'Kuorma-auto',
                value: 35
              },
              {
                key: 'M_KTAVAT_36',
                label: 'Traktori, työkone',
                value: 36
              },
              {
                key: 'M_KTAVAT_37',
                label: 'Muu',
                value: 37
              }
            ]
          }
        ]
      }
    ]
  },

  // Kuinka pitkä matka oli? Täydennä arviomme.

  // Kuinka pitkä matka oli? Täydentäkää arviomme.

  // https://projects.invisionapp.com/d/main#/console/19519946/415099485/preview#project_console
  // sn: 6

  {
    key: 'M_PITUUS',
    main: true,
    title: 'Kuinka pitkä matka oli?',
    guardianTitle: 'Kuinka pitkä matka oli?',
    label: 'Arvio koko matkan pituudesta',
    placeholder: '',
    suffix: 'm',
    controlType: 'distanceControl',
    type: 'text',
    required: true,
    value: null,
    validation: {
      min: 1,
      max: 100000000,
      pattern: /^\d+$/
    },
    siblings: ['M_PITUUS_ARVIO', 'M_PITUUS_KAVELY', 'M_PITUUS_PYORAILY'],
    next: 'M_PAAKULKUTAPA'
  },
  {
    key: 'M_PITUUS_ARVIO',
    main: false,
    label: '',
    placeholder: '',
    controlType: 'distanceControl',
    type: 'hidden',
    required: true,
    value: null,
    validation: {}
  },
  {
    key: 'M_PITUUS_KAVELY',
    main: false,
    label: 'Kuinka suuren osan matkasta kuljit jalan?',
    guardianLabel: 'Kuinka suuren osan matkasta hän kulki jalan?',
    placeholder: '',
    suffix: 'm',
    controlType: 'distanceControl',
    type: 'text',
    required: true,
    value: null,
    validation: {
      min: 0,
      max: 100000000,
      pattern: /^\d+$/
    }
  },
  {
    key: 'M_PITUUS_PYORAILY',
    main: false,
    label: 'Kuinka suuren osan matkasta kuljit pyörällä?',
    guardianLabel: 'Kuinka suuren osan matkasta hän kulki pyörällä?',
    placeholder: '',
    suffix: 'm',
    controlType: 'distanceControl',
    type: 'text',
    required: true,
    value: null,
    validation: {
      min: 0,
      max: 100000000,
      pattern: /^\d+$/
    }
  },

  // Millä kuljit maantieteellisesti pisimmän osuuden matkasta? (Matkan pääasiallinen kulkutapa (se jolla kuljit pisimmän osan
  // matkasta))

  // Millä hän kulki maantieteellisesti pisimmän osuuden matkasta?

  // https://projects.invisionapp.com/d/main#/console/19831125/416280086/preview
  // sn: 7

  {
    key: 'M_PAAKULKUTAPA',
    main: true,
    title:
      'Mikä oli pääkulkutapasi eli millä kuljit maantieteellisesti pisimmän osuuden matkasta?',
    guardianTitle:
      'Mikä oli pääkulkutapa eli millä hän kulki maantieteellisesti pisimmän osuuden matkasta?',
    label:
      'Mikä oli pääkulkutapasi eli millä kuljit maantieteellisesti pisimmän osuuden matkasta?',
    guardianLabel:
      'Mikä oli pääkulkutapa eli millä hän kulki maantieteellisesti pisimmän osuuden matkasta?',
    controlType: 'radioControl',
    type: 'radio',
    required: true,
    value: null,
    validation: {},
    filterOptionsWithAnswersFrom: 'M_KTAVAT',

    next: 'M_HENKILOMAARA',
    skip: (answers: Answers) => {
      let kulkutavatAnswerValue = requireAnswerValue(
        'M_KTAVAT',
        answers,
        'M_PAAKULKUTAPA'
      );

      if (isTypeKeyValuePairArray(kulkutavatAnswerValue)) {
        kulkutavatAnswerValue = uniqWith(kulkutavatAnswerValue, isEqual);
      }

      return (
        !kulkutavatAnswerValue ||
        !Array.isArray(kulkutavatAnswerValue) ||
        kulkutavatAnswerValue.length <= 1
      );
    },
    options: [
      {
        key: 'M_PAAKULKUTAPA_1',
        label: 'Jalankulku (kävely, juoksu, pyörätuoli, rollaattori)',
        value: 1
      },
      {
        key: 'M_PAAKULKUTAPA_2',
        label: 'Polkupyörä (myös sähköpyörä)',
        value: 2
      },
      {
        key: 'M_PAAKULKUTAPA_3',
        label: 'Kaupunkipyörä',
        value: 3
      },
      {
        key: 'M_PAAKULKUTAPA_4',
        label: 'Potkulauta (myös sähköpotkulauta)',
        value: 4
      },
      {
        key: 'M_PAAKULKUTAPA_5',
        label: 'Muu liikunnallinen kulkutapa',
        value: 5
      },
      {
        key: 'M_PAAKULKUTAPA_6',
        label: 'Henkilöauto (kuljettajana)',
        value: 6
      },
      {
        key: 'M_PAAKULKUTAPA_7',
        label: 'Henkilöauto (matkustajana)',
        value: 7
      },
      {
        key: 'M_PAAKULKUTAPA_8',
        label: 'Pakettiauto (kuljettajana)',
        value: 8
      },
      {
        key: 'M_PAAKULKUTAPA_9',
        label: 'Pakettiauto (matkustajana)',
        value: 9
      },
      {
        key: 'M_PAAKULKUTAPA_10',
        label: 'Taksi',
        value: 10
      },
      {
        key: 'M_PAAKULKUTAPA_11',
        label: 'Koulutaksi',
        value: 11
      },
      {
        key: 'M_PAAKULKUTAPA_12',
        label: 'Kutsubussi- tai taksi',
        value: 12
      },
      {
        key: 'M_PAAKULKUTAPA_13',
        label: 'Lähiliikenteen bussi',
        value: 13
      },
      {
        key: 'M_PAAKULKUTAPA_14',
        label: 'Kaukoliikenteen linja-auto',
        value: 14
      },
      {
        key: 'M_PAAKULKUTAPA_15',
        label: 'Tilausbussi (esim. turistibussi tai koululaisretkibussi)',
        value: 15
      },
      {
        key: 'M_PAAKULKUTAPA_16',
        label: 'Muu bussi',
        value: 16
      },
      {
        key: 'M_PAAKULKUTAPA_17',
        label: 'Lähi- tai taajamajuna',
        value: 17
      },
      {
        key: 'M_PAAKULKUTAPA_18',
        label: 'Kaukojuna',
        value: 18
      },
      {
        key: 'M_PAAKULKUTAPA_19',
        label: 'Raitiovaunu',
        value: 19
      },
      {
        key: 'M_PAAKULKUTAPA_20',
        label: 'Metro',
        value: 20
      },
      {
        key: 'M_PAAKULKUTAPA_21',
        label: 'Muu raideliikenne',
        value: 21
      },
      {
        key: 'M_PAAKULKUTAPA_22',
        label: 'Reittilento',
        value: 22
      },
      {
        key: 'M_PAAKULKUTAPA_23',
        label: 'Tilauslento',
        value: 23
      },
      {
        key: 'M_PAAKULKUTAPA_24',
        label: 'Muu lentokone (pienkone, helikopteri)',
        value: 24
      },
      {
        key: 'M_PAAKULKUTAPA_25',
        label: 'Muu moottoroimaton',
        value: 25
      },
      {
        key: 'M_PAAKULKUTAPA_26',
        label: 'Soutuvene',
        value: 26
      },
      {
        key: 'M_PAAKULKUTAPA_27',
        label: 'Purjevene',
        value: 27
      },
      {
        key: 'M_PAAKULKUTAPA_28',
        label: 'Moottorivene',
        value: 28
      },
      {
        key: 'M_PAAKULKUTAPA_29',
        label: 'Laiva/autolautta/pika-alus',
        value: 29
      },
      {
        key: 'M_PAAKULKUTAPA_30',
        label: 'Lossi/yhteysalus/lautta',
        value: 30
      },
      {
        key: 'M_PAAKULKUTAPA_31',
        label: 'Muu vesiliikenne',
        value: 31
      },
      {
        key: 'M_PAAKULKUTAPA_32',
        label: 'Moottoripyörä',
        value: 32
      },
      {
        key: 'M_PAAKULKUTAPA_33',
        label: 'Mopo',
        value: 33
      },
      {
        key: 'M_PAAKULKUTAPA_34',
        label: 'Mopoauto',
        value: 34
      },
      {
        key: 'M_PAAKULKUTAPA_35',
        label: 'Kuorma-auto',
        value: 35
      },
      {
        key: 'M_PAAKULKUTAPA_36',
        label: 'Traktori, työkone',
        value: 36
      },
      {
        key: 'M_PAAKULKUTAPA_37',
        label: 'Muu',
        value: 37
      }
    ]
  },

  // Olisitko voinut tehdä koko matkan jollain muulla kulkutavalla? (Olisitko voinut tehdä matkan jollain muulla pääkulkutavalla?)
  // Olisiko hän voinut tehdä koko matkan jollain muulla kulkutavalla?
  // https://projects.invisionapp.com/d/main#/console/19831125/416280087/preview
  // Sn: 8

  {
    key: 'M_ONKOMUUKT',
    main: true,
    title: 'Olisitko voinut tehdä koko matkan jollain muulla kulkutavalla?',
    guardianTitle:
      'Olisiko hän voinut tehdä koko matkan jollain muulla kulkutavalla?',
    label: 'Olisitko voinut tehdä koko matkan jollain muulla kulkutavalla?',
    controlType: 'radioOtherControl',
    type: 'radio',
    required: false,
    value: null,
    validation: {},

    next: null,
    options: [
      {
        key: 'M_ONKOMUUKT_1',
        label: 'Kyllä',
        value: 1,
        other: {
          key: 'M_VAIHTOKULKUTAPA',
          main: false,
          title: 'Mikä tämä vaihtoehto olisi ollut?',
          label: 'Valitse kulkutapa',
          controlType: 'formArrayControl',
          type: '',
          required: true,
          value: null,
          validation: {
            length: 1
          },
          fields: [
            {
              key: 'M_VAIHTOKULKUTAPA',
              main: false,
              label: 'Lisää kulkutapa',
              controlType: 'groupedRadioControl',
              type: 'radio',
              required: true,
              validation: {},
              value: null,
              groups: [
                {
                  key: 'M_VAIHTOKULKUTAPA_GROUP_1',
                  title: 'Kävely, Pyöräily, ja ulkoliikunta',
                  options: [
                    {
                      key: 'M_VAIHTOKULKUTAPA_1',
                      label:
                        'Jalankulku (kävely, juoksu, pyörätuoli, rollaattori)',
                      value: 1
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_2',
                      label: 'Polkupyörä (myös sähköpyörä)',
                      value: 2
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_3',
                      label: 'Kaupunkipyörä',
                      value: 3
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_4',
                      label: 'Potkulauta (myös sähköpotkulauta)',
                      value: 4
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_5',
                      label: 'Muu liikunnallinen kulkutapa',
                      value: 5
                    }
                  ]
                },
                {
                  key: 'M_VAIHTOKULKUTAPA_GROUP_2',
                  title: 'Henkilö- tai pakettiauto',
                  options: [
                    {
                      key: 'M_VAIHTOKULKUTAPA_6',
                      label: 'Henkilöauto (kuljettajana)',
                      value: 6
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_7',
                      label: 'Henkilöauto (matkustajana)',
                      value: 7
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_8',
                      label: 'Pakettiauto (kuljettajana)',
                      value: 8
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_9',
                      label: 'Pakettiauto (matkustajana)',
                      value: 9
                    }
                  ]
                },
                {
                  key: 'M_VAIHTOKULKUTAPA_GROUP_3',
                  title: 'Taksi- ja kutsuliikenne',
                  options: [
                    {
                      key: 'M_VAIHTOKULKUTAPA_10',
                      label: 'Taksi',
                      value: 10
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_11',
                      label: 'Koulutaksi',
                      value: 11
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_12',
                      label: 'Kutsubussi- tai taksi',
                      value: 12
                    }
                  ]
                },
                {
                  key: 'M_VAIHTOKULKUTAPA_GROUP_4',
                  title: 'Bussi',
                  options: [
                    {
                      key: 'M_VAIHTOKULKUTAPA_13',
                      label: 'Lähiliikenteen bussi',
                      value: 13
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_14',
                      label: 'Kaukoliikenteen linja-auto',
                      value: 14
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_15',
                      label:
                        'Tilausbussi (esim. turistibussi tai koululaisretkibussi)',
                      value: 15
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_16',
                      label: 'Muu bussi',
                      value: 16
                    }
                  ]
                },
                {
                  key: 'M_VAIHTOKULKUTAPA_GROUP_5',
                  title: 'Raideliikenne',
                  options: [
                    {
                      key: 'M_VAIHTOKULKUTAPA_17',
                      label: 'Lähi- tai taajamajuna',
                      value: 17
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_18',
                      label: 'Kaukojuna',
                      value: 18
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_19',
                      label: 'Raitiovaunu',
                      value: 19
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_20',
                      label: 'Metro',
                      value: 20
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_21',
                      label: 'Muu raideliikenne',
                      value: 21
                    }
                  ]
                },
                {
                  key: 'M_VAIHTOKULKUTAPA_GROUP_6',
                  title: 'Ilmaliikenne',
                  options: [
                    {
                      key: 'M_VAIHTOKULKUTAPA_22',
                      label: 'Reittilento',
                      value: 22
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_23',
                      label: 'Tilauslento',
                      value: 23
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_24',
                      label: 'Muu lentokone (pienkone, helikopteri)',
                      value: 24
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_25',
                      label: 'Muu moottoroimaton',
                      value: 25
                    }
                  ]
                },
                {
                  key: 'M_VAIHTOKULKUTAPA_GROUP_7',
                  title: 'Vesiliikenne',
                  options: [
                    {
                      key: 'M_VAIHTOKULKUTAPA_26',
                      label: 'Soutuvene',
                      value: 26
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_27',
                      label: 'Purjevene',
                      value: 27
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_28',
                      label: 'Moottorivene',
                      value: 28
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_29',
                      label: 'Laiva/autolautta/pika-alus',
                      value: 29
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_30',
                      label: 'Lossi/yhteysalus/lautta',
                      value: 30
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_31',
                      label: 'Muu vesiliikenne',
                      value: 31
                    }
                  ]
                },
                {
                  key: 'M_VAIHTOKULKUTAPA_GROUP_8',
                  title: 'Muu ajoneuvo',
                  options: [
                    {
                      key: 'M_VAIHTOKULKUTAPA_32',
                      label: 'Moottoripyörä',
                      value: 32
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_33',
                      label: 'Mopo',
                      value: 33
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_34',
                      label: 'Mopoauto',
                      value: 34
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_35',
                      label: 'Kuorma-auto',
                      value: 35
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_36',
                      label: 'Traktori, työkone',
                      value: 36
                    },
                    {
                      key: 'M_VAIHTOKULKUTAPA_37',
                      label: 'Muu',
                      value: 37
                    }
                  ]
                }
              ]
            }
          ]
        }
      },
      {
        key: 'M_ONKOMUUKT_2',
        label: 'En',
        guardianLabel: 'Ei',
        value: 2
      }
    ]
  },

  // Kuinka monta henkilöä oli mukana pääosalla matkaa? Laske itsesi mukaan. (Kuinka monta henkilöä oli mukana pääosalla matkaa? Laske itsesi mukaan. Laske vain oma matkaseurueesi, esimerkiksi perheenjäsenet tai ystävät. Tässä ei tarkoiteta esimerkiksi junan kaikkia muita matkustajia.)\n' +
  //           'Jos liikuit yksin, merkitse vastaukseksi 1 henkilö.)
  // Mainitse vain seurassasi olleet matkustajat, ei esimerkiksi joukkoliikennevälineen kaikkia matkustajia. Jos liikuit yksin, merkitse 1.

  // Kuinka monta henkilöä oli mukana pääosalla matkaa? Laske vastaaja mukaan.
  // Mainitse vain hänen seurassaan olleet matkustajat, ei esimerkiksi joukkoliikennevälineen kaikkia matkustajia. Jos hän liikkui yksin, merkitse 1.

  // https://projects.invisionapp.com/d/main#/console/19519946/415099490/preview#project_console
  // sn: 9

  {
    key: 'M_HENKILOMAARA',
    main: true,
    title:
      'Kuinka monta henkilöä oli mukana pääosalla matkaa? Laske itsesi mukaan.',
    guardianTitle:
      'Kuinka monta henkilöä oli mukana pääosalla matkaa? Laske vastaaja mukaan.',
    content:
      'Mainitse vain seurassasi olleet matkustajat, ei esimerkiksi joukkoliikennevälineen kaikkia matkustajia. Jos liikuit yksin, merkitse 1.',
    guardianContent:
      'Mainitse vain hänen seurassaan olleet matkustajat, ei esimerkiksi joukkoliikennevälineen kaikkia matkustajia. Jos hän liikkui yksin, merkitse 1.',
    label: '',
    controlType: 'textControl',
    type: 'text',
    required: false,
    value: null,
    validation: {
      min: 1,
      max: 100,
      pattern: /^\d+$/
    },

    next: 'M_KYYTI'
  },

  // Kuuluiko matkaseurueeseen kotitaloutesi ulkopuolisia jäseniä? (Kuuluiko matkaseurueeseen kotitalouden ulkopuolisia jäseniä?)

  //Kuuluiko matkaseurueeseen hänen kotitaloutensa ulkopuolisia jäseniä?

  // https://projects.invisionapp.com/d/main#/console/19519946/415099491/preview
  // sn: 10

  {
    key: 'M_ULKOP',
    main: true,
    title: 'Kuuluiko matkaseurueeseen kotitaloutesi ulkopuolisia jäseniä?',
    label: 'Kuuluiko matkaseurueeseen kotitaloutesi ulkopuolisia jäseniä?',
    guardianTitle:
      'Kuuluiko matkaseurueeseen hänen kotitaloutensa ulkopuolisia jäseniä?',
    controlType: 'radioControl',
    type: 'radio',
    required: false,
    value: null,
    validation: {},

    next: null,
    skip: (answers: Answers) => {
      if (!hasAnswerValue('M_HENKILOMAARA', answers)) {
        return true;
      }
      const thisAnswerValue = requireAnswerValue(
        'M_HENKILOMAARA',
        answers,
        'M_ULKOP'
      );
      return !!(thisAnswerValue && thisAnswerValue <= 1);
    },
    options: [
      {
        key: 'M_ULKOP_1',
        label: 'Kyllä',
        value: 1
      },
      {
        key: 'M_ULKOP_2',
        label: 'Ei',
        value: 2
      }
    ]
  },

  // Saatettiinko tai kyydittiinkö jotakuta tällä matkalla? (Saatettiinko tai kyydittiinkö jotakuta tällä matkalla?)

  // Saatettiinko tai kyydittiinkö jotakuta tällä matkalla?

  // https://projects.invisionapp.com/d/main#/console/19519946/415099492/preview

  // sn: 11

  {
    key: 'M_KYYTI',
    main: true,
    title: 'Oliko matkan tarkoitus kyyditseminen tai saattaminen?',
    label: 'Oliko matkan tarkoitus kyyditseminen tai saattaminen?',
    controlType: 'radioControl',
    type: 'radio',
    required: false,
    value: null,
    validation: {},

    next: null,
    options: [
      {
        key: 'M_KYYTI_1',
        label: 'Kyllä, olin saattaja tai kyyditsijä',
        guardianLabel: 'Kyllä, hän oli saattaja tai kyyditsijä',
        value: 1
      },
      {
        key: 'M_KYYTI_2',
        label: 'Kyllä, minut saatettiin tai sain kyydin',
        guardianLabel: 'Kyllä, hänet saatettiin tai hän sai kyydin',
        value: 2
      },
      {
        key: 'M_KYYTI_3',
        label:
          'Kyllä, olin matkalla mukana, mutta en saattajana tai saatettavana',
        guardianLabel:
          'Kyllä, hän oli matkalla mukana, mutta ei saattajana tai saatettavana',
        value: 3
      },
      {
        key: 'M_KYYTI_4',
        label: 'Ei',
        value: 4
      }
    ]
  }
];
