<ng-container [formGroup]="form">
  <mat-accordion>
    <mat-expansion-panel *ngFor="let group of question.groups">
      <mat-expansion-panel-header>
        <span class="expansion-header">{{ group.getTitle() | translate }}</span>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <hlt-form-radio
          [control]="control"
          [group]="form"
          [label]="option.getLabel() | translate"
          [name]="question.key"
          [value]="option.value"
          *ngFor="let option of group.options"
        ></hlt-form-radio>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
