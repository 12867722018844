import { Component, NgZone, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BackgroundQuestionsService } from '@hlt-app/study/services/background-questions.service';
import { DiaryQuestionsService } from '@hlt-app/study/services/diary-questions.service';
import { FirestoreService } from '@hlt-app/study/services/firestore.service';
import { HabitQuestionsService } from '@hlt-app/study/services/habit-questions.service';
import { PreDiaryQuestionsService } from '@hlt-app/study/services/prediary-questions.service';
import { Store } from '@ngrx/store';
import { AuthActions } from '@xtream/firebase-ngrx-user-management';
import moment from 'moment';
import { Observable } from 'rxjs';
import { catchError, filter, first, map } from 'rxjs/operators';

@Component({
  selector: 'hlt-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss']
})
export class SelectUserComponent implements OnInit {
  public userSelectForm: FormGroup = new FormGroup({
    h_nettitunnus: new FormControl(null, [Validators.required])
  });
  public data$: Observable<any>;
  public momentFunc = moment;
  public error: boolean;
  public now = Math.floor(Date.now() / 1000);

  constructor(
    private route: ActivatedRoute,
    private firestoreService: FirestoreService,
    private ngZone: NgZone,
    private backgroundQuestionsService: BackgroundQuestionsService,
    private habitQuestionsService: HabitQuestionsService,
    private preDiaryQuestionsService: PreDiaryQuestionsService,
    private diaryQuestionsService: DiaryQuestionsService,
    private store: Store
  ) {}

  get hNettitunnus() {
    return this.userSelectForm.get('h_nettitunnus') as FormControl;
  }

  ngOnInit() {
    this.store.dispatch(new AuthActions.GetUser());

    this.backgroundQuestionsService.destroy();
    this.habitQuestionsService.destroy();
    this.preDiaryQuestionsService.destroy();
    this.diaryQuestionsService.destroy();
    this.route.params
      .pipe(
        first((params) => !!params),
        filter((params) => !!params.h_nettitunnus),
        map((params) => params.h_nettitunnus)
      )
      .subscribe((hNettitunnus) => {
        this.hNettitunnus.setValue(hNettitunnus);
        this.submit();
      });
  }

  submit() {
    if (this.userSelectForm.valid) {
      // send
      this.error = false;
      // @todo: why is this not updating the view?
      this.ngZone.run(() => {
        this.data$ = this.firestoreService
          .getUser(this.hNettitunnus.value)
          .pipe(
            catchError((e) => {
              this.error = true;
              return e;
            })
          );
      });
    }
  }
}
