import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { DiaryReducers } from '@hlt-app/study/reducers';
import { FormController } from '@hlt-app/study/services/form-controller';
import { diaryQuestions } from '@hlt-shared/Questions/data/diary';
import { select, Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class DiaryQuestionsService extends FormController {
  protected questions = diaryQuestions;
  protected Answers$ = this.store.pipe(
    select(DiaryReducers.selectStudyDiaryAnswers)
  );
  protected type = 'diary';
  protected backRoute = '/study/diary';

  constructor(
    protected store: Store,
    protected router: Router,
    protected localize: LocalizeRouterService
  ) {
    super(store, router, localize);
  }
}
