<button [disabled]="disabled" (click)="navigate()">
  <div class="container">
    <div class="container__step">
      <div *ngIf="remaining && !completed" class="remaining">
        <span class="remaining__value">{{ remaining }}</span>
      </div>
      <div class="circle" [ngClass]="{ completed: completed }">
        <span *ngIf="!completed" class="pointer">{{ pointer }}</span>
        <mat-icon *ngIf="completed" class="done">done</mat-icon>
      </div>
    </div>
    <span class="button__text">{{ mainText }}</span>
    <mat-icon *ngIf="showArrow" class="arrow" aria-hidden="true">
      arrow_forward
    </mat-icon>
  </div>
  <div class="sub-container" *ngIf="subDescription">
    <p class="sub-container__description">{{ subDescription }}</p>
  </div>
</button>
