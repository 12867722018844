<div id="2fa-captcha"></div>

<div class="container--small">
  <div class="register" *ngIf="!submitted && !recentLogin">
    <div class="header">
      <h1 class="header__title">{{ 'PHONEREGISTER.TITLE' | translate }}</h1>
      <p class="header__desc">
        {{ 'PHONEREGISTER.DESC1' | translate }}
      </p>
    </div>

    <div class="content">
      <form (ngSubmit)="submit()" [formGroup]="form">
        <hlt-text-input
          [form]="form"
          [control]="email"
          [placeholder]="'REGISTER.EMAILPLACEHOLDER' | translate"
          [label]="'REGISTER.EMAILLABEL' | translate"
          type="email"
        ></hlt-text-input>

        <hlt-text-input
          [form]="form"
          [control]="phone"
          [placeholder]="'+3584012345678'"
          [label]="'PHONEREGISTER.PHONELABEL' | translate"
          type="text"
        ></hlt-text-input>

        <p class="content__password-desc">
          {{ 'REGISTER.PASSWORDTEXT' | translate }}
        </p>

        <hlt-text-input
          [form]="form"
          [control]="password"
          [label]="'REGISTER.PASSWORDLABEL' | translate"
          type="password"
        ></hlt-text-input>

        <hlt-text-input
          [form]="form"
          [control]="confirmPassword"
          [label]="'REGISTER.PASSWORDCONFIRMLABEL' | translate"
          type="password"
        ></hlt-text-input>

        <p class="content__safety-desc">
          {{ 'REGISTER.SAFETYTEXT' | translate }}
        </p>

        <hlt-button
          [text]="'REGISTER.CONFIRM' | translate"
          type="submit"
        ></hlt-button>
      </form>
    </div>
  </div>

  <div class="recent-login" *ngIf="recentLogin">
    <div class="content">
      <div class="header">
        <h1 class="header__title">{{ 'PHONEREGISTER.TITLE' | translate }}</h1>
        <p class="header__desc">
          {{ 'PHONEREGISTER.DESC6' | translate }}
        </p>

        <form (ngSubmit)="login()" [formGroup]="loginForm">
          <hlt-text-input
            [form]="loginForm"
            [control]="loginFormEmail"
            [placeholder]="'LOGIN.EMAILPLACEHOLDER' | translate"
            type="email"
          ></hlt-text-input>

          <hlt-text-input
            [label]="'LOGIN.PASSWORD' | translate"
            [form]="loginForm"
            [control]="loginFormPassword"
            type="password"
          ></hlt-text-input>

          <hlt-button
            [text]="'PHONEREGISTER.CONTINUE' | translate"
            type="submit"
          ></hlt-button>
        </form>
        <p *ngIf="loginError">{{ loginError }}</p>
      </div>
    </div>
  </div>

  <div class="confirm-email" *ngIf="submitted && !enrolled && !recentLogin">
    <div class="content">
      <div class="header">
        <h1 class="header__title">{{ 'PHONEREGISTER.TITLE' | translate }}</h1>
        <p *ngIf="!phone.value" class="header__desc">
          {{ 'PHONEREGISTER.DESC2' | translate }}
        </p>
        <p *ngIf="phone.value" class="header__desc">
          {{ 'PHONEREGISTER.DESC3' | translate }}
        </p>
      </div>

      <form [formGroup]="phoneForm" *ngIf="!phone.value" (ngSubmit)="enroll()">
        <hlt-text-input
          [form]="form"
          [control]="phoneFormPhone"
          [placeholder]="'+3584012345678'"
          [label]="'PHONEREGISTER.PHONELABEL' | translate"
          type="text"
        ></hlt-text-input>
        <hlt-button
          [text]="'REGISTER.CONFIRM' | translate"
          type="submit"
        ></hlt-button>
      </form>

      <p *ngIf="error">{{ error }}</p>
      <hlt-button
        *ngIf="phone.value"
        (clicked)="enroll()"
        [text]="'PHONEREGISTER.CONTINUE' | translate"
      ></hlt-button>
    </div>
  </div>

  <div class="verify-phone" *ngIf="enrolled && !verified && !recentLogin">
    <div class="content">
      <div class="header">
        <h1 class="header__title">{{ 'PHONEREGISTER.TITLE' | translate }}</h1>
        <p class="header__desc">
          {{
            'PHONEREGISTER.DESC4' | translate: { value: phoneFormPhone.value }
          }}
        </p>
      </div>

      <form (ngSubmit)="verify()" [formGroup]="verifyForm">
        <hlt-text-input
          [form]="verifyForm"
          [control]="code"
          [placeholder]="'000000'"
          [label]="'PHONEREGISTER.CODE' | translate"
          type="text"
        ></hlt-text-input>
        <hlt-button
          [text]="'REGISTER.CONFIRM' | translate"
          type="submit"
        ></hlt-button>
      </form>

      <p *ngIf="verifyError">{{ verifyError }}</p>
    </div>
  </div>

  <div class="verified" *ngIf="verified && !recentLogin">
    <div class="content">
      <div class="header">
        <h1 class="header__title">{{ 'PHONEREGISTER.TITLE' | translate }}</h1>
        <p class="header__desc">
          {{ 'PHONEREGISTER.DESC5' | translate }}
        </p>
        <hlt-route-button
          [route]="'/welcome'"
          [text]="'PHONEREGISTER.LINK' | translate"
        ></hlt-route-button>
      </div>
    </div>
  </div>
</div>
