<nav class="container">
  <div class="wrapper" role="banner">
    <hlt-button
      [icon]="{
        icon: 'arrow_back',
        label: 'NAVIGATION.BACKBUTTONLABEL' | translate
      }"
      (clicked)="click()"
      [nav]="true"
    ></hlt-button>

    <span class="container__title">{{ title }}</span>
    <p
      [ngClass]="{ hidden: total === 1 && current === 1 }"
      class="container__progress"
    >
      {{ current }}/{{ total }}
    </p>
  </div>
</nav>
