import { BackgroundActions } from '@hlt-app/study/actions';
import { createReducer, on } from '@ngrx/store';
import { QuestionsState } from '.';

const initialState: QuestionsState = {
  answers: {},
  error: null,
  pending: false
};

const reducer = createReducer(
  initialState,
  on(BackgroundActions.setBackgroundData, (state, { answers }) => ({
    ...state,
    answers,
    pending: false,
    error: null
  })),
  on(BackgroundActions.saveBackground, (state, { answers }) => ({
    ...state,
    answers: { ...state.answers, ...answers },
    pending: true
  })),
  on(BackgroundActions.saveBackgroundSuccess, (state) => ({
    ...state,
    pending: false,
    error: null
  })),
  // tslint:disable-next-line: no-shadowed-variable
  on(BackgroundActions.saveBackgroundFailure, (state, { error }) => ({
    ...state,
    pending: false,
    error
  }))
);

const getAnswers = (state: QuestionsState) => state.answers;
const pending = (state: QuestionsState) => state.pending;
const error = (state: QuestionsState) => state.error;

export const BackgroundQuestionsFeatureKey = 'backgroundQuestions';

export const BackgroundQuestionsReducer = {
  reducer,
  getAnswers,
  pending,
  error
};
