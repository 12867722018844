import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'hlt-radio-grouped-input',
  templateUrl: './radio-grouped-input.component.html',
  styleUrls: ['./radio-grouped-input.component.scss']
})
export class RadioGroupedInputComponent implements OnInit {
  @Input() public control: FormControl;
  @Input() public form: FormGroup;
  @Input() public question: any;

  constructor() {}

  ngOnInit(): void {}
}
