import { pick } from 'lodash';
import { IOption } from '../interfaces/interface.option';
import { TypeQuestionClasses } from '../interfaces/type.questionClasses';

export class OptionValue {
  public key!: string;
  public label!: string;
  public value!: number;
  public other?: TypeQuestionClasses;
  public guardian = false;

  constructor(
    option: IOption,
    other: TypeQuestionClasses | null = null,
    guardian = false
  ) {
    this.guardian = guardian;
    const pickedOption = pick(option, ['key', 'label', 'value']);
    Object.assign(this, pickedOption);

    if (!!other) {
      this.other = other;
    }
    return this;
  }

  getLabel() {
    return this.key + (this.guardian ? '.guardian' : '') + '.label';
  }

  toObject() {
    return { ...this };
  }
} // other === true -> value with text field
