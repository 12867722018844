import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'hlt-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() public beforeText = false;
  @Input() public classes: string[] | string;
  @Input() public disabled: boolean;
  @Input() public icon: { icon: string; label: string };
  @Input() public text: string;
  @Input() public type: string;
  @Input() public menu: boolean;
  @Input() public link: boolean;
  @Input() public nav: boolean;
  @Input() public ghost: boolean;
  @Input() public undo: boolean;
  @Input() public edit: boolean;
  @Input() public underline: boolean;
  @Input() public afterInput: boolean;

  @Output() public clicked = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {
    if (typeof this.classes === 'string') {
      this.classes = [this.classes];
    }
  }

  click() {
    this.clicked.emit(true);
  }
}
