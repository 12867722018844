import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { selectUser } from '@hlt-app/auth/reducers';
import { select, Store } from '@ngrx/store';
import {
  AuthActions,
  getAuthError,
  isAuthSuccess
} from '@xtream/firebase-ngrx-user-management';
import { Subscription } from 'rxjs';

@Component({
  selector: 'hlt-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {
  public success$ = this.store.pipe(select(isAuthSuccess));
  public error$ = this.store.pipe(select(getAuthError));
  public user$ = this.store.pipe(select(selectUser));
  private subscription$: Subscription;

  constructor(
    private router: Router,
    private localize: LocalizeRouterService,
    private store: Store,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.subscription$ = this.user$.subscribe((user) => {
      if (!!user) {
        this.ngZone.run(() =>
          this.router.navigate([this.localize.translateRoute('/study')])
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  onSubmit(credentials: {
    email: string;
    password: string;
    rememberMe?: boolean;
  }): void {
    this.store.dispatch(
      new AuthActions.CredentialsLogin(
        credentials.email,
        credentials.password,
        credentials.rememberMe
      )
    );
  }
}
