<div [ngClass]="{ 'input-map': map }">
  <ng-container [formGroup]="form">
    <div matAutocompleteOrigin #origin="matAutocompleteOrigin">
      <ng-container *ngIf="!datalist">
        <hlt-text-input
          [map]="map"
          [autocomplete]="auto"
          [autocompleteConnectedTo]="origin"
          [control]="mainControl"
          [form]="form"
          [label]="question.getLabel() | translate"
          [placeholder]="question.getPlaceholder() | translate"
        ></hlt-text-input>
      </ng-container>

      <ng-container *ngIf="datalist">
        <hlt-text-input-datalist
          [autocompleteConnectedTo]="origin"
          [control]="mainControl"
          [form]="form"
          [label]="question.getLabel() | translate"
          [placeholder]="question.getPlaceholder() | translate"
          [question]="question"
        ></hlt-text-input-datalist>
      </ng-container>
    </div>

    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="optionSelected($event)"
    >
      <ng-container *ngIf="autoComplete$ | async as autoComplete">
        <ng-container *ngFor="let item of autoComplete">
          <mat-option [value]="item">{{ item.properties.label }}</mat-option>
        </ng-container>
      </ng-container>
    </mat-autocomplete>

    <hlt-button
      *ngIf="map"
      (clicked)="openMap()"
      [icon]="{
        icon: 'location_on',
        label: 'MAP.OPENMAP' | translate
      }"
      [edit]="true"
      [afterInput]="true"
    ></hlt-button>
  </ng-container>

  <ng-container *ngIf="map">
    <hlt-map
      *ngIf="showMap"
      [lat]="lat"
      [lon]="lon"
      [street]="mainControl"
      [localAdmin]="localAdmin"
      [country]="country"
      (closeMap)="closeMap($event)"
    ></hlt-map>
  </ng-container>
</div>

<hlt-info-textbox
  *ngIf="infoText"
  [alert]="false"
  [text]="'ADDRESS.INFOTEXT' | translate"
></hlt-info-textbox>
