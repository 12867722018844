export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyCqKLcQB569IOCUKNRvqc1258BVZAEtIqw',
    authDomain: '',
    databaseUrl: 'https://traficom-hlt.firebaseio.com',
    projectId: 'traficom-hlt',
    storageBucket: '',
    messagingSenderId: ''
  },
  digitransitSubscriptionKey: 'f519b53be1484c14bbca9fc590e4e13e',
  baseUrl: 'https://www.hlt24.fi' // @todo: Add a production link after user has resetted password
};
