export let wspKunta = [
  { fi: 'Alajärvi', sv:'Alajärvi' },
  { fi: 'Alavieska', sv:'Alavieska' },
  { fi: 'Alavus', sv:'Alavo' },
  { fi: 'Asikkala', sv:'Asikkala' },
  { fi: 'Askola', sv:'Askola' },
  { fi: 'Aura', sv:'Aura' },
  { fi: 'Akaa', sv:'Ackas' },
  { fi: 'Brändö', sv:'Brändö' },
  { fi: 'Eckerö', sv:'Eckerö' },
  { fi: 'Enonkoski', sv:'Enonkoski' },
  { fi: 'Enontekiö', sv:'Enontekis' },
  { fi: 'Espoo', sv:'Esbo' },
  { fi: 'Eura', sv:'Eura' },
  { fi: 'Eurajoki', sv:'Euraåminne' },
  { fi: 'Evijärvi', sv:'Evijärvi' },
  { fi: 'Finström', sv:'Finström' },
  { fi: 'Forssa', sv:'Forssa' },
  { fi: 'Föglö', sv:'Föglö' },
  { fi: 'Geta', sv:'Geta' },
  { fi: 'Haapajärvi', sv:'Haapajärvi' },
  { fi: 'Haapavesi', sv:'Haapavesi' },
  { fi: 'Hailuoto', sv:'Karlö' },
  { fi: 'Halsua', sv:'Halso' },
  { fi: 'Hamina', sv:'Fredrikshamn' },
  { fi: 'Hammarland', sv:'Hammarland' },
  { fi: 'Hankasalmi', sv:'Hankasalmi' },
  { fi: 'Hanko', sv:'Hangö' },
  { fi: 'Harjavalta', sv:'Harjavalta' },
  { fi: 'Hartola', sv:'GustavAdolfs' },
  { fi: 'Hattula', sv:'Hattula' },
  { fi: 'Hausjärvi', sv:'Hausjärvi' },
  { fi: 'Heinävesi', sv:'Heinävesi' },
  { fi: 'Helsinki', sv:'Helsingfors' },
  { fi: 'Vantaa', sv:'Vanda' },
  { fi: 'Hirvensalmi', sv:'Hirvensalmi' },
  { fi: 'Hollola', sv:'Hollola' },
  { fi: 'Huittinen', sv:'Vittis' },
  { fi: 'Humppila', sv:'Humppila' },
  { fi: 'Hyrynsalmi', sv:'Hyrynsalmi' },
  { fi: 'Hyvinkää', sv:'Hyvinge' },
  { fi: 'Hämeenkyrö', sv:'Tavastkyro' },
  { fi: 'Hämeenlinna', sv:'Tavastehus' },
  { fi: 'Heinola', sv:'Heinola' },
  { fi: 'Ii', sv:'Ijo' },
  { fi: 'Iisalmi', sv:'Idensalmi' },
  { fi: 'Iitti', sv:'Itis' },
  { fi: 'Ikaalinen', sv:'Ikalis' },
  { fi: 'Ilmajoki', sv:'Ilmola' },
  { fi: 'Ilomantsi', sv:'Ilomants' },
  { fi: 'Inari', sv:'Enare' },
  { fi: 'Inkoo', sv:'Ingå' },
  { fi: 'Isojoki', sv:'Storå' },
  { fi: 'Isokyrö', sv:'Storkyro' },
  { fi: 'Imatra', sv:'Imatra' },
  { fi: 'Janakkala', sv:'Janakkala' },
  { fi: 'Joensuu', sv:'Joensuu' },
  { fi: 'Jokioinen', sv:'Jockis' },
  { fi: 'Jomala', sv:'Jomala' },
  { fi: 'Joroinen', sv:'Jorois' },
  { fi: 'Joutsa', sv:'Joutsa' },
  { fi: 'Juuka', sv:'Juga' },
  { fi: 'Juupajoki', sv:'Juupajoki' },
  { fi: 'Juva', sv:'Juva' },
  { fi: 'Jyväskylä', sv:'Jyväskylä' },
  { fi: 'Jämijärvi', sv:'Jämijärvi' },
  { fi: 'Jämsä', sv:'Jämsä' },
  { fi: 'Järvenpää', sv:'Träskända' },
  { fi: 'Kaarina', sv:'S:tKarins' },
  { fi: 'Kaavi', sv:'Kaavi' },
  { fi: 'Kajaani', sv:'Kajana' },
  { fi: 'Kalajoki', sv:'Kalajoki' },
  { fi: 'Kangasala', sv:'Kangasala' },
  { fi: 'Kangasniemi', sv:'Kangasniemi' },
  { fi: 'Kankaanpää', sv:'Kankaanpää' },
  { fi: 'Kannonkoski', sv:'Kannonkoski' },
  { fi: 'Kannus', sv:'Kannus' },
  { fi: 'Karijoki', sv:'Bötom' },
  { fi: 'Karkkila', sv:'Högfors' },
  { fi: 'Karstula', sv:'Karstula' },
  { fi: 'Karvia', sv:'Karvia' },
  { fi: 'Kaskinen', sv:'Kaskö' },
  { fi: 'Kauhajoki', sv:'Kauhajoki' },
  { fi: 'Kauhava', sv:'Kauhava' },
  { fi: 'Kauniainen', sv:'Grankulla' },
  { fi: 'Kaustinen', sv:'Kaustby' },
  { fi: 'Keitele', sv:'Keitele' },
  { fi: 'Kemi', sv:'Kemi' },
  { fi: 'Keminmaa', sv:'Keminmaa' },
  { fi: 'Kempele', sv:'Kempele' },
  { fi: 'Kerava', sv:'Kervo' },
  { fi: 'Keuruu', sv:'Keuru' },
  { fi: 'Kihniö', sv:'Kihniö' },
  { fi: 'Kinnula', sv:'Kinnula' },
  { fi: 'Kirkkonummi', sv:'Kyrkslätt' },
  { fi: 'Kitee', sv:'Kides' },
  { fi: 'Kittilä', sv:'Kittilä' },
  { fi: 'Kiuruvesi', sv:'Kiuruvesi' },
  { fi: 'Kivijärvi', sv:'Kivijärvi' },
  { fi: 'Kokemäki', sv:'Kumo' },
  { fi: 'Kokkola', sv:'Karleby' },
  { fi: 'Kolari', sv:'Kolari' },
  { fi: 'Konnevesi', sv:'Konnevesi' },
  { fi: 'Kontiolahti', sv:'Kontiolax' },
  { fi: 'Korsnäs', sv:'Korsnäs' },
  { fi: 'KoskiTl', sv:'Koskis' },
  { fi: 'Kotka', sv:'Kotka' },
  { fi: 'Kouvola', sv:'Kouvola' },
  { fi: 'Kristiinankaupunki', sv:'Kristinestad' },
  { fi: 'Kruunupyy', sv:'Kronoby' },
  { fi: 'Kuhmo', sv:'Kuhmo' },
  { fi: 'Kuhmoinen', sv:'Kuhmois' },
  { fi: 'Kumlinge', sv:'Kumlinge' },
  { fi: 'Kuopio', sv:'Kuopio' },
  { fi: 'Kuortane', sv:'Kuortane' },
  { fi: 'Kurikka', sv:'Kurikka' },
  { fi: 'Kustavi', sv:'Gustavs' },
  { fi: 'Kuusamo', sv:'Kuusamo' },
  { fi: 'Outokumpu', sv:'Outokumpu' },
  { fi: 'Kyyjärvi', sv:'Kyyjärvi' },
  { fi: 'Kärkölä', sv:'Kärkölä' },
  { fi: 'Kärsämäki', sv:'Kärsämäki' },
  { fi: 'Kökar', sv:'Kökar' },
  { fi: 'Kemijärvi', sv:'Kemijärvi' },
  { fi: 'Kemiönsaari', sv:'Kimitoön' },
  { fi: 'Lahti', sv:'Lahtis' },
  { fi: 'Laihia', sv:'Laihela' },
  { fi: 'Laitila', sv:'Letala' },
  { fi: 'Lapinlahti', sv:'Lapinlahti' },
  { fi: 'Lappajärvi', sv:'Lappajärvi' },
  { fi: 'Lappeenranta', sv:'Villmanstrand' },
  { fi: 'Lapinjärvi', sv:'Lappträsk' },
  { fi: 'Lapua', sv:'Lappo' },
  { fi: 'Laukaa', sv:'Laukas' },
  { fi: 'Lemi', sv:'Lemi' },
  { fi: 'Lemland', sv:'Lemland' },
  { fi: 'Lempäälä', sv:'Lempäälä' },
  { fi: 'Leppävirta', sv:'Leppävirta' },
  { fi: 'Lestijärvi', sv:'Lestijärvi' },
  { fi: 'Lieksa', sv:'Lieksa' },
  { fi: 'Lieto', sv:'Lundo' },
  { fi: 'Liminka', sv:'Limingo' },
  { fi: 'Liperi', sv:'Libelits' },
  { fi: 'Loimaa', sv:'Loimaa' },
  { fi: 'Loppi', sv:'Loppi' },
  { fi: 'Loviisa', sv:'Lovisa' },
  { fi: 'Luhanka', sv:'Luhanka' },
  { fi: 'Lumijoki', sv:'Lumijoki' },
  { fi: 'Lumparland', sv:'Lumparland' },
  { fi: 'Luoto', sv:'Larsmo' },
  { fi: 'Luumäki', sv:'Luumäki' },
  { fi: 'Lohja', sv:'Lojo' },
  { fi: 'Parainen', sv:'Pargas' },
  { fi: 'Maalahti', sv:'Malax' },
  { fi: 'Maarianhamina', sv:'Mariehamn' },
  { fi: 'Marttila', sv:'S:tMårtens' },
  { fi: 'Masku', sv:'Masku' },
  { fi: 'Merijärvi', sv:'Merijärvi' },
  { fi: 'Merikarvia', sv:'Sastmola' },
  { fi: 'Miehikkälä', sv:'Miehikkälä' },
  { fi: 'Mikkeli', sv:'S:tMichel' },
  { fi: 'Muhos', sv:'Muhos' },
  { fi: 'Multia', sv:'Multia' },
  { fi: 'Muonio', sv:'Muonio' },
  { fi: 'Mustasaari', sv:'Korsholm' },
  { fi: 'Muurame', sv:'Muurame' },
  { fi: 'Mynämäki', sv:'Virmo' },
  { fi: 'Myrskylä', sv:'Mörskom' },
  { fi: 'Mäntsälä', sv:'Mäntsälä' },
  { fi: 'Mäntyharju', sv:'Mäntyharju' },
  { fi: 'Mänttä-Vilppula', sv:'Mänttä-Vilppula' },
  { fi: 'Naantali', sv:'Nådendal' },
  { fi: 'Nakkila', sv:'Nakkila' },
  { fi: 'Nivala', sv:'Nivala' },
  { fi: 'Nokia', sv:'Nokia' },
  { fi: 'Nousiainen', sv:'Nousis' },
  { fi: 'Nurmes', sv:'Nurmes' },
  { fi: 'Nurmijärvi', sv:'Nurmijärvi' },
  { fi: 'Närpiö', sv:'Närpes' },
  { fi: 'Orimattila', sv:'Orimattila' },
  { fi: 'Oripää', sv:'Oripää' },
  { fi: 'Orivesi', sv:'Orivesi' },
  { fi: 'Oulainen', sv:'Oulainen' },
  { fi: 'Oulu', sv:'Uleåborg' },
  { fi: 'Padasjoki', sv:'Padasjoki' },
  { fi: 'Paimio', sv:'Pemar' },
  { fi: 'Paltamo', sv:'Paltamo' },
  { fi: 'Parikkala', sv:'Parikkala' },
  { fi: 'Parkano', sv:'Parkano' },
  { fi: 'Pelkosenniemi', sv:'Pelkosenniemi' },
  { fi: 'Perho', sv:'Perho' },
  { fi: 'Pertunmaa', sv:'Pertunmaa' },
  { fi: 'Petäjävesi', sv:'Petäjävesi' },
  { fi: 'Pieksämäki', sv:'Pieksämäki' },
  { fi: 'Pielavesi', sv:'Pielavesi' },
  { fi: 'Pietarsaari', sv:'Jakobstad' },
  { fi: 'Pedersörenkunta', sv:'Pedersöre' },
  { fi: 'Pihtipudas', sv:'Pihtipudas' },
  { fi: 'Pirkkala', sv:'Birkala' },
  { fi: 'Polvijärvi', sv:'Polvijärvi' },
  { fi: 'Pomarkku', sv:'Påmark' },
  { fi: 'Pori', sv:'Björneborg' },
  { fi: 'Pornainen', sv:'Borgnäs' },
  { fi: 'Posio', sv:'Posio' },
  { fi: 'Pudasjärvi', sv:'Pudasjärvi' },
  { fi: 'Pukkila', sv:'Pukkila' },
  { fi: 'Punkalaidun', sv:'Punkalaidun' },
  { fi: 'Puolanka', sv:'Puolanka' },
  { fi: 'Puumala', sv:'Puumala' },
  { fi: 'Pyhtää', sv:'Pyttis' },
  { fi: 'Pyhäjoki', sv:'Pyhäjoki' },
  { fi: 'Pyhäjärvi', sv:'Pyhäjärvi' },
  { fi: 'Pyhäntä', sv:'Pyhäntä' },
  { fi: 'Pyhäranta', sv:'Pyhäranta' },
  { fi: 'Pälkäne', sv:'Pälkäne' },
  { fi: 'Pöytyä', sv:'Pöytyä' },
  { fi: 'Porvoo', sv:'Borgå' },
  { fi: 'Raahe', sv:'Brahestad' },
  { fi: 'Raisio', sv:'Reso' },
  { fi: 'Rantasalmi', sv:'Rantasalmi' },
  { fi: 'Ranua', sv:'Ranua' },
  { fi: 'Rauma', sv:'Raumo' },
  { fi: 'Rautalampi', sv:'Rautalampi' },
  { fi: 'Rautavaara', sv:'Rautavaara' },
  { fi: 'Rautjärvi', sv:'Rautjärvi' },
  { fi: 'Reisjärvi', sv:'Reisjärvi' },
  { fi: 'Riihimäki', sv:'Riihimäki' },
  { fi: 'Ristijärvi', sv:'Ristijärvi' },
  { fi: 'Rovaniemi', sv:'Rovaniemi' },
  { fi: 'Ruokolahti', sv:'Ruokolax' },
  { fi: 'Ruovesi', sv:'Ruovesi' },
  { fi: 'Rusko', sv:'Rusko' },
  { fi: 'Rääkkylä', sv:'Rääkkylä' },
  { fi: 'Raasepori', sv:'Raseborg' },
  { fi: 'Saarijärvi', sv:'Saarijärvi' },
  { fi: 'Salla', sv:'Salla' },
  { fi: 'Salo', sv:'Salo' },
  { fi: 'Saltvik', sv:'Saltvik' },
  { fi: 'Sauvo', sv:'Sagu' },
  { fi: 'Savitaipale', sv:'Savitaipale' },
  { fi: 'Savonlinna', sv:'Nyslott' },
  { fi: 'Savukoski', sv:'Savukoski' },
  { fi: 'Seinäjoki', sv:'Seinäjoki' },
  { fi: 'Sievi', sv:'Sievi' },
  { fi: 'Siikainen', sv:'Siikais' },
  { fi: 'Siikajoki', sv:'Siikajoki' },
  { fi: 'Siilinjärvi', sv:'Siilinjärvi' },
  { fi: 'Simo', sv:'Simo' },
  { fi: 'Sipoo', sv:'Sibbo' },
  { fi: 'Siuntio', sv:'Sjundeå' },
  { fi: 'Sodankylä', sv:'Sodankylä' },
  { fi: 'Soini', sv:'Soini' },
  { fi: 'Somero', sv:'Somero' },
  { fi: 'Sonkajärvi', sv:'Sonkajärvi' },
  { fi: 'Sotkamo', sv:'Sotkamo' },
  { fi: 'Sottunga', sv:'Sottunga' },
  { fi: 'Sulkava', sv:'Sulkava' },
  { fi: 'Sund', sv:'Sund' },
  { fi: 'Suomussalmi', sv:'Suomussalmi' },
  { fi: 'Suonenjoki', sv:'Suonenjoki' },
  { fi: 'Sysmä', sv:'Sysmä' },
  { fi: 'Säkylä', sv:'Säkylä' },
  { fi: 'Vaala', sv:'Vaala' },
  { fi: 'Sastamala', sv:'Sastamala' },
  { fi: 'Siikalatva', sv:'Siikalatva' },
  { fi: 'Taipalsaari', sv:'Taipalsaari' },
  { fi: 'Taivalkoski', sv:'Taivalkoski' },
  { fi: 'Taivassalo', sv:'Tövsala' },
  { fi: 'Tammela', sv:'Tammela' },
  { fi: 'Tampere', sv:'Tammerfors' },
  { fi: 'Tervo', sv:'Tervo' },
  { fi: 'Tervola', sv:'Tervola' },
  { fi: 'Teuva', sv:'Östermark' },
  { fi: 'Tohmajärvi', sv:'Tohmajärvi' },
  { fi: 'Toholampi', sv:'Toholampi' },
  { fi: 'Toivakka', sv:'Toivakka' },
  { fi: 'Tornio', sv:'Torneå' },
  { fi: 'Turku', sv:'Åbo' },
  { fi: 'Pello', sv:'Pello' },
  { fi: 'Tuusniemi', sv:'Tuusniemi' },
  { fi: 'Tuusula', sv:'Tusby' },
  { fi: 'Tyrnävä', sv:'Tyrnävä' },
  { fi: 'Ulvila', sv:'Ulvsby' },
  { fi: 'Urjala', sv:'Urjala' },
  { fi: 'Utajärvi', sv:'Utajärvi' },
  { fi: 'Utsjoki', sv:'Utsjoki' },
  { fi: 'Uurainen', sv:'Uurainen' },
  { fi: 'Uusikaarlepyy', sv:'Nykarleby' },
  { fi: 'Uusikaupunki', sv:'Nystad' },
  { fi: 'Vaasa', sv:'Vasa' },
  { fi: 'Valkeakoski', sv:'Valkeakoski' },
  { fi: 'Varkaus', sv:'Varkaus' },
  { fi: 'Vehmaa', sv:'Vemo' },
  { fi: 'Vesanto', sv:'Vesanto' },
  { fi: 'Vesilahti', sv:'Vesilahti' },
  { fi: 'Veteli', sv:'Vetil' },
  { fi: 'Vieremä', sv:'Vieremä' },
  { fi: 'Vihti', sv:'Vichtis' },
  { fi: 'Viitasaari', sv:'Viitasaari' },
  { fi: 'Vimpeli', sv:'Vindala' },
  { fi: 'Virolahti', sv:'Vederlax' },
  { fi: 'Virrat', sv:'Virdois' },
  { fi: 'Vårdö', sv:'Vårdö' },
  { fi: 'Vöyri', sv:'Vörå' },
  { fi: 'Ylitornio', sv:'Övertorneå' },
  { fi: 'Ylivieska', sv:'Ylivieska' },
  { fi: 'Ylöjärvi', sv:'Ylöjärvi' },
  { fi: 'Ypäjä', sv:'Ypäjä' },
  { fi: 'Ähtäri', sv:'Etseri' },
  { fi: 'Äänekoski', sv:'Äänekoski' }
]
