import { Component, OnInit } from '@angular/core';
import { register } from '@hlt-app/auth/actions/register-page.actions';
import { Credentials } from '@hlt-app/auth/models';
import * as fromAuth from '@hlt-app/auth/reducers';
import { select, Store } from '@ngrx/store';

@Component({
  selector: 'hlt-register',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent implements OnInit {
  pending$ = this.store.pipe(select(fromAuth.selectRegisterPagePending));
  error$ = this.store.pipe(select(fromAuth.selectRegisterPageError));

  constructor(private store: Store<fromAuth.State>) {}

  ngOnInit(): void {}

  onSubmit(credentials: Credentials): void {
    this.store.dispatch(register(credentials));
  }
}
