<div class="container--small" role="main">
  <form (ngSubmit)="submit()" [formGroup]="form">
    <div class="header">
      <h1 class="header__title">{{ 'REGISTER.TITLE' | translate }}</h1>
      <p class="header__desc">
        {{ 'REGISTER.HEADER' | translate }}
      </p>
    </div>

    <div class="content">
      <hlt-text-input
        [label]="'REGISTER.USERNAMELABEL' | translate"
        [form]="form"
        [control]="form.get('token')"
        [placeholder]="'REGISTER.USERNAMEPLACEHOLDER' | translate"
      ></hlt-text-input>

      <p class="content__email-desc">
        {{ 'REGISTER.EMAILTEXT' | translate }}
      </p>

      <hlt-text-input
        [form]="form"
        [control]="form.get('email')"
        [placeholder]="'REGISTER.EMAILPLACEHOLDER' | translate"
        type="email"
        [label]="'REGISTER.EMAILLABEL' | translate"
      ></hlt-text-input>

      <p class="content__password-desc">
        {{ 'REGISTER.PASSWORDTEXT' | translate }}
      </p>

      <hlt-text-input
        [label]="'REGISTER.PASSWORDLABEL' | translate"
        [form]="form"
        [control]="form.get('password')"
        type="password"
        [password]="true"
      ></hlt-text-input>

      <hlt-text-input
        [label]="'REGISTER.PASSWORDCONFIRMLABEL' | translate"
        [form]="form"
        [control]="form.get('confirmPassword')"
        type="password"
      ></hlt-text-input>

      <p class="content__safety-desc">
        {{ 'REGISTER.SAFETYTEXT' | translate }}
      </p>

      <ng-container *ngIf="error">
        <hlt-firebase-errors [error]="error"></hlt-firebase-errors>
      </ng-container>

      <hlt-button
        [text]="'REGISTER.CONFIRM' | translate"
        type="submit"
      ></hlt-button>
      <hlt-route-button
        class="button__undo"
        [text]="'REGISTER.UNDO' | translate"
        route="/start"
        [ghost]="true"
        [undo]="true"
      ></hlt-route-button>
    </div>
  </form>
</div>
