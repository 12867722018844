import { isNumberString } from 'class-validator';
import {
  cloneDeep,
  filter,
  find,
  findLast,
  identity,
  isEmpty,
  isNil,
  isUndefined,
  omitBy,
  pickBy
} from 'lodash';
import {
  Answers,
  isTypeKeyBooleanPair,
  isTypeKeyValuePairArray,
  TypeAnswer
} from '../interfaces/type.answer';
import {
  isArrayQuestion,
  isOptionQuestion,
  TypeQuestionClasses
} from '../interfaces/type.questionClasses';

// @todo: WIP
export class QuestionsController {
  private readonly originalQuestions: TypeQuestionClasses[];
  private readonly keyIndexMap: { [key: string]: number };
  private _sampleVariables: Answers;
  private _answers: Answers;
  private userMetaKeys = [
    'completed',
    'h_alle_15',
    'h_nettitunnus',
    'h_otosalue_id',
    'h_sukupuoli',
    'h_tlapsi',
    'h_tutkimuspvm',
    'h_verkkoalku',
    'h_verkkoloppu',
    'role',
    'uid',
    // @todo: this is a hack, but we need two values from background questions
    'T_AJOKORTTI',
    'T_ANSIOTYO'
  ];

  constructor(
    questions: TypeQuestionClasses[],
    sampleVariables?: Answers,
    answers?: Answers
  ) {
    this.originalQuestions = cloneDeep(questions);
    this._questions = questions;
    this._sampleVariables = sampleVariables || null;
    this._answers = answers || null;

    this._index = 0;
    this.allowedKeys = filter(this.questions, { main: true }).map(
      (qs) => qs.key
    );
    this.allowedSiblingKeys = filter(this.questions, { main: false }).map(
      (qs) => qs.key
    );
    this.keyIndexMap = this.questions
      .map((q, i) => ({ [q.key]: i }))
      .reduce((acc, curr) => ({ ...acc, ...curr }));

    this.setAnswers(this._answers);

    const last = this.getLastAnswerKey();
    if (last) {
      this.reKeyAllowed(last);
    }
    return this;
  }

  get nettitunnus(): string {
    if (!this._sampleVariables) {
      return '';
    }
    return this._sampleVariables.h_nettitunnus as string;
  }

  get count() {
    return this.allowedKeys ? this.allowedKeys.length : 0;
  }

  private _questions: TypeQuestionClasses[];

  get questions(): TypeQuestionClasses[] {
    return this._questions;
  }

  set questions(value: TypeQuestionClasses[]) {
    this._questions = value;
  }

  private _index: number = 0;

  get index(): number {
    return this._index;
  }

  set index(value: number) {
    this._index = value;
  }

  private _allowedKeys: string[] = [];

  get allowedKeys(): string[] {
    return this._allowedKeys;
  }

  set allowedKeys(keys: string[]) {
    this._allowedKeys = keys;
  }

  private _allowedSiblingKeys: string[] = [];

  get allowedSiblingKeys(): string[] {
    return this._allowedSiblingKeys;
  }

  set allowedSiblingKeys(value: string[]) {
    this._allowedSiblingKeys = value;
  }

  reKeyAllowed(until: string | null) {
    let currentQuestion = this.questions[0];
    const currentAnswers = this.getAnswers();
    if (currentQuestion.key === until) {
      return;
    }
    this.findNext(currentQuestion, currentAnswers, false, until);

    // check if we can go further
    if (until && until !== this.getLastKey()) {
      currentQuestion = this.getByKey(until, false);
      try {
        this.findNext(
          currentQuestion,
          currentAnswers,
          false,
          this.getLastKey(),
          true
        );
      } catch (err) {
        return;
        // preconditions not met to look further
      }
      // look until the end
      this.findNext(currentQuestion, currentAnswers, false, this.getLastKey());
    }
  }

  reset(sampleVariables?: Answers) {
    this.questions = cloneDeep(this.originalQuestions);

    if (sampleVariables) {
      this._sampleVariables = sampleVariables;
    }

    this.questions.map((q) => {
      if (isArrayQuestion(q)) {
        q.sampleVariables = this._sampleVariables;
      }
      return q;
    });

    this.allowedKeys = filter(this.questions, { main: true }).map(
      (qs) => qs.key
    );
    this.allowedSiblingKeys = filter(this.questions, { main: false }).map(
      (qs) => qs.key
    );

    this.index = 0;
    return this;
  }

  setClaims(claims: Answers) {
    this._sampleVariables = claims;
  }

  addToAllowed(allowed: string[], key: string) {
    let keys: string[] = [...allowed];
    if (!allowed.includes(key)) {
      keys = [...allowed, key];
    }
    return keys;
  }

  removeFromAllowed(allowed: string[], key: string) {
    if (!allowed) {
      return;
    }
    const index = allowed.indexOf(key);
    if (index !== -1) {
      allowed.splice(index, 1);
    }
  }

  continue(): TypeQuestionClasses[] | null {
    const last = this.getLastAnswerKey();
    if (!last) {
      const q = this.filterQuestionOptions(this.questions[0]);
      if (!!q.skip && q.skip(this.getAnswers())) {
        return this.next();
      } else {
        this.index = 0;
        const res = [q];
        return this.getSiblings(res);
      }
    }

    // valid until last answer key
    if (this.validUntil() === true) {
      //set index
      this.getByKey(last);
    }
    const next = this.next();
    if (!!next) {
      return next;
    } else {
      const q = this.filterQuestionOptions(this.questions[0]);
      if (!!q.skip && q.skip(this.getAnswers())) {
        return this.next();
      } else {
        this.index = 0;
        const res = [q];
        return this.getSiblings(res);
      }
    }
  }

  current(): TypeQuestionClasses[] {
    const current = this.filterQuestionOptions(this.questions[this.index]);
    const res = [current];
    return this.getSiblings(res);
  }

  currentPage(index: boolean | number = false): number {
    let currentKey;
    if (typeof index === 'number') {
      currentKey = this.questions[index].key;
    } else {
      currentKey = this.questions[this.index].key;
    }

    const keys = Object.keys(this.keyIndexMap);
    const intersection = keys.filter(
      (key) => this.allowedKeys?.indexOf(key) !== -1
    );
    return intersection.indexOf(currentKey) + 1;
  }

  status(): number {
    const last = this.getLastAnswerKey();
    if (!last) {
      return this.count;
    }

    const validUntil = this.validUntil();
    // validUntil == index of question
    if (validUntil !== true) {
      return this.count - this.currentPage(validUntil);
    }

    // last === valid answer
    if (this.currentPage() === this.count) {
      return 0;
    } else {
      const index = this.keyIndexMap[last];

      // check further if answers are not required
      if (this.allowedKeys) {
        const rest = this.allowedKeys.slice(this.allowedKeys.indexOf(last) + 1);
        const valid = rest.every((r) => !this.getByKey(r, false).required);
        if (valid) {
          return 0;
        }
      }

      return this.count - this.currentPage(index);
    }
  }

  completed(): boolean {
    return this.status() === 0;
  }

  left(): number {
    return this.status() === this.count ? 0 : this.status();
  }

  getSiblings(questions: TypeQuestionClasses[]) {
    const current = questions[0];
    if (
      current.main &&
      current.hasOwnProperty('siblings') &&
      current.siblings &&
      current.siblings.length !== 0
    ) {
      const currentAnswers = this.getAnswers();
      for (const sibling of current.siblings) {
        const q = this.getByKey(sibling);
        if (!!q.skip && q.skip(currentAnswers)) {
          this.removeFromAllowed(this.allowedSiblingKeys, q.key);
        } else {
          this.allowedSiblingKeys = this.addToAllowed(
            this.allowedSiblingKeys,
            q.key
          );
          questions.push(q);
        }
      }
    }
    return questions;
  }

  findNext(
    currentQuestion: TypeQuestionClasses,
    currentAnswers: Answers,
    setIndex = true,
    until: string | null = null,
    dry: boolean = false
  ): TypeQuestionClasses | null {
    let question = currentQuestion;
    let nextQuestion: TypeQuestionClasses | null = null;

    while (!nextQuestion) {
      const nextQuestionCandidate = this.getNextQuestionCandidate(
        question,
        currentAnswers,
        setIndex
      );

      if (!nextQuestionCandidate) {
        return null;
      }

      if (until && until === nextQuestionCandidate.key) {
        nextQuestion = nextQuestionCandidate;
      }

      if (
        !!nextQuestionCandidate.skip &&
        nextQuestionCandidate.skip(currentAnswers)
      ) {
        if (!dry) {
          this.removeFromAllowed(this.allowedKeys, nextQuestionCandidate.key);
          if (nextQuestionCandidate.siblings) {
            nextQuestionCandidate.siblings.map((key) =>
              this.removeFromAllowed(this.allowedSiblingKeys, key)
            );
          }
        }
        question = nextQuestionCandidate;
      } else {
        if (until === null) {
          nextQuestion = nextQuestionCandidate;
        } else {
          question = nextQuestionCandidate;
        }
      }
    }
    if (!dry) {
      return this.filterQuestionOptions(nextQuestion);
    } else {
      return nextQuestion;
    }
  }

  flatten(input: any[]) {
    const stack = [...input];
    const res = [];
    while (stack.length) {
      // pop value from stack
      const next: any = stack.pop();
      if (Array.isArray(next)) {
        // push back array items, won't modify the original input
        stack.push(...next);
      } else {
        res.push(next);
      }
    }
    // reverse to restore input order
    return res.reverse();
  }

  // @todo: this is a hack, fix this if there is time..
  filterQuestionOptions(question: TypeQuestionClasses): TypeQuestionClasses {
    const answers = this.getAnswers();
    if (
      isOptionQuestion(question) &&
      !!answers &&
      !!question.filterOptionsWithAnswersFrom &&
      answers[question.filterOptionsWithAnswersFrom]
    ) {
      const filterWith: any = answers[question.filterOptionsWithAnswersFrom];
      question.reset();

      if (isTypeKeyValuePairArray(filterWith)) {
        const filteredAnswers = cloneDeep(filterWith)
          .map((answer) => {
            return Object.values(answer).shift();
          })
          .map((values) => {
            if (typeof values === 'string' || typeof values === 'number') {
              return [values];
            } else if (!!values && isTypeKeyBooleanPair(values)) {
              const keys = Object.keys(values);
              return keys.filter((k) => values[k]);
            } else {
              return [];
            }
          })
          .reduce((acc, curr) => [...acc, ...curr])
          .map((key) => {
            return typeof key === 'string' && isNumberString(key)
              ? Number(key)
              : key;
          });

        if (!!filteredAnswers && Array.isArray(filteredAnswers)) {
          question.filter(filteredAnswers as number[]);
        }
      }
    } else if (
      isOptionQuestion(question) &&
      !!question.filterOptionsWithAnswersFrom
    ) {
      question.reset();
    }
    return question;
  }

  next(): TypeQuestionClasses[] | null {
    const currentQuestion = this.questions[this.index];
    const currentAnswers = this.getAnswers();
    const nextQuestion = this.findNext(currentQuestion, currentAnswers);
    if (!nextQuestion) {
      return null;
    }
    this.allowedKeys = this.addToAllowed(this.allowedKeys, nextQuestion.key);
    if (nextQuestion.siblings) {
      nextQuestion.siblings.map(
        (key) =>
          (this.allowedSiblingKeys = this.addToAllowed(
            this.allowedSiblingKeys,
            key
          ))
      );
    }

    this.index = this.questions.indexOf(nextQuestion);
    const next = [nextQuestion];
    return this.getSiblings(next);
  }

  previous(): TypeQuestionClasses[] | null {
    let previousQuestionCandidateIndex = this.index;

    const currentAnswers = this.getAnswers();
    let previousQuestion: TypeQuestionClasses | null = null;

    while (!previousQuestion) {
      previousQuestionCandidateIndex--;
      if (previousQuestionCandidateIndex < 0) {
        // @todo: maybe return question in index 0;
        return null;
      }

      if (
        this.questions[previousQuestionCandidateIndex].hasOwnProperty('skip') &&
        this.questions[previousQuestionCandidateIndex].main
      ) {
        if (
          // @ts-ignore
          !this.questions[previousQuestionCandidateIndex].skip(currentAnswers)
        ) {
          previousQuestion = this.questions[previousQuestionCandidateIndex];
        }
      } else if (this.questions[previousQuestionCandidateIndex].main) {
        previousQuestion = this.questions[previousQuestionCandidateIndex];
      }
    }

    this.index = previousQuestionCandidateIndex;
    previousQuestion = this.filterQuestionOptions(previousQuestion);
    const prev = [previousQuestion];
    return this.getSiblings(prev);
  }

  getAnswers(withOutSample = false): Answers {
    const keyValues: Answers = this.questions
      .map((q) => {
        const key: string = q.key;
        const val: TypeAnswer = q.answer;
        return { [key]: val };
      })
      .reduce((acc, curr) => ({ ...acc, ...curr }));
    if (withOutSample) {
      return keyValues;
    }
    return {
      ...this._sampleVariables,
      ...keyValues
    };
  }

  setAnswers(answers: Answers) {
    if (answers && Object.keys(answers).length) {
      const keys = Object.keys(answers);
      const allowedKeys = [...this.allowedSiblingKeys, ...this.allowedKeys];

      keys.map((key) => {
        if (allowedKeys.includes(key)) {
          this.getByKey(key, false).answer = answers[key];
        } else if (this.checkIfKeyExists(key)) {
          this.getByKey(key, false).answer = null;
        }
      });
      this.unsetNotAllowedAnswers();
    }
  }

  unsetNotAllowedAnswers() {
    this.reKeyAllowed(this.getLastAnswerKey());
    const allowedKeys = [
      ...this.allowedSiblingKeys,
      ...this.allowedKeys,
      ...this.userMetaKeys
    ];
    const answers = this.getAnswers();
    if (answers) {
      const answerKeys = Object.keys(answers);
      const notAllowed = answerKeys.filter(
        (key: string) => !allowedKeys.includes(key)
      );
      notAllowed.map((key: string) => {
        let unsetNotAllowed = null;
        try {
          unsetNotAllowed = this.getByKey(key, false);
        } catch (e) {}
        if (unsetNotAllowed) {
          unsetNotAllowed.answer = null;
        }
      });
      this.validUntil();
    }
  }

  getNextQuestionCandidate(
    currentQuestion: TypeQuestionClasses,
    currentAnswers: Answers,
    setIndex = true
  ) {
    let nextQuestionKey = null;
    if (typeof currentQuestion.next === 'function') {
      nextQuestionKey = currentQuestion.next(currentAnswers);
    } else {
      nextQuestionKey = currentQuestion.next;
    }
    if (!nextQuestionKey) {
      return null;
    }
    return this.getByKey(nextQuestionKey, setIndex);
  }

  getLastKey(): string {
    return this.questions[this.questions.length - 1].key;
  }

  getLastAnswerKey(): string | null {
    let answers: any = this.getAnswers();
    answers = omitBy(answers, isNil);

    if (answers === null) {
      return answers;
    }
    answers = Object.keys(answers);

    const last = findLast(this.questions, (q) => {
      return answers && q.main && answers.indexOf(q.key) !== -1;
    });

    if (last) {
      return last.key;
    } else {
      return null;
    }
  }

  getIndex(index: number): Error | TypeQuestionClasses {
    if (index < 0 || index > this.questions.length) {
      throw new Error('Index not found in questions');
    } else {
      this.index = index;
      return this.questions[this.index];
    }
  }

  // Overwrites questions with result. maybe call reset and be careful.
  filterBy(property: Partial<TypeQuestionClasses>) {
    this.reset();
    this.questions = filter(this.questions, property);
    if (isEmpty(this.questions) || isUndefined(this.questions)) {
      throw new Error('filter returned empty array.');
    }
    this.allowedKeys = [];
    this.questions.map((q) => {
      this.allowedKeys = this.addToAllowed(this.allowedKeys, q.key);
      if (q.siblings) {
        q.siblings.map(
          (key) =>
            (this.allowedSiblingKeys = this.addToAllowed(
              this.allowedSiblingKeys,
              key
            ))
        );
      }
    });
    this.index = 0;
    return this;
  }

  // @todo: should this set index to found question?
  getBy(
    property: Partial<TypeQuestionClasses>
  ): undefined | TypeQuestionClasses {
    const question = find(this.questions, property);
    if (question && question.main) {
      this.index = this.questions.indexOf(question);
    }
    if (!!question) {
      return this.filterQuestionOptions(question);
    } else {
      return question;
    }
  }

  checkIfKeyExists(key: string) {
    const question = this.questions[this.keyIndexMap[key]];
    return question !== undefined;
  }

  getByKey(key: string, setIndex = true): TypeQuestionClasses {
    const question = this.questions[this.keyIndexMap[key]];
    if (question === undefined) {
      throw new Error(`Question ${key} does not exist`);
    }

    if (setIndex && question && question.main) {
      this.index = this.keyIndexMap[key];
    }

    return this.filterQuestionOptions(question);
  }

  validUntil(): boolean | number {
    const last = this.getLastAnswerKey();
    if (!last) {
      return true;
    }
    const index = cloneDeep(this.keyIndexMap);
    const lastIndex = index[last];
    let keys = Object.keys(index);
    keys = keys.slice(0, lastIndex + 1);
    let currentAnswers = this.getAnswers();
    const allowedAnswers = this.allowedKeys;

    const intersection = keys.filter((key) => allowedAnswers?.includes(key));
    currentAnswers = pickBy(currentAnswers, identity);
    for (const [i, questionKey] of intersection.entries()) {
      if (
        typeof currentAnswers[questionKey] === 'undefined' ||
        currentAnswers[questionKey] === null
      ) {
        // @todo: figure out should this blow up or just set the index, create test
        const question = this.getByKey(questionKey, false);
        if (!question.required) {
          continue;
        }

        let key = i;
        if (key !== 0) {
          key = key - 1;
        }
        this.getByKey(intersection[key]);
        return index[intersection[key]];
      }
    }

    return true;
  }
}
