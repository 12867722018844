import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArrayQuestion } from '@hlt-shared/Questions/classes/ArrayQuestion';
import { isNumberString } from '@hlt-shared/Questions/helper/require-answer';
import { Subscription } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'hlt-dialog-question',
  templateUrl: './dialog-question.component.html',
  styleUrls: ['./dialog-question.component.scss']
})
export class DialogQuestionComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public question: ArrayQuestion;

  private sub: Subscription;
  private eosSub: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogQuestionComponent>
  ) {
    this.form = data.form;
    this.question = data.question;
  }

  ngOnInit(): void {
    const age = this.form.get('PE_IKA');
    const dLicence = this.form.get('PE_AJOKORTTI');
    if (age && dLicence) {
      this.sub = age.valueChanges
        .pipe(startWith(age.value as string), debounceTime(300))
        .subscribe((value) => {
          if (typeof value === 'string' && isNumberString(value)) {
            value = Number(value);
          }

          if (value < 17) {
            dLicence.reset();
            dLicence.disable();
          } else {
            dLicence.enable();
          }
        });
    }

    const eos = this.form.get('A_EOS');
    const suorite = this.form.get('A_SUORITE');

    if (eos && suorite) {
      const input = eos.get('1');
      this.eosSub = input.valueChanges
        .pipe(
          startWith(input.value as boolean),
          map((value) => {
            if (value) {
              suorite.reset();
              suorite.disable();
            } else {
              suorite.enable();
            }
          })
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.eosSub) {
      this.eosSub.unsubscribe();
    }
  }

  submitDialog() {
    if (this.form.valid) {
      this.dialogRef.close(this.form);
    } else {
      this.form.markAllAsTouched();
    }
  }

  closeDialog() {
    if (this.form.invalid) {
      this.form.reset();
    }
    this.dialogRef.close(this.form);
  }
}
