import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import * as fromAuth from '@hlt-app/auth/reducers';
import { SpinnerService } from '@hlt-app/auth/services/spinner.service';
import { FirestoreService } from '@hlt-app/study/services/firestore.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { auth } from 'firebase';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { atLeastOne } from './at-least-one.directive';

@Component({
  selector: 'hlt-draw',
  templateUrl: './draw.component.html',
  styleUrls: ['./draw.component.scss']
})
export class DrawComponent implements OnInit, OnDestroy {
  public form: FormGroup = new FormGroup(
    {
      name: new FormControl(null, [Validators.required]),
      email: new FormControl(null),
      phone: new FormControl(null)
    },
    atLeastOne(Validators.required, ['email', 'phone'])
  );

  public user$: Observable<auth.IdTokenResult> = this.store.pipe(
    select(fromAuth.selectUser)
  );

  public guardian = false;
  public testiryhma = 1;

  private userSub: Subscription;
  private send: Subscription;

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private localize: LocalizeRouterService,
    private firestoreService: FirestoreService,
    private ngZone: NgZone,
    private store: Store,
    private spinnerService: SpinnerService
  ) {}

  get name() {
    return this.form.get('name') as FormControl;
  }

  get email() {
    return this.form.get('email') as FormControl;
  }

  get phone() {
    return this.form.get('phone') as FormControl;
  }

  ngOnInit(): void {
    this.userSub = this.user$
      .pipe(
        filter((user) => !!user),
        map((user) => {
          this.spinnerService.show();
          return user;
        }),
        switchMap((user) => this.loadData(user)),
        map(([guardian, testiryhma]) => {
          this.spinnerService.hide();
          this.guardian = guardian;
          this.testiryhma = testiryhma;
        })
      )
      .subscribe();
  }

  loadData(user) {
    if (user.claims.role > 1) {
      return this.handlePhoneUser(user);
    }
    return this.handleRegularUser(user);
  }

  handlePhoneUser(user) {
    return this.firestoreService.getSelectedUser(user.claims.user_id).pipe(
      switchMap((claims) => {
        const testiryhma = claims.h_testiryhma || 1;
        if (!claims.h_alle_15) {
          return of([false, testiryhma]);
        }
        return this.firestoreService.getGuardian(claims.h_nettitunnus).pipe(
          map((guardian) => {
            return [guardian, testiryhma];
          })
        );
      })
    );
  }

  handleRegularUser(user) {
    const testiryhma = user.claims.h_testiryhma || 1;
    if (!user.claims.h_alle_15) {
      return of([false, testiryhma]);
    }
    return this.firestoreService.getGuardian(user.claims.h_nettitunnus).pipe(
      map((guardian) => {
        return [guardian, testiryhma];
      })
    );
  }

  ngOnDestroy() {
    if (this.send) {
      this.send.unsubscribe();
    }
    this.userSub.unsubscribe();
  }

  submit() {
    if (this.form.valid) {
      this.spinnerService.show();
      this.send = this.firestoreService
        .setDraw({
          data: {
            ...this.form.value,
            testiryhma: this.testiryhma
          }
        })
        .pipe(
          tap((res) =>
            this.ngZone.run(() => {
              this.spinnerService.hide();
              this.router.navigate([
                this.localize.translateRoute('/completed')
              ]);
            })
          ),
          catchError((error) => {
            this.spinnerService.hide();
            return error;
          })
        )
        .subscribe();
    } else {
      this.form.markAllAsTouched();
    }
  }

  skip() {
    this.router.navigate([this.localize.translateRoute('/completed')]);
  }
}
