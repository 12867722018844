import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MaterialModule } from '@hlt-app/material';
import {
  AddressInputComponent,
  AnswerTimeEndedComponent,
  ArrayQuestionComponent,
  BoxButtonComponent,
  ButtonComponent,
  DialogQuestionComponent,
  DistanceInputComponent,
  FirebaseErrorsComponent,
  FormCheckboxComponent,
  FormCheckboxesComponent,
  FormErrorsComponent,
  FormNavigationComponent,
  FormRadioComponent,
  FormRadiosComponent,
  FormTimeComponent,
  FormTimeInputComponent,
  InfoTextboxComponent,
  LanguageButtonComponent,
  MapComponent,
  QuestionComponent,
  RadioGroupedInputComponent,
  RadioOtherInputComponent,
  RouteButtonComponent,
  SelectInputComponent,
  SelectOtherInputComponent,
  SelectPrefillInputComponent,
  StartNavigationComponent,
  TextInputComponent,
  TextInputDatalistComponent
} from '@hlt-app/shared/components';
import { DrawComponent } from '@hlt-app/shared/components/draw/draw.component';
import { QuestionareCompletedComponent } from '@hlt-app/shared/components/questionare-completed/questionare-completed.component';
import { TranslateModule } from '@ngx-translate/core';

const COMPONENTS = [
  AddressInputComponent,
  ArrayQuestionComponent,
  BoxButtonComponent,
  ButtonComponent,
  ButtonComponent,
  DialogQuestionComponent,
  DistanceInputComponent,
  FormCheckboxComponent,
  FormCheckboxesComponent,
  FormNavigationComponent,
  FormRadioComponent,
  FormRadiosComponent,
  FormTimeComponent,
  FormTimeInputComponent,
  InfoTextboxComponent,
  LanguageButtonComponent,
  QuestionComponent,
  RadioGroupedInputComponent,
  RadioOtherInputComponent,
  RouteButtonComponent,
  SelectInputComponent,
  SelectOtherInputComponent,
  SelectPrefillInputComponent,
  StartNavigationComponent,
  TextInputComponent,
  FormErrorsComponent,
  FirebaseErrorsComponent,
  MapComponent,
  QuestionareCompletedComponent,
  DrawComponent,
  AnswerTimeEndedComponent,
  TextInputDatalistComponent
];

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    TranslateModule,
    HttpClientModule,
    LeafletModule
  ],
  declarations: COMPONENTS,
  exports: [...COMPONENTS, TranslateModule],
  providers: [HttpClientModule]
})
export class SharedModule {}
