<div class="time__container--fallback" (click)="focusHour()" [formGroup]="form">
  <label id="hours" class="cdk-visually-hidden">{{
    'TIMEINPUT.HOURS' | translate
  }}</label>
  <input
    id="time"
    [attr.aria-labelledby]="label + ' hours'"
    type="text"
    placeholder="--"
    class="hours time--fallback"
    [formControl]="hours"
    (blur)="leadingZeroes(hours)"
    (click)="select($event)"
    #hourEl
  />
  <span>.</span>
  <label id="minutes" class="cdk-visually-hidden">{{
    'TIMEINPUT.MINUTES' | translate
  }}</label>
  <input
    [attr.aria-labelledby]="label + ' minutes'"
    type="text"
    placeholder="--"
    class="minutes time--fallback"
    [formControl]="minutes"
    (blur)="leadingZeroes(minutes)"
    (click)="select($event)"
    #minutesEl
  />
</div>
