/* istanbul ignore file */
import { ArrayQuestion } from '../classes/ArrayQuestion';
import { OptionGroupQuestion } from '../classes/OptionGroupQuestion';
import { OptionQuestion } from '../classes/OptionQuestion';
import { Question } from '../classes/Question';

export const isOptionQuestion = (
  question: TypeQuestionClasses
): question is OptionQuestion => {
  return (
    !!question &&
    question.hasOwnProperty('options') &&
    !question.hasOwnProperty('_fields') &&
    !question.hasOwnProperty('groups')
  );
};

export const isOptionGroupQuestion = (
  question: TypeQuestionClasses
): question is OptionGroupQuestion => {
  return (
    !!question &&
    question.hasOwnProperty('options') &&
    question.hasOwnProperty('groups') &&
    !question.hasOwnProperty('_fields')
  );
};

export const isArrayQuestion = (
  question: TypeQuestionClasses
): question is ArrayQuestion => {
  return (
    !!question &&
    question.hasOwnProperty('_fields') &&
    !question.hasOwnProperty('options') &&
    !question.hasOwnProperty('groups')
  );
};

export type TypeQuestionClasses =
  | Question
  | OptionQuestion
  | OptionGroupQuestion
  | ArrayQuestion;
