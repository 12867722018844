<fieldset class="radio-buttons">
  <ng-container *ngFor="let option of question.options">
    <hlt-form-radio
      [control]="control"
      [group]="form"
      [label]="option.getLabel() | translate"
      [name]="question.key"
      [value]="option.value"
      [inactive]="disable.indexOf(option.value) !== -1 ? true : null"
    ></hlt-form-radio>
  </ng-container>
  <hlt-form-errors [control]="control"></hlt-form-errors>
  <hlt-info-textbox
    *ngIf="infobox"
    [alert]="false"
    [text]="'PREDIARY.INFOTEXT' | translate"
  ></hlt-info-textbox>
</fieldset>
