import { PreDiaryActions } from '@hlt-app/study/actions';
import { createReducer, on } from '@ngrx/store';
import { QuestionsState } from '.';

const initialState: QuestionsState = {
  answers: {},
  error: null,
  pending: false
};

const reducer = createReducer(
  initialState,
  on(PreDiaryActions.setPreDiaryData, (state, { answers }) => ({
    ...state,
    answers,
    pending: false,
    error: null
  })),
  on(PreDiaryActions.savePreDiary, (state, { answers }) => ({
    ...state,
    answers: { ...state.answers, ...answers },
    pending: true
  })),
  on(PreDiaryActions.savePreDiarySuccess, (state) => ({
    ...state,
    pending: false,
    error: null
  })),
  // tslint:disable-next-line: no-shadowed-variable
  on(PreDiaryActions.savePreDiaryFailure, (state, { error }) => ({
    ...state,
    pending: false,
    error
  }))
);

const getAnswers = (state: QuestionsState) => state.answers;
const pending = (state: QuestionsState) => state.pending;
const error = (state: QuestionsState) => state.error;

export const PreDiaryQuestionsFeatureKey = 'preDiaryQuestions';

export const PreDiaryQuestionsReducer = {
  reducer,
  getAnswers,
  pending,
  error
};
