import { Injectable } from '@angular/core';
import { AuthApiActions } from '@hlt-app/auth/actions';
import { AuthService } from '@hlt-app/auth/services';
import { SpinnerService } from '@hlt-app/auth/services/spinner.service';
import { FirestoreService } from '@hlt-app/study/services/firestore.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthActions } from '@xtream/firebase-ngrx-user-management';
import { auth } from 'firebase';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class LoginEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private firestoreService: FirestoreService,
    private spinnerService: SpinnerService
  ) {}

  authenticatedSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.AuthActionTypes.Authenticated),
      switchMap(() =>
        this.authService.getJWTToken().pipe(
          filter((user) => !!user),
          take(1),
          map((user: auth.IdTokenResult) => {
            if (typeof user.claims.role === 'undefined') {
              throw new Error('no role');
            }
            return user;
          }),
          switchMap((user: auth.IdTokenResult) => {
            return this.firestoreService
              .setLogin({
                login: {
                  time: new Date(user.authTime).getTime(),
                  browser: window.navigator.userAgent
                },
                h_nettitunnus: user.claims.h_nettitunnus
              })
              .pipe(map(() => user));
          }),
          map((user: auth.IdTokenResult) => {
            this.spinnerService.hide();
            return AuthApiActions.loginSuccess({ user });
          }),
          catchError((error: any) => {
            if (error.message === 'no role') {
              return of(AuthApiActions.registerClaimsFailure(error));
            }

            return of(new AuthActions.AuthError(error));
          })
        )
      )
    );
  });

  authError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.AuthActionTypes.AuthError),
        tap(() => this.spinnerService.hide())
      );
    },
    { dispatch: false }
  );

  login$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.AuthActionTypes.CredentialsLogin),
        tap(() => this.spinnerService.show())
      );
    },
    { dispatch: false }
  );
}
