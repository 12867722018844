import { hasAnswerValue, requireAnswerValue } from '../helper/require-answer';
import { Answers } from '../interfaces/type.answer';
import { TypeQuestion } from '../interfaces/type.question';

export const backgroundQuestions: TypeQuestion[] = [
  {
    key: 'T_IKA',
    main: true,
    title: 'Minkä ikäinen olet?',
    guardianTitle: 'Minkä ikäinen tutkimukseen valittu on?',
    label: 'Ikä',
    prependAnswer: 'Ikä:',
    placeholder: 'Esim. 30',
    controlType: 'textControl',
    type: 'text',
    required: true,
    value: null,
    validation: {
      min: 4,
      max: 120,
      pattern: /^\d+$/
    },
    next: 'T_SUKUPUOLI'
  },
  {
    key: 'T_SUKUPUOLI',
    main: true,
    title: 'Mikä on sukupuolesi?',
    guardianTitle: 'Mikä on hänen sukupuolensa?',
    label: 'Sukupuoli',
    prependAnswer: 'Sukupuoli:',
    controlType: 'radioControl',
    type: 'radio',
    required: true,
    value: null,
    validation: {},

    next: 'T_KOTIKUNTA',
    options: [
      {
        key: 'T_SUKUPUOLI_1',
        label: 'Mies',
        value: 1
      },
      {
        key: 'T_SUKUPUOLI_2',
        label: 'Nainen',
        value: 2
      },
      {
        key: 'T_SUKUPUOLI_3',
        label: 'Muu tai en halua vastata',
        guardianLabel: 'Muu tai ei halua vastata',
        value: 3
      }
    ]
  },
  {
    key: 'T_KOTIKUNTA',
    main: true,
    label: 'Kunta',
    placeholder: 'Esim. Helsinki',
    controlType: 'datalistControl',
    autocompleteLayers: ['localadmin'],
    type: 'text',
    required: true,
    value: null,
    validation: {},
    next: 'T_ASUINPAIKKA',
    siblings: ['T_KOTIOSOITE', 'T_KOTIOSOITE_LAT', 'T_KOTIOSOITE_LON']
  },
  {
    key: 'T_KOTIOSOITE',
    main: false,
    title: 'Mikä on kotiosoitteesi?',
    guardianTitle: 'Mikä on hänen kotiosoitteensa?',
    label: 'Kadun tai tien nimi ja talon numero',
    placeholder: 'Esim. Aleksanterinkatu 20',
    controlType: 'autocompleteControl',
    autocompleteFields: {
      lat: 'T_KOTIOSOITE_LAT',
      lon: 'T_KOTIOSOITE_LON',
      localAdmin: 'T_KOTIKUNTA'
    },
    autocompleteLayers: ['address', 'street'],
    type: 'text',
    required: true,
    value: null,
    validation: {}
  },
  {
    key: 'T_KOTIOSOITE_LAT',
    main: false,
    label: '',
    placeholder: '',
    controlType: 'textControl',
    type: 'hidden',
    required: false,
    value: null,
    validation: {}
  },
  {
    key: 'T_KOTIOSOITE_LON',
    main: false,
    label: '',
    placeholder: '',
    controlType: 'textControl',
    type: 'hidden',
    required: false,
    value: null,
    validation: {}
  },
  {
    key: 'T_ASUINPAIKKA',
    main: true,
    title: 'Asutko...',
    guardianTitle: 'Asuuko hän...',
    label: 'Asutko...',
    controlType: 'radioControl',
    type: 'radio',
    required: false,
    value: null,
    validation: {},

    next: 'T_AJOKORTTI',
    options: [
      {
        key: 'T_ASUINPAIKKA_1',
        label: 'Kerros- tai luhtitalossa',
        value: 1
      },
      {
        key: 'T_ASUINPAIKKA_2',
        label: 'Rivitalossa',
        value: 2
      },
      {
        key: 'T_ASUINPAIKKA_3',
        label: 'Paritalossa',
        value: 3
      },
      {
        key: 'T_ASUINPAIKKA_4',
        label: 'Omakotitalossa',
        value: 4
      },
      {
        key: 'T_ASUINPAIKKA_5',
        label:
          'Palveluasunnossa, ryhmäkodissa, laitoksessa tai muussa vastaavassa',
        value: 5
      },
      {
        key: 'T_ASUINPAIKKA_6',
        label: 'Muu asumismuoto',
        value: 6
      }
    ]
  },
  {
    key: 'T_MUUPAIKKAKUNTA',
    main: false,
    title:
      'Onko sinulla vapaa-ajan asuntoa tai muuta toista asuntoa, jota käytät säännöllisesti?',
    guardianTitle:
      'Onko hänellä käytettävissään vapaa-ajan asuntoa tai muuta toista asuntoa, jota hän käyttää säännöllisesti?',
    label:
      'Onko sinulla vapaa-ajan asuntoa tai muuta toista asuntoa, jota käytät säännöllisesti?',
    controlType: 'radioOtherControl',
    type: 'radio',
    required: false,
    value: null,
    validation: {},
    next: null,
    skip: (answers: Answers) => {
      const areaAnswerValue = requireAnswerValue(
        'h_otosalue_id',
        answers,
        'T_MUUPAIKKAKUNTA'
      );
      return areaAnswerValue !== 99;
    },
    options: [
      {
        key: 'T_MUUPAIKKAKUNTA_1',
        label: 'Kyllä',
        value: 1,
        other: {
          key: 'T_MUUPAIKKAKUNTA_MIKA',
          main: false,
          label: 'Missä kunnassa asunto sijaitsee?',
          placeholder: 'Esim. Karkkila',
          controlType: 'autocompleteControl',
          autocompleteLayers: ['localadmin'],
          type: 'text',
          required: true,
          value: null,
          validation: {}
        }
      },
      {
        key: 'T_MUUPAIKKAKUNTA_2',
        label: 'Ei',
        value: 2
      }
    ]
  },
  {
    key: 'T_AJOKORTTI',
    main: true,
    title: 'Onko sinulla henkilöauton ajokorttia?',
    label: 'Onko sinulla henkilöauton ajokorttia?',
    prependAnswer: 'Ajokortti:',
    controlType: 'radioControl',
    type: 'radio',
    required: true,
    value: null,
    validation: {},
    next: 'T_PERHELKM',
    skip: (answers: Answers) => {
      const ageAnswerValue = requireAnswerValue(
        'T_IKA',
        answers,
        'T_AJOKORTTI'
      );
      return !!(ageAnswerValue && ageAnswerValue < 17);
    },
    options: [
      {
        key: 'T_AJOKORTTI_1',
        label: 'Kyllä, voimassa oleva',
        value: 1
      },
      {
        key: 'T_AJOKORTTI_2',
        label: 'Ei, mutta on joskus ollut',
        value: 2
      },
      {
        key: 'T_AJOKORTTI_3',
        label: 'Ei ole koskaan ollut',
        value: 3
      }
    ]
  },
  {
    key: 'T_PERHELKM',
    main: true,
    title: 'Ketkä kaikki kuuluvat kotitalouteesi?',
    guardianTitle: 'Ketkä kaikki kuuluvat hänen kotitalouteensa?',
    label: 'Lisää henkilö',
    controlType: 'formArrayControl',
    guideTexts: {
      edit: '= muokkaa henkilön tietoja',
      delete: '= poista henkilö',
      drag: '= siirrä ylös tai alas raahaamalla'
    },
    skipText: 'Ei muita henkilöitä',
    icon: 'account_circle',
    contentType: 'asukas',
    type: '',
    required: false,
    value: null,
    validation: {},
    next: 'T_HLOVUOSITULOT',
    fields: [
      {
        key: 'PE_SUKUPUOLI',
        main: false,
        title: 'Onko hän...',
        label: 'Onko hän...',
        prependAnswer: 'Sukupuoli:',
        controlType: 'radioControl',
        type: 'radio',
        required: true,
        value: null,
        validation: {},
        options: [
          {
            key: 'PE_SUKUPUOLI_1',
            label: 'Mies',
            value: 1
          },
          {
            key: 'PE_SUKUPUOLI_2',
            label: 'Nainen',
            value: 2
          },
          {
            key: 'PE_SUKUPUOLI_3',
            label: 'Muu tai en halua vastata',
            guardianLabel: 'Muu tai ei halua vastata',
            value: 3
          }
        ]
      },

      {
        key: 'PE_IKA',
        main: false,
        label: 'Minkä ikäinen hän on?',
        prependAnswer: 'Ikä:',
        controlType: 'textControl',
        type: 'text',
        placeholder: 'Esim. 30',
        required: true,
        value: null,
        validation: {
          min: 0,
          max: 120,
          pattern: /^\d+$/
        }
      },

      {
        key: 'PE_AJOKORTTI',
        main: false,
        label: 'Onko hänellä henkilöauton ajokorttia?',
        prependAnswer: 'Ajokortti:',
        controlType: 'radioControl',
        type: 'radio',
        required: true,
        value: null,
        validation: {},
        skip: (answers: Answers) => {
          const areaAnswerValue = requireAnswerValue(
            'h_otosalue_id',
            answers,
            'PE_AJOKORTTI'
          );

          if (areaAnswerValue !== 99) {
            return true;
          }

          if (!hasAnswerValue('PE_IKA', answers)) {
            return false;
          }

          const ageAnswerValue = requireAnswerValue(
            'PE_IKA',
            answers,
            'PE_AJOKORTTI'
          );

          return !!(ageAnswerValue && ageAnswerValue < 17);
        },
        options: [
          {
            key: 'PE_AJOKORTTI_1',
            label: 'Kyllä',
            value: 1
          },
          {
            key: 'PE_AJOKORTTI_2',
            label: 'Ei',
            value: 2
          },
          {
            key: 'PE_AJOKORTTI_3',
            label: 'En osaa sanoa',
            guardianLabel: 'Ei osaa sanoa',
            value: 3
          }
        ]
      }
    ]
  },

  {
    key: 'T_HLOVUOSITULOT',
    main: true,
    title:
      'Mitkä ovat sinun henkilökohtaiset ansio- ja pääomatulosi ennen verojen vähentämistä (ns. bruttotulot) vuodessa?',
    guardianTitle:
      'Mitkä ovat hänen henkilökohtaiset ansio- ja pääomatulonsa ennen verojen vähentämistä (ns. bruttotulot) vuodessa?',
    content:
      'Tuloja ovat mm. palkka, eläke, opintotuki, työttömyyskorvaus ja etuudet, tulo omasta yrityksestä tai maataloudesta, palkkiot ja osingot.',
    label:
      'Mitkä ovat sinun henkilökohtaiset ansio- ja pääomatulosi ennen verojen vähentämistä (ns. bruttotulot) vuodessa?',
    controlType: 'radioControl',
    type: 'radio',
    required: false,
    value: null,
    validation: {},
    next: 'T_VUOSITULOT',
    options: [
      {
        key: 'T_HLOVUOSITULOT_1',
        label: '0–9 999 euroa vuodessa',
        value: 1
      },
      {
        key: 'T_HLOVUOSITULOT_2',
        label: '10 000–19 999 euroa vuodessa',
        value: 2
      },
      {
        key: 'T_HLOVUOSITULOT_3',
        label: '20 000–29 999 euroa vuodessa',
        value: 3
      },
      {
        key: 'T_HLOVUOSITULOT_4',
        label: '30 000–39 999  euroa vuodessa',
        value: 4
      },
      {
        key: 'T_HLOVUOSITULOT_5',
        label: '40 000–49 999 euroa vuodessa',
        value: 5
      },
      {
        key: 'T_HLOVUOSITULOT_6',
        label: '50 000–59 999  euroa vuodessa',
        value: 6
      },
      {
        key: 'T_HLOVUOSITULOT_7',
        label: '60 000–69 999  euroa vuodessa',
        value: 7
      },
      {
        key: 'T_HLOVUOSITULOT_8',
        label: '70 000–99 999  euroa vuodessa',
        value: 8
      },
      {
        key: 'T_HLOVUOSITULOT_9',
        label: '100 000–149 999  euroa vuodessa',
        value: 9
      },
      {
        key: 'T_HLOVUOSITULOT_10',
        label: 'Yli 150 000  euroa vuodessa',
        value: 10
      },
      {
        key: 'T_HLOVUOSITULOT_11',
        label: 'En osaa sanoa tai en halua vastata',
        guardianLabel: 'Ei osaa sanoa tai ei halua vastata',
        value: 11
      }
    ]
  },
  {
    key: 'T_VUOSITULOT',
    main: true,
    title:
      'Mitkä ovat kotitaloutesi yhteenlasketut ansio- ja pääomatulot ennen verojen vähentämistä (ns. bruttotulot) vuodessa?',
    guardianTitle:
      'Mitkä ovat hänen kotitaloutensa yhteenlasketut ansio- ja pääomatulot ennen verojen vähentämistä (ns. bruttotulot) vuodessa?',
    content:
      'Tuloja ovat mm. palkka, eläke, opintotuki, työttömyyskorvaus ja etuudet, tulo omasta yrityksestä tai maataloudesta, palkkiot ja osingot.',
    label:
      'Mitkä ovat kotitaloutesi yhteenlasketut ansio- ja pääomatulot ennen verojen vähentämistä (ns. bruttotulot) vuodessa?',
    controlType: 'radioControl',
    type: 'radio',
    required: false,
    value: null,
    validation: {},
    next: 'T_ANSIOTYO',
    options: [
      {
        key: 'T_VUOSITULOT_1',
        label: '0–19 999 euroa vuodessa',
        value: 1
      },
      {
        key: 'T_VUOSITULOT_2',
        label: '20 000–39 999  euroa vuodessa',
        value: 2
      },
      {
        key: 'T_VUOSITULOT_3',
        label: '40 000–59 999  euroa vuodessa',
        value: 3
      },
      {
        key: 'T_VUOSITULOT_4',
        label: '60 000–79 999  euroa vuodessa',
        value: 4
      },
      {
        key: 'T_VUOSITULOT_5',
        label: '80 000–99 999  euroa vuodessa',
        value: 5
      },
      {
        key: 'T_VUOSITULOT_6',
        label: '100 000–119 999  euroa vuodessa',
        value: 6
      },
      {
        key: 'T_VUOSITULOT_7',
        label: '120 000–149 999  euroa vuodessa',
        value: 7
      },
      {
        key: 'T_VUOSITULOT_8',
        label: '150 000–199 999  euroa vuodessa',
        value: 8
      },
      {
        key: 'T_VUOSITULOT_9',
        label: 'Yli 200 000  euroa vuodessa',
        value: 9
      },
      {
        key: 'T_VUOSITULOT_10',
        label: 'En osaa sanoa tai en halua vastata',
        guardianLabel: 'Ei osaa sanoa tai ei halua vastata',
        value: 10
      }
    ]
  },

  {
    key: 'T_ANSIOTYO',
    main: true,
    title: 'Mikä seuraavista kuvaa tämänhetkistä työssäkäyntiäsi parhaiten?',
    label: 'Mikä seuraavista kuvaa tämänhetkistä työssäkäyntiäsi parhaiten?',
    controlType: 'radioControl',
    type: 'radio',
    required: false,
    value: null,
    validation: {},
    next: 'T_AMMATTI',
    skip: (answers: Answers) => {
      const ageAnswerValue = requireAnswerValue('T_IKA', answers, 'T_ANSIOTYO');
      return !!(ageAnswerValue && ageAnswerValue < 15);
    },
    options: [
      {
        key: 'T_ANSIOTYO_1',
        label: 'Kokopäivätyö',
        value: 1
      },
      {
        key: 'T_ANSIOTYO_2',
        label: 'Osa-aikatyö tai lyhennetty työaika',
        value: 2
      },
      {
        key: 'T_ANSIOTYO_3',
        label: 'Satunnainen työnteko',
        value: 3
      },
      {
        key: 'T_ANSIOTYO_4',
        label: 'Osa-aikainen lomautus',
        value: 4
      },
      {
        key: 'T_ANSIOTYO_5',
        label: 'Kokoaikainen lomautus',
        value: 5
      },
      {
        key: 'T_ANSIOTYO_6',
        label: 'Työtön',
        value: 6
      },
      {
        key: 'T_ANSIOTYO_7',
        label: 'En käy lainkaan töissä',
        value: 7
      },
      {
        key: 'T_ANSIOTYO_8',
        label: 'En osaa sanoa',
        value: 8
      }
    ]
  },
  {
    key: 'T_AMMATTI',
    main: true,
    title: 'Mikä kuvaa parhaiten tämänhetkistä pääasiallista toimintaasi?',
    label: 'Mikä kuvaa parhaiten tämänhetkistä pääasiallista toimintaasi?',
    controlType: 'radioControl',
    type: 'radio',
    required: false,
    value: null,
    validation: {},
    skip: (answers: Answers) => {
      if (!hasAnswerValue('T_ANSIOTYO', answers)) {
        return false;
      }

      const answerValue = requireAnswerValue(
        'T_ANSIOTYO',
        answers,
        'T_AMMATTI'
      );
      return !!(answerValue && answerValue === 6);
    },
    next: 'T_KOULUTUS',
    options: [
      {
        key: 'T_AMMATTI_1',
        label: 'Työntekijä',
        value: 1
      },
      {
        key: 'T_AMMATTI_2',
        label: 'Alempi toimihenkilö',
        value: 2
      },
      {
        key: 'T_AMMATTI_3',
        label: 'Ylempi tai johtavassa asemassa oleva toimihenkilö',
        value: 3
      },
      {
        key: 'T_AMMATTI_4',
        label: 'Maatalousyrittäjä tai maanviljelijä',
        value: 4
      },
      {
        key: 'T_AMMATTI_5',
        label: 'Muu yrittäjä',
        value: 5
      },
      {
        key: 'T_AMMATTI_6',
        label: 'Kotiäiti, -isä tai perhevapaa',
        value: 6
      },
      {
        key: 'T_AMMATTI_7',
        label: 'Eläkeläinen',
        value: 7
      },
      {
        key: 'T_AMMATTI_8',
        label:
          'Varusmiespalvelus, siviilipalvelus tai vapaaehtoinen asepalvelus',
        value: 8
      },
      {
        key: 'T_AMMATTI_9',
        label: 'Opiskelija (lukio, ammattiopisto, korkeakoulu, ...)',
        value: 9
      },
      {
        key: 'T_AMMATTI_10',
        label: 'Koululainen (peruskoulu)',
        value: 10
      },
      {
        key: 'T_AMMATTI_11',
        label: 'Alle kouluikäinen',
        value: 11
      },
      {
        key: 'T_AMMATTI_12',
        label: 'Muu',
        value: 12
      }
    ]
  },
  {
    key: 'T_KOULUTUS',
    main: true,
    title: 'Mikä on koulutuksesi? Ilmoita ylin suoritettu tutkinto.',
    label: 'Mikä on koulutuksesi? Ilmoita ylin suoritettu tutkinto.',
    controlType: 'radioControl',
    type: 'radio',
    required: true,
    value: null,
    validation: {},
    next: 'T_KODINULKOP',
    skip: (answers: Answers) => {
      if (!hasAnswerValue('T_AMMATTI', answers)) {
        return false;
      }

      const occupationAnswerValue = requireAnswerValue(
        'T_AMMATTI',
        answers,
        'T_KOULUTUS'
      );
      return !!(
        occupationAnswerValue &&
        (occupationAnswerValue === 10 || occupationAnswerValue === 11)
      );
    },
    options: [
      {
        key: 'T_KOULUTUS_1',
        label: 'Ei koulutusta tai perusaste kesken',
        value: 1
      },
      {
        key: 'T_KOULUTUS_2',
        label: 'Perusaste suoritettuna',
        value: 2
      },
      {
        key: 'T_KOULUTUS_3',
        label:
          'Toinen aste suoritettuna (esim. ylioppilastutkinto, ammattikoulu)',
        value: 3
      },
      {
        key: 'T_KOULUTUS_4',
        label: 'Opistoaste suoritettuna',
        value: 4
      },
      {
        key: 'T_KOULUTUS_5',
        label: 'Alempi korkeakoulu tai ammatillinen korkeakoulu suoritettuna',
        value: 5
      },
      {
        key: 'T_KOULUTUS_6',
        label: 'Ylempi korkea-aste suoritettuna',
        value: 6
      },
      {
        key: 'T_KOULUTUS_7',
        label: 'Tutkijakoulutusaste suoritettuna',
        value: 7
      }
    ]
  },
  {
    key: 'T_KODINULKOP',
    main: true,
    title: 'Teetkö ansiotyötä kodin ulkopuolella sijaitsevassa työpaikassa?',
    label: 'Teetkö ansiotyötä kodin ulkopuolella sijaitsevassa työpaikassa?',
    controlType: 'radioControl',
    type: 'radio',
    required: false,
    value: null,
    validation: {},
    next: 'T_ETATYO',
    skip: (answers: Answers) => {
      if (!hasAnswerValue('T_ANSIOTYO', answers)) {
        return true;
      }
      const workingAnswerValue = requireAnswerValue(
        'T_ANSIOTYO',
        answers,
        'T_KODINULKOP'
      ) as number;
      return [1, 2, 3, 4, 5].indexOf(workingAnswerValue) === -1;
    },
    options: [
      {
        key: 'T_KODINULKOP_1',
        label: 'Kyllä, minulla on työnantajani osoittama toimipiste',
        value: 1
      },
      {
        key: 'T_KODINULKOP_2',
        label: 'Kyllä, teen liikkuvaa työtä',
        value: 2
      },
      {
        key: 'T_KODINULKOP_3',
        label: 'En, työskentelen yrittäjänä kotona tai kotitilallani',
        value: 3
      }
    ]
  },
  {
    key: 'T_ETATYO',
    main: true,
    title: 'Kuinka paljon teit etätyötä viimeisen 7 vuorokauden aikana?',
    label: 'Kuinka paljon teit etätyötä viimeisen 7 vuorokauden aikana?',
    controlType: 'radioControl',
    type: 'radio',
    required: false,
    value: null,
    validation: {},
    next: 'T_ETASYYT',
    skip: (answers: Answers) => {
      const areaAnswerValue = requireAnswerValue(
        'h_otosalue_id',
        answers,
        'T_ETATYO'
      ) as number;
      if (!hasAnswerValue('T_ANSIOTYO', answers)) {
        return true;
      }
      const ageAnswerValue = requireAnswerValue('T_IKA', answers, 'T_ETATYO');
      if (ageAnswerValue && ageAnswerValue < 15) {
        return true;
      }
      const workingAnswerValue = requireAnswerValue(
        'T_ANSIOTYO',
        answers,
        'T_ETATYO'
      ) as number;
      return (
        [1, 4, 5, 12, 99].indexOf(areaAnswerValue) === -1 ||
        [1, 2, 3, 4].indexOf(workingAnswerValue) === -1
      );
    },
    options: [
      {
        key: 'T_ETATYO_1',
        label: 'En lainkaan',
        value: 1
      },
      {
        key: 'T_ETATYO_2',
        label: 'Alle 1 päivän',
        value: 2
      },
      {
        key: 'T_ETATYO_3',
        label: '1–2 päivää',
        value: 3
      },
      // {
      //   key: 'T_ETATYO_4',
      //   label: 'Yli 2 päivää',
      //   value: 4
      // },
      {
        key: 'T_ETATYO_5',
        label: '3–4 päivää',
        value: 5
      },
      {
        key: 'T_ETATYO_6',
        label: 'Yli 4 päivää',
        value: 6
      }
    ]
  },
  {
    key: 'T_ETASYYT',
    main: true,
    title:
      'Mitkä ovat tärkeimmät syyt etätyölle? Valitse korkeintaan kolme vaihtoehtoa.',
    label:
      'Mitkä ovat tärkeimmät syyt etätyölle? Valitse korkeintaan kolme vaihtoehtoa.',
    controlType: 'checkboxControl',
    type: 'checkbox',
    required: false,
    value: null,
    resetOption: [1],
    validation: {
      max: 3
    },
    next: null,
    skip: (answers: Answers) => {
      const areaAnswerValue = requireAnswerValue(
        'h_otosalue_id',
        answers,
        'T_ETASYYT'
      ) as number;
      if (!hasAnswerValue('T_ANSIOTYO', answers)) {
        return true;
      }
      const ageAnswerValue = requireAnswerValue('T_IKA', answers, 'T_ETASYYT');
      if (ageAnswerValue && ageAnswerValue < 15) {
        return true;
      }
      const workingAnswerValue = requireAnswerValue(
        'T_ANSIOTYO',
        answers,
        'T_ETASYYT'
      ) as number;
      return (
        [4, 99].indexOf(areaAnswerValue) === -1 ||
        [1, 2, 3, 4].indexOf(workingAnswerValue) === -1
      );
    },
    options: [
      {
        key: 'T_ETASYYT_1',
        label: 'En tee koskaan etätyötä',
        value: 1
      },
      {
        key: 'T_ETASYYT_2',
        label: 'Pitkä työmatka',
        value: 2
      },
      {
        key: 'T_ETASYYT_3',
        label: 'Työrauha',
        value: 3
      },
      {
        key: 'T_ETASYYT_4',
        label: 'Työn luonne',
        value: 4
      },
      {
        key: 'T_ETASYYT_5',
        label: 'Säästyy aikaa matkustamiselta',
        value: 5
      },
      {
        key: 'T_ETASYYT_6',
        label: 'Ruuhka-aikojen välttäminen',
        value: 6
      },
      {
        key: 'T_ETASYYT_7',
        label: 'Perhetilanne',
        value: 7
      },
      {
        key: 'T_ETASYYT_8',
        label: 'Etätyösuositus tai työnantajan toive',
        value: 8
      },
      {
        key: 'T_ETASYYT_9',
        label: 'Muu syy',
        value: 9
      }
    ]
  }
];
