import { pick } from 'lodash';
import { OptionGroupI } from '../interfaces/interface.optionGroupQuestion';
import { OptionValue } from './OptionValue';

export class OptionGroup {
  public key!: string;
  public title!: string;
  public options!: OptionValue[];
  public guardian = false;

  constructor(groups: OptionGroupI, guardian = false) {
    this.guardian = guardian;
    const pickedOption = pick(groups, ['key', 'title', 'options']);
    Object.assign(this, pickedOption);
    return this;
  }

  getTitle() {
    return this.key + '.title';
  }

  toObject() {
    return { ...this };
  }
} // other === true -> value with text field
