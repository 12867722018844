import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import {
  BackgroundReducers,
  HabitReducers,
  UserReducers
} from '@hlt-app/study/reducers';
import { FormController } from '@hlt-app/study/services/form-controller';
import { habitQuestions } from '@hlt-shared/Questions/data/habitQuestions';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HabitQuestionsService extends FormController {
  protected questions = habitQuestions;
  protected claims$ = combineLatest([
    this.store.pipe(select(BackgroundReducers.selectStudyBackgroundAnswers)),
    this.store.pipe(select(UserReducers.selectUserClaims))
  ]).pipe(map((arr) => arr.reduce((a, b) => ({ ...a, ...b }))));
  protected Answers$ = this.store.pipe(
    select(HabitReducers.selectStudyHabitAnswers)
  );
  protected type = 'habit';
  protected completedRoute = '/study/habit-completed';

  constructor(
    protected store: Store,
    protected router: Router,
    protected localize: LocalizeRouterService
  ) {
    super(store, router, localize);
  }
}
