import { DiaryActions } from '@hlt-app/study/actions';
import { getMoment } from '@hlt-shared/Questions/helper/date-helper';
import { createReducer, on } from '@ngrx/store';
import { QuestionsState } from '.';

const initialState: QuestionsState = {
  answers: {},
  index: null,
  error: null,
  pending: false,
  trips: [],
  completed: false,
  noTripsDone: false
};

const reducer = createReducer(
  initialState,
  on(DiaryActions.setDiaryData, (state, { answers, index }) => ({
    ...state,
    answers,
    index,
    pending: false,
    error: null
  })),
  on(DiaryActions.resetDiaryData, (state) => ({
    ...state,
    answers: {},
    index: null,
    pending: false,
    error: null
  })),
  on(DiaryActions.setEditDiaryData, (state, { answers, index }) => ({
    ...state,
    answers,
    index,
    pending: false,
    error: null
  })),
  on(DiaryActions.saveDiary, (state, { answers, index }) => ({
    ...state,
    answers: { ...state.answers, ...answers },
    index,
    pending: true
  })),
  on(DiaryActions.saveDiarySuccess, (state) => ({
    ...state,
    pending: false,
    error: null
  })),
  // tslint:disable-next-line: no-shadowed-variable
  on(DiaryActions.saveDiaryFailure, (state, { error }) => ({
    ...state,
    pending: false,
    error
  })),

  on(DiaryActions.setTripsData, (state, { trips, completed }) => {
    const sortable = [...trips];
    if (sortable.length > 1) {
      sortable.sort((a, b) =>
        getMoment(a.M_LAHTOAIKA).diff(getMoment(b.M_LAHTOAIKA))
      );
    }
    return {
      ...state,
      trips: sortable,
      completed,
      pending: false,
      error: null
    };
  }),
  on(DiaryActions.saveTrip, (state, { trip }) => {
    const trips = [...state.trips, trip];
    if (trips.length > 1) {
      trips.sort((a, b) =>
        getMoment(a.M_LAHTOAIKA).diff(getMoment(b.M_LAHTOAIKA))
      );
    }
    return {
      ...state,
      trips,
      pending: true
    };
  }),
  on(DiaryActions.saveTripsSuccess, (state) => ({
    ...state,
    pending: false,
    error: null
  })),
  // tslint:disable-next-line: no-shadowed-variable
  on(DiaryActions.saveTripsFailure, (state, { error }) => ({
    ...state,
    pending: false,
    error
  })),
  on(DiaryActions.editTrip, (state, { index, trip }) => {
    const newTrips = [...state.trips];
    newTrips[index] = trip;
    return {
      ...state,
      trips: newTrips,
      pending: true
    };
  }),
  on(DiaryActions.deleteTrip, (state, { index }) => {
    const newTrips = [...state.trips];
    newTrips.splice(index, 1);
    return {
      ...state,
      trips: newTrips,
      pending: true
    };
  }),
  on(DiaryActions.setDiaryCompleted, (state, { completed, noTripsDone } ) => ({
    ...state,
    completed,
    noTripsDone
  }))
);

const getAnswers = (state: QuestionsState) => state.answers;
const getIndex = (state: QuestionsState) => state.index;
const getTrips = (state: QuestionsState) => state.trips;
const pending = (state: QuestionsState) => state.pending;
const error = (state: QuestionsState) => state.error;
const completedState = (state: QuestionsState) => state.completed;

export const DiaryQuestionsFeatureKey = 'diaryQuestions';

export const DiaryQuestionsReducer = {
  reducer,
  getAnswers,
  getIndex,
  getTrips,
  pending,
  error,
  completedState
};
