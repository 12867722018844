import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormFactoryService } from '@hlt-app/shared/services/form-factory.service';
import { DiaryReducers } from '@hlt-app/study/reducers';
import { Question } from '@hlt-shared/Questions/classes/Question';
import { select, Store } from '@ngrx/store';
import { latLng } from 'leaflet';
import { Subscription } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'hlt-distance-input',
  templateUrl: './distance-input.component.html',
  styleUrls: ['./distance-input.component.scss']
})
export class DistanceInputComponent implements OnInit, OnDestroy {
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public control: FormControl;
  @Input() public form: FormGroup;
  @Input() public question: Question;
  @Input() public type: string;

  public distanceForm = new FormGroup({
    km: new FormControl(null, [Validators.min(0), Validators.pattern(/^\d+$/)]),
    m: new FormControl(null, [
      Validators.min(0),
      Validators.max(999),
      Validators.pattern(/^\d+$/)
    ])
  });

  private answers$ = this.store.pipe(
    select(DiaryReducers.selectStudyDiaryAnswers)
  );
  private sub: Subscription;

  constructor(
    private store: Store,
    private formFactoryService: FormFactoryService
  ) {}

  ngOnInit(): void {
    // if value set
    if (!!this.control.value) {
      this.splitValueToInputs(this.control.value);
    }

    this.updateControl();

    if (!!this.question && this.question.key === 'M_PITUUS') {
      this.setSiblingValidators();
    }

    if (!!this.question && this.question.key === 'M_PITUUS_ARVIO') {
      // set automatically if not set, HLT-185 change into hidden field
      if (!this.control.value) {
        this.setValueFromCoordinates();
      }
    }

    if (!!this.question && this.question.type === 'hidden') {
      this.type = 'hidden';
    }

    // set zero
    if (
      this.control.value === '0' ||
      this.control.value === 0 ||
      this.control.value === null
    ) {
      this.splitValueToInputs('0');
    }
  }

  get km() {
    return this.distanceForm.get('km') as FormControl;
  }

  get m() {
    return this.distanceForm.get('m') as FormControl;
  }

  getKmAndM(value: number) {
    let km = 0;
    let m: number;
    if (value >= 1000) {
      km = Math.floor(value / 1000);
      m = Math.round(value - km * 1000);
    } else {
      m = Math.round(value);
    }
    return { km, m };
  }

  setKmAndMValues({ km, m }) {
    this.km.setValue(km.toString());
    this.m.setValue(m.toString());
    this.distanceForm.updateValueAndValidity();
  }

  splitValueToInputs(value: number | string) {
    if (typeof value === 'string') {
      value = Number(value);
    }
    this.setKmAndMValues(this.getKmAndM(value));
  }

  updateControl() {
    this.sub = this.distanceForm.valueChanges
      .pipe(
        switchMap((value) => {
          return this.distanceForm.statusChanges.pipe(
            filter((status) => status === 'VALID'),
            map(() => value)
          );
        })
      )
      .subscribe((value) => {
        let totalDistance = 0;
        if (value.km) {
          totalDistance += value.km * 1000;
        }
        if (value.m) {
          totalDistance += value.m * 1;
        }
        this.control.setValue(totalDistance.toString());
        this.control.markAsTouched();
        this.form.updateValueAndValidity();
      });
  }

  convertToNumber(val) {
    if (typeof val === 'string') {
      return Number(val);
    }
    return val;
  }

  setValueFromCoordinates() {
    this.answers$
      .pipe(
        filter((answers) => !!answers),
        filter(
          (answers) =>
            !!answers.M_LOSOITE_LAT &&
            !!answers.M_LOSOITE_LON &&
            !!answers.M_MOSOITE_LAT &&
            !!answers.M_MOSOITE_LON
        ),
        first(),
        map((answers) => {
          const lLat = this.convertToNumber(answers.M_LOSOITE_LAT);
          const lLon = this.convertToNumber(answers.M_LOSOITE_LON);
          const mLat = this.convertToNumber(answers.M_MOSOITE_LAT);
          const mLon = this.convertToNumber(answers.M_MOSOITE_LON);

          const start = latLng({
            lat: lLat,
            lng: lLon
          });
          const end = latLng({
            lat: mLat,
            lng: mLon
          });
          return { start, end };
        }),
        map(({ start, end }) => {
          this.splitValueToInputs(start.distanceTo(end));
        })
      )
      .subscribe();
  }

  setSiblingValidators() {
    if (this.question.siblings.length === 0) {
      return;
    }
    // This a bit dirty hack for HLT-185 to skip sibling validation for the estimate field
    // delete this.question.siblings[0];
    // Variable to use in validation, remove the arvio from variable array when [0] is ARVIO and use the new array for maxCombinedValueValidator
    let validateSiblings = this.question.siblings;
    if (this.question.siblings[0] === 'M_PITUUS_ARVIO') {
      validateSiblings = validateSiblings.slice(1);
    }

    this.form.setValidators([
      this.formFactoryService.maxCombinedValueValidator(
        this.question.key,
        validateSiblings
        )
    ]);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
