import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hlt-answer-time-ended',
  templateUrl: './answer-time-ended.component.html',
  styleUrls: ['./answer-time-ended.component.scss']
})
export class AnswerTimeEndedComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
