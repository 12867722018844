<div class="header">
  <h2 mat-dialog-title class="title">{{ question.getLabel() | translate }}</h2>
  <button class="close-dialog" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content class="container">
  <div
    class="container__form"
    *ngFor="let control of form.controls | keyvalue; let index = index"
  >
    <div [formGroup]="form">
      <label *ngIf="question.fields[index].controlType === 'radioControl'">
        {{ question.fields[index].getLabel() | translate }}
      </label>
      <hlt-question
        [form]="form"
        [question]="question.fields[index]"
      ></hlt-question>
    </div>
  </div>
</div>
<div class="buttons" mat-dialog-actions>
  <hlt-button
    [text]="question.getLabel() | translate"
    (clicked)="submitDialog()"
  ></hlt-button>
</div>
