<ng-container *ngIf="control && control.touched && control.errors">
  <span class="material-icons error" role="alert"
    >error
    <p class="error__text" *ngIf="control.errors.required">
      {{ 'ERRORS.REQUIRED' | translate }}
    </p>
    <p
      class="error__text"
      *ngIf="control.errors.pattern && !control.errors.required"
    >
      <ng-container *ngIf="!password">
        {{ 'ERRORS.NOTVALID' | translate }}
      </ng-container>
      <ng-container *ngIf="password">
        {{ 'ERRORS.PASSWORD' | translate }}
      </ng-container>
    </p>
    <p
      class="error__text"
      *ngIf="control.errors.minlength && !control.errors.required"
    >
      {{
        'ERRORS.MINLENGTH'
          | translate: { value: control.errors.minlength.requiredLength }
      }}
    </p>
    <p
      class="error__text"
      *ngIf="control.errors.maxlength && !control.errors.required"
    >
      {{
        'ERRORS.MAXLENGTH'
          | translate: { value: control.errors.maxlength.requiredLength }
      }}
    </p>
    <p
      class="error__text"
      *ngIf="control.errors.min && !control.errors.required"
    >
      {{ 'ERRORS.MINVALUE' | translate: { value: control.errors.min.min } }}
    </p>
    <p
      class="error__text"
      *ngIf="control.errors.max && !control.errors.required"
    >
      {{ 'ERRORS.MAXVALUE' | translate: { value: control.errors.max.max } }}
    </p>
    <p
      class="error__text"
      *ngIf="control.errors.email && !control.errors.required"
    >
      {{ 'ERRORS.INVALIDEMAIL' | translate }}
    </p>
    <p
      class="error__text"
      *ngIf="control.errors.passwordsMismatch && !control.errors.required"
    >
      {{ 'ERRORS.PASSWORDMATCH' | translate }}
    </p>
    <p
      class="error__text"
      *ngIf="control.errors.maximumChecked && !control.errors.required"
    >
      {{
        'ERRORS.MAXCHECKED'
          | translate: { value: control.errors.maximumChecked.max }
      }}
    </p>
    <p
      class="error__text"
      *ngIf="
        control.errors.requireCheckboxToBeChecked && !control.errors.required
      "
    >
      {{
        'ERRORS.MINCHECKED'
          | translate: { value: control.errors.requireCheckboxToBeChecked.min }
      }}
    </p>
    <p
      class="error__text"
      *ngIf="control.errors.startBeforeEnd && !control.errors.required"
    >
      {{ 'ERRORS.STARTBEFOREEND' | translate }}
    </p>
    <p
      class="error__text"
      *ngIf="control.errors.timeOverlap && !control.errors.required"
    >
      {{ 'ERRORS.TIMESOVERLAP' | translate }}<br />
      {{ control.errors.timeOverlap.from | translate }} -
      {{ control.errors.timeOverlap.to | translate }}
      {{ control.errors.timeOverlap.start }} -
      {{
        control.errors.timeOverlap.nextDay | translate: { date: nextDayString }
      }}
      {{ control.errors.timeOverlap.end }}
    </p>
    <p
      class="error__text"
      *ngIf="control.errors.startInRange && !control.errors.required"
    >
      {{ 'ERRORS.STARTINRANGE' | translate }}<br />
      {{ control.errors.startInRange.from | translate }} -
      {{ control.errors.startInRange.to | translate }}
      {{ control.errors.startInRange.start }} -
      {{
        control.errors.startInRange.nextDay | translate: { date: nextDayString }
      }}
      {{ control.errors.startInRange.end }}
    </p>
    <p
      class="error__text"
      *ngIf="control.errors.formArrayMinimum && !control.errors.required"
    >
      {{
        'ERRORS.FORMARRAYMINIMUM'
          | translate: { value: control.errors.formArrayMinimum.min }
      }}
    </p>
    <p
      class="error__text"
      *ngIf="control.errors.maxCombined && !control.errors.required"
    >
      {{ 'ERRORS.MAXCOMBINED' | translate }}
    </p>
  </span>
</ng-container>
