<div
  class="container"
  [formGroup]="form"
  [ngClass]="{ 'input-map': map, 'distance-container': distance }"
>
  <label
    [ngClass]="{ 'distance-label': distance }"
    class="label"
    *ngIf="label && !hidden"
    [for]="idFor"
    >{{ label }}</label
  >

  <div [ngClass]="{ 'password-container': show }">
    <ng-container *ngIf="!autocomplete">
      <input
        [id]="idFor"
        [ngClass]="{ 'distance-input': distance }"
        [formControl]="control"
        [placeholder]="placeholder"
        [type]="type"
      />
      <button
        class="material-icons visibility visibility_off"
        type="button"
        (click)="changeVisibility()"
        *ngIf="show"
      >
        {{ visible ? 'visibility_off' : 'visibility' }}
      </button>
    </ng-container>
    <ng-container *ngIf="autocomplete">
      <input
        [id]="idFor"
        [ngClass]="{ 'distance-input': distance }"
        [formControl]="control"
        [matAutocomplete]="autocomplete"
        [matAutocompleteConnectedTo]="autocompleteConnectedTo"
        [placeholder]="placeholder"
        [type]="type"
      />
    </ng-container>
  </div>
  <hlt-form-errors [control]="control" [password]="password"></hlt-form-errors>
  <hlt-info-textbox
    *ngIf="infobox"
    [alert]="false"
    [text]="'ERRORS.CHECKVALUE' | translate"
  ></hlt-info-textbox>
</div>
