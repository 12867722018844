<div class="container--small">
  <h1>
    {{
      !guardian
        ? ('COMPLETED.TITLE' | translate)
        : ('COMPLETED.TITLEGUARDIAN' | translate)
    }}
  </h1>
  <p class="description">
    {{
      !guardian
        ? ('COMPLETED.DESCRIPTION' | translate)
        : ('COMPLETED.DESCRIPTIONGUARDIAN' | translate)
    }}
  </p>
  <hlt-route-button
    [text]="
      !guardian
        ? ('BUTTONS.COMPLETEANDSIGNOUT' | translate)
        : ('BUTTONS.COMPLETEANDSIGNOUTGUARDIAN' | translate)
    "
    route="/logout"
  ></hlt-route-button>

  <p *ngIf="phoneUser" class="link">
    <a class="btn-underline" href="/fi/kantar/select-user"
      >Jatka uudella käyttäjällä</a
    >
  </p>
</div>
