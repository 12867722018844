<ng-container [formGroup]="form">
  <hlt-select-input
    [control]="select"
    [form]="form"
    [label]="question.getLabel() | translate"
    [options]="question.options"
    [groups]="question?.groups || null"
  ></hlt-select-input>
  <hlt-form-errors [control]="select"></hlt-form-errors>
</ng-container>
