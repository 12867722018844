import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { OptionQuestion } from '@hlt-shared/Questions/classes/OptionQuestion';
import { OptionValue } from '@hlt-shared/Questions/classes/OptionValue';
import { combineLatest, Subscription } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'hlt-form-checkboxes',
  templateUrl: './form-checkboxes.component.html',
  styleUrls: ['./form-checkboxes.component.scss']
})
export class FormCheckboxesComponent implements OnInit, OnDestroy {
  @Input() public group: FormGroup;
  @Input() public form: FormGroup;
  @Input() public question: OptionQuestion;

  private sub: Subscription;
  private resetOptions: OptionValue[];

  constructor() {}

  get control(): AbstractControl {
    return this.form.get(this.question.key) as AbstractControl;
  }

  getCheck(option) {
    const group = this.control as FormGroup;
    return group.get(option.value.toString());
  }

  ngOnInit(): void {
    if (!!this.question.resetOption && this.question.resetOption.length) {
      this.resetOptions = this.question.resetOption.map((value) =>
        this.question.getOption(value)
      );
      this.subscription();
    }
  }

  subscription() {
    const reset = this.resetOptions.map((option) => {
      return this.getResetControl(option).valueChanges.pipe(
        startWith(this.getResetControl(option).value as boolean),
        debounceTime(300),
        map((value) => ({ value, option }))
      );
    });
    if (reset.length) {
      this.sub = combineLatest(reset)
        .pipe(
          map((values) => {
            const active = values.filter(({ value, option }) => value);
            if (active.length) {
              this.resetOthers(active[0].value, active[0].option);
            } else {
              this.enableAll();
            }
          })
        )
        .subscribe();
    }
  }

  enableAll() {
    this.getAllControls().map((c) => {
      if (!c.enabled) {
        c.enable();
        if (c.value === null) {
          c.setValue(false);
        }
      }
    });

    this.group.updateValueAndValidity();
  }

  resetOthers(value, option) {
    this.getOtherControls(option).map((c) => {
      if (!c.disabled) {
        c.reset();
        c.disable();
      }
    });

    this.group.updateValueAndValidity();
  }

  getAllControls() {
    return this.question.options.map((o) => this.getCheck(o));
  }

  getOtherControls(option) {
    return this.question.options
      .filter((o) => o.value !== option.value)
      .map((o) => this.getCheck(o));
  }

  getResetControl(option) {
    return this.getCheck(option);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
