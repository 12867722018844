<ng-container *ngIf="type !== 'hidden'">
  <form [formGroup]="distanceForm">
    <p class="distance--label">{{ label }}</p>
    <div class="distance--input">
      <hlt-text-input
        [control]="km"
        [form]="distanceForm"
        label="km"
        type="text"
        [distance]="true"
      ></hlt-text-input>
    </div>
    <div class="distance--input">
      <hlt-text-input
        [control]="m"
        [form]="distanceForm"
        label="m"
        type="text"
        [distance]="true"
      ></hlt-text-input>
    </div>
  </form>
  <hlt-form-errors [control]="control"></hlt-form-errors>
</ng-container>
