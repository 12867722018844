import {
  isEmail,
  isEmpty,
  isNumber,
  isNumberString,
  isString,
  matches,
  max,
  maxLength,
  min,
  minLength
} from 'class-validator';
import { cloneDeep, pick } from 'lodash';
import { IQuestion } from '../interfaces/interface.question';
import { TypeAnswer } from '../interfaces/type.answer';
import { TypeControl } from '../interfaces/type.control';
import { TypeInput } from '../interfaces/type.input';
import { Layer } from '../interfaces/type.layers';
import {
  AllowedFunction,
  TypeNextFunction
} from '../interfaces/type.nextFunction';
import { TypeSkipFunction } from '../interfaces/type.skipFunction';

export class Question {
  public key!: string; // id of form field, must be unique
  public main!: boolean;
  public title?: string; // this is the main title
  public content?: string; // this is the main title
  public label!: string; // label for the field group / subtitle
  public placeholder?: string; //
  public suffix?: string; //
  public prependAnswer?: string; //
  public controlType!: TypeControl; // name of the control e.g textControl
  public autocompleteFields?: {
    lat?: string;
    lon?: string;
    localAdmin?: string;
    country?: string;
    length?: string;
  };
  public preFillFields?: {
    street?: string;
    lat?: string;
    lon?: string;
    localAdmin?: string;
    country?: string;
  };
  public autocompleteLayers?: Layer[];
  public type!: TypeInput; // e.g. number, radio, text
  public required!: boolean;
  public value!: TypeAnswer; // answer to question
  public validation!: { [key: string]: string | number }; // Validation; // regex or maybe some descriptive format e.g minSelections:1;maxSelection:3;
  public siblings?: string[]; // array of keys of siblings that should be shown on the same page
  public next?: string | null | TypeNextFunction; // default next is the key of the next question to answer.. look below
  public skip?: TypeSkipFunction;
  public allowed?: boolean | AllowedFunction;
  public guardian = false;

  constructor(question: IQuestion, guardian = false) {
    const pickedQuestion = pick(question, [
      'key',
      'main',
      'title',
      'content',
      'label',
      'placeholder',
      'suffix',
      'prependAnswer',
      'controlType',
      'autocompleteFields',
      'preFillFields',
      'autocompleteLayers',
      'type',
      'required',
      'validation',
      'value',
      'siblings',
      'next',
      'skip',
      'allowed'
    ]);
    this.guardian = guardian;
    Object.assign(this, pickedQuestion);
    return this;
  }

  getTitle() {
    return this.key + (this.guardian ? '.guardian' : '') + '.title';
  }

  getLabel() {
    return this.key + (this.guardian ? '.guardian' : '') + '.label';
  }

  getContent() {
    return this.key + (this.guardian ? '.guardian' : '') + '.content';
  }

  getPlaceholder() {
    return this.key + '.placeholder';
  }

  getPrependAnswer() {
    return this.key + '.prependAnswer';
  }

  getSuffix() {
    return this.key + '.suffix';
  }

  getLabelFromValue(value: any) {
    return value;
  }

  validate(value: TypeAnswer): boolean | string {
    // default value is null
    if (value === null) {
      return true;
    }
    // value should be allowed to be reset

    if (this.required && isEmpty(value)) {
      return `value for ${this.key} is required`;
    }

    if (this.type === 'number' && !isNumberString(value) && !isNumber(value)) {
      return `value for ${this.key} needs to be a number`;
    }

    if (this.type === 'text' && !isString(value) && !isNumber(value)) {
      return `value for ${this.key} needs to be a string`;
    }

    if (this.validation) {
      if (!!this.validation.email && !isEmail(value)) {
        return `value for ${this.key} needs to be a email`;
      }

      if (typeof this.validation.min !== 'undefined') {
        let minimumValue = this.validation.min;
        if (typeof minimumValue === 'string') {
          minimumValue = Number(minimumValue);
        }

        if (!min(value, minimumValue)) {
          return `value for ${this.key} needs to be bigger than ${minimumValue}`;
        }
      }

      if (!!this.validation.max) {
        let maximumValue = this.validation.max;
        if (typeof maximumValue === 'string') {
          maximumValue = Number(maximumValue);
        }

        if (!max(value, maximumValue)) {
          return `value for ${this.key} needs to be smaller than ${maximumValue}`;
        }
      }

      if (!!this.validation.minLength) {
        let minLengthValue = this.validation.minLength;
        if (typeof minLengthValue === 'string') {
          minLengthValue = Number(minLengthValue);
        }
        if (!minLength(value, minLengthValue)) {
          return `string for ${this.key} needs to be longer than ${minLengthValue}`;
        }
      }

      if (!!this.validation.maxLength) {
        let maxLengthValue = this.validation.maxLength;
        if (typeof maxLengthValue === 'string') {
          maxLengthValue = Number(maxLengthValue);
        }
        if (!maxLength(value, maxLengthValue)) {
          return `string for ${this.key} needs to be shorter than ${maxLengthValue}`;
        }
      }

      if (!!this.validation.pattern) {
        if (
          typeof value === 'string' &&
          typeof this.validation.pattern === 'string' &&
          !matches(value, new RegExp(this.validation.pattern))
        ) {
          return `string for ${this.key} must match pattern ${this.validation.pattern}`;
        }
      }
    }
    return true;
  }

  normalize(value: TypeAnswer) {
    if (typeof value === 'string' && isNumberString(value)) {
      return Number(value);
    }
    return value;
  }

  set answer(answer: TypeAnswer) {
    let value = cloneDeep(answer);
    value = this.normalize(value);
    const valid = this.validate(value);
    if (valid === true) {
      this.value = value;
    } else if (typeof valid === 'string') {
      throw new Error(valid);
    }
  }

  get answer() {
    return this.value;
  }

  toObject() {
    return { ...this };
  }
}
