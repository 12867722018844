<div class="container--small">
  <h1>{{ 'USERSELECT.TITLE' | translate }}</h1>
  <form (ngSubmit)="submit()" [formGroup]="userSelectForm">
    <hlt-text-input
      [form]="userSelectForm"
      [label]="'USERSELECT.LABEL' | translate"
      [placeholder]="'ABC123'"
      [control]="hNettitunnus"
      type="text"
    ></hlt-text-input>
    <hlt-button
      [text]="'USERSELECT.SUBMIT' | translate"
      type="submit"
    ></hlt-button>
  </form>

  <div class="error" *ngIf="error">{{ 'USERSELECT.NOTFOUND' | translate }}</div>

  <div class="user" *ngIf="data$ | async as data">
    <div class="user-data">
      <div class="user-data__info">
        <h2>{{ 'USERSELECT.CHOSENUSER' | translate }}</h2>
      </div>

      <div class="user-data__info">
        <label>{{ 'USERSELECT.SURVEYID' | translate }} </label>
        <p>{{ data.h_nettitunnus }}</p>
      </div>

      <div class="user-data__info">
        <label>{{ 'USERSELECT.AREAID' | translate }}</label>
        <p>{{ data.h_otosalue_id }}</p>
      </div>

      <div class="user-data__info">
        <label>{{ 'USERSELECT.CHILD' | translate }}</label>
        <p>
          {{
            data.h_alle_15
              ? ('USERSELECT.YES' | translate)
              : ('USERSELECT.NO' | translate)
          }}
        </p>
      </div>

      <div class="user-data__info">
        <label>{{ 'USERSELECT.TRIPDAY' | translate }}</label>
        <p>
          {{
            data.h_tutkimuspvm._seconds
              ? momentFunc(data.h_tutkimuspvm._seconds * 1000).format(
                  'DD.MM.YYYY'
                )
              : ''
          }}
        </p>
      </div>

      <div class="user-data__info">
        <label>{{ 'USERSELECT.COMPLETED' | translate }}</label>
        <p>
          {{
            data.completed
              ? ('USERSELECT.YES' | translate)
              : ('USERSELECT.NO' | translate)
          }}
        </p>
      </div>
    </div>

    <hlt-route-button
      *ngIf="!data.completed || data.h_verkkoloppu._seconds <= now"
      [route]="'/study'"
      [text]="'USERSELECT.CONTINUETOQUESTIONARE' | translate"
    ></hlt-route-button>
  </div>
</div>
