import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormFactoryService } from '@hlt-app/shared/services/form-factory.service';
import { OptionQuestion } from '@hlt-shared/Questions/classes/OptionQuestion';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'hlt-radio-other-input',
  templateUrl: './radio-other-input.component.html',
  styleUrls: ['./radio-other-input.component.scss']
})
export class RadioOtherInputComponent implements OnInit, OnDestroy {
  @Input() public question: OptionQuestion;
  @Input() public form: FormGroup;
  public disabled = true;
  public group: FormGroup;
  public control: string;
  public otherField: any;
  public subscription: Subscription;

  constructor(private formFactory: FormFactoryService) {}

  ngOnInit(): void {
    this.control = this.question.key;
    this.group = this.form.get(this.control) as FormGroup;
    this.otherField = this.formFactory.getOther(this.question.options);
    this.subscription = this.radio.valueChanges
      .pipe(startWith(this.radio.value as number))
      .subscribe((value) => this.radioValueChange(value));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  radioValueChange(value: string) {
    const intValue = Number(value);
    const option = this.getOption(intValue);
    if (option && option.other && option.other.key === this.otherField.key) {
      this.other.enable();
    } else {
      this.other.reset();
      this.other.disable();
    }
  }

  getOption(value) {
    return this.question.options.find((option) => option.value === value);
  }

  get radio() {
    return this.form.get([this.control, this.question.key]) as FormControl;
  }

  get other() {
    return this.form.get([this.control, this.otherField.key]) as FormControl;
  }
}
