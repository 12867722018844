import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { DiaryReducers } from '@hlt-app/study/reducers';
import { validateTimeArray } from '@hlt-shared/Questions/helper/date-helper';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FormFactoryService {
  private editingIndex: number | null = null;

  constructor(
    private store: Store,
    private translateService: TranslateService
  ) {}

  make(questions, editingIndex = null) {
    this.editingIndex = editingIndex;
    return new FormGroup(this.createQuestions(questions));
  }

  createQuestions(
    questions
  ): {
    [key: string]: AbstractControl;
  } {
    return questions
      .map((question) => this.createFormControl(question))
      .reduce((acc, curr) => ({ ...acc, ...curr }));
  }

  createFormControl(question) {
    if (
      question.value &&
      typeof question.value === 'number' &&
      question.controlType !== 'groupedRadioControl' &&
      question.controlType !== 'radioControl'
    ) {
      question.value = question.value.toString();
    }

    if (
      question.value &&
      typeof question.value === 'string' &&
      question.controlType === 'radioControl' &&
      question.controlType === 'groupedRadioControl'
    ) {
      question.value = Number(question.value);
    }

    let otherQuestionValue = null;
    if (
      question.controlType === 'selectOtherControl' ||
      question.controlType === 'radioOtherControl'
    ) {
      otherQuestionValue = this.getFormState(question.value, question.key);
    }

    if (
      question.controlType === 'selectOtherControl' &&
      otherQuestionValue &&
      typeof otherQuestionValue === 'number'
    ) {
      otherQuestionValue = otherQuestionValue.toString();
    }

    switch (question.controlType) {
      case 'selectControl':
      case 'selectPrefillControl':
      case 'textControl':
      case 'radioControl':
      case 'timeControl':
      case 'groupedRadioControl':
      case 'distanceControl':
        return {
          [question.key]: new FormControl(
            question.value,
            this.getValidations(
              question.validation,
              question.required
            ).validations,
            this.getValidations(
              question.validation,
              question.required
            ).asyncValidations
          )
        };
      case 'selectOtherControl':
      case 'radioOtherControl':
        return {
          [question.key]: new FormGroup({
            [question.key]: new FormControl(
              otherQuestionValue,
              this.getValidations(
                question.validation,
                question.required
              ).validations,
              this.getValidations(
                question.validation,
                question.required
              ).asyncValidations
            ),
            ...this.createFormControl(this.getOther(question.options))
          })
        };
      case 'checkboxControl':
        const checkboxValidations = [];
        if (question.required) {
          checkboxValidations.push(this.atLeastOneCheckboxCheckedValidator(1));
        }
        if (!!question.validation && !!question.validation.max) {
          checkboxValidations.push(
            this.maximumCheckboxCheckedValidator(question.validation.max)
          );
        }
        return {
          [question.key]: new FormGroup(
            question.options
              .map((option) => ({
                [option.value.toString()]: new FormControl(
                  !!this.getFormState(question.value, option.value)
                )
              }))
              .reduce((acc, curr) => ({ ...acc, ...curr })),
            checkboxValidations
          )
        };
      case 'formArrayControl':
        const formArrayValidations = [];

        if (question.required) {
          formArrayValidations.push(this.minimumFormArrayLength(1));
        }
        return {
          [question.key]: new FormArray(
            question.value
              ? question.value.map((values) => {
                  const group = new FormGroup(
                    this.createQuestions(question.fields)
                  );

                  const answerKeys = question.fields.map((val) => val.key);
                  const answersKeys = Object.keys(values);

                  const disable = answerKeys.filter(
                    (val) => answersKeys.indexOf(val) === -1
                  );

                  answersKeys.forEach((key) => {
                    if (
                      values[key] &&
                      typeof values[key] === 'number' &&
                      question.fields.find((q) => q.key === key).controlType !==
                        'groupedRadioControl' &&
                      question.fields.find((q) => q.key === key).controlType !==
                        'radioControl'
                    ) {
                      values[key] = values[key].toString();
                    }
                    group.get(key).setValue(values[key]);
                  });

                  if (disable.length) {
                    disable.map((k) => group.get(k).disable());
                  }

                  return group;
                })
              : [],
            formArrayValidations
          )
        };
      case 'autocompleteControl':
      case 'mapControl':
      case 'datalistControl':
        return {
          [question.key]: new FormControl(
            question.value,
            this.getValidations(
              question.validation,
              question.required
            ).validations,
            this.getValidations(
              question.validation,
              question.required
            ).asyncValidations
          )
        };
    }
  }

  private getFormState(value, key) {
    return value && value.hasOwnProperty(key) && value[key] ? value[key] : null;
  }

  getValidations(values, required = false) {
    const validations = [];
    const asyncValidations = [];
    if (required || values.required) {
      validations.push(Validators.required);
    }
    if (values.email) {
      validations.push(Validators.email);
    }
    if (typeof values.min !== 'undefined') {
      validations.push(Validators.min(values.min));
    }
    if (typeof values.max !== 'undefined') {
      validations.push(Validators.max(values.max));
    }
    if (values.minLength) {
      validations.push(Validators.minLength(values.minLength));
    }
    if (values.maxLength) {
      validations.push(Validators.maxLength(values.maxLength));
    }
    if (values.pattern) {
      validations.push(Validators.pattern(new RegExp(values.pattern)));
    }
    if (values.requiredTrue) {
      validations.push(Validators.requiredTrue);
    }
    if (values.startTime) {
      asyncValidations.push(this.startTimeValidator());
    }
    if (values.endTime) {
      asyncValidations.push(this.endTimeValidator());
    }
    return { validations, asyncValidations };
  }

  getOther(options) {
    return options.filter((o) => o.hasOwnProperty('other') && o.other)[0].other;
  }

  getOtherKey(options) {
    const other = this.getOther(options);
    return other.key;
  }

  private atLeastOneCheckboxCheckedValidator(minRequired = 1): ValidatorFn {
    return function validate(formGroup: FormGroup) {
      let checked = 0;

      Object.keys(formGroup.controls).forEach((key) => {
        const control = formGroup.controls[key];

        if (control.value === true) {
          checked++;
        }
      });

      if (checked < minRequired) {
        return {
          requireCheckboxToBeChecked: {
            min: minRequired
          }
        };
      }

      return null;
    };
  }

  private maximumCheckboxCheckedValidator(maxSelections = 3): ValidatorFn {
    return function validate(formGroup: FormGroup) {
      let checked = 0;

      Object.keys(formGroup.controls).forEach((key) => {
        const control = formGroup.controls[key];

        if (control.value === true) {
          checked++;
        }
      });

      if (checked > maxSelections) {
        return {
          maximumChecked: {
            max: maxSelections
          }
        };
      }

      return null;
    };
  }

  private minimumFormArrayLength(minimum = 1): ValidatorFn {
    return function validate(formArray: FormArray) {
      if (formArray.length < minimum) {
        return {
          formArrayMinimum: {
            min: minimum
          }
        };
      }
      return null;
    };
  }

  maxCombinedValueValidator(main: string, siblings: string[]): ValidatorFn {
    return (form: AbstractControl): { [key: string]: any } | null => {
      let mainControlValue = form.get(main).value;
      const siblingControlsValue = siblings
        .filter((sibling) => !!form.get(sibling))
        .map((sibling) => form.get(sibling).value)
        .reduce((acc, curr) => acc * 1 + curr * 1, 0);

      if (!mainControlValue) {
        mainControlValue = 0;
      } else {
        mainControlValue = mainControlValue * 1;
      }

      const forbidden = !(mainControlValue >= siblingControlsValue);
      return forbidden ? { maxCombined: true } : null;
    };
  }

  private startTimeValidator(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ startInRange?: boolean } | null> => {
      if (!control.value) {
        return null;
      }

      const allTrips$ = this.store.pipe(
        select(DiaryReducers.selectStudyDiaryTrips)
      );

      return combineLatest([allTrips$]).pipe(
        first(),
        map(([allTrips]) => {
          return validateTimeArray(
            allTrips,
            { M_LAHTOAIKA: control.value },
            this.editingIndex
          );
        }),
        map(() => {
          return null;
        }),
        catchError((error: Error) => {
          if (error.name === '3') {
            const msg = error.message.split('|');
            return of({
              startInRange: {
                from: msg[0],
                to: msg[1],
                start: msg[2],
                end: msg[3],
                nextDay: msg[4]
              }
            });
          } else {
            return of(null);
          }
        })
      );
    };
  }

  private endTimeValidator(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{
      startBeforeEnd?: boolean;
      timeOverlap?: boolean;
    } | null> => {
      if (!control.value) {
        return null;
      }

      const next = {
        M_SEURAAVAPAIVA: { '1': false }
      };

      if (control.parent) {
        // @ts-ignore
        next.M_SEURAAVAPAIVA = control.parent.controls.M_SEURAAVAPAIVA.value;
      }

      const allTrips$ = this.store.pipe(
        select(DiaryReducers.selectStudyDiaryTrips)
      );

      const newTrip$ = this.store
        .pipe(select(DiaryReducers.selectStudyDiaryAnswers))
        .pipe(
          map((answers) => ({
            ...answers,
            M_MAARAAIKA: control.value,
            ...next
          }))
        );

      return combineLatest([allTrips$, newTrip$]).pipe(
        first(),
        map(([allTrips, newTrip]) => {
          return validateTimeArray(allTrips, newTrip, this.editingIndex);
        }),
        map(() => {
          return null;
        }),
        catchError((error: Error) => {
          if (error.name === '2') {
            return of({
              startBeforeEnd: true
            });
          } else if (error.name === '4') {
            const msg = error.message.split('|');

            return of({
              timeOverlap: {
                from: msg[0],
                to: msg[1],
                start: msg[2],
                end: msg[3],
                nextDay: msg[4]
              }
            });
          } else {
            return of(null);
          }
        })
      );
    };
  }
}
