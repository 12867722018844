import { IOptionGroupQuestion } from '../interfaces/interface.optionGroupQuestion';
import { OptionGroup } from './OptionGroup';
import { OptionQuestion } from './OptionQuestion';

export class OptionGroupQuestion extends OptionQuestion {
  public groups!: OptionGroup[]; // values for types select, radio, checkbox etc.. next is the key of the next question to answer

  constructor(
    question: IOptionGroupQuestion,
    groups: OptionGroup[],
    guardian = false
  ) {
    const options = groups
      .map((group) => group.options)
      .reduce((acc, curr) => {
        return [...acc, ...curr];
      });
    super(question, options, guardian);
    this.groups = groups;
    return this;
  }

  getGroup(value: number) {
    return this.groups.find((group) => {
      const option = group.options.find((opt) => opt.value === value);
      return !!option;
    });
  }

  getGroupTitleFromValue(value: number) {
    const group = this.getGroup(value);
    if (group) {
      return group.getTitle();
    }
    return '';
  }

  toObject() {
    return { ...this };
  }
}
