<ng-container [ngSwitch]="question?.controlType">
  <h1
    class="question"
    *ngIf="question.main"
    [innerHTML]="question.getTitle() | translate: values"
  ></h1>
  <p class="description" *ngIf="question.main">
    {{ question.getContent() | translate }}
  </p>
  <ng-container *ngSwitchCase="'textControl'">
    <hlt-text-input
      [label]="question.getLabel() | translate"
      [placeholder]="question.getPlaceholder() | translate"
      [control]="control"
      [form]="form"
      [type]="question.type"
      [question]="question"
    ></hlt-text-input>
  </ng-container>
  <ng-container *ngSwitchCase="'distanceControl'">
    <hlt-distance-input
      [label]="question.getLabel() | translate"
      [placeholder]="question.getPlaceholder() | translate"
      [control]="control"
      [form]="form"
      [question]="question"
    ></hlt-distance-input>
  </ng-container>
  <ng-container *ngSwitchCase="'radioControl'">
    <hlt-form-radios
      [control]="control"
      [form]="form"
      [question]="question"
    ></hlt-form-radios>
  </ng-container>
  <ng-container *ngSwitchCase="'groupedRadioControl'">
    <hlt-radio-grouped-input
      [control]="control"
      [form]="form"
      [question]="question"
    ></hlt-radio-grouped-input>
    <hlt-form-errors [control]="control"></hlt-form-errors>
  </ng-container>
  <ng-container *ngSwitchCase="'checkboxControl'">
    <hlt-form-checkboxes
      [question]="question"
      [group]="control"
      [form]="form"
    ></hlt-form-checkboxes>
    <hlt-form-errors [control]="control"></hlt-form-errors>
  </ng-container>
  <ng-container *ngSwitchCase="'selectControl'">
    <hlt-select-input
      [control]="control"
      [form]="form"
      [label]="question.getLabel() | translate"
      [question]="question"
      [options]="question.options"
      [groups]="question?.groups || null"
    ></hlt-select-input>
  </ng-container>
  <ng-container *ngSwitchCase="'selectPrefillControl'">
    <hlt-select-prefill-input
      [form]="form"
      [question]="question"
    ></hlt-select-prefill-input>
  </ng-container>
  <ng-container *ngSwitchCase="'selectOtherControl'">
    <hlt-select-other-input
      [form]="form"
      [question]="question"
    ></hlt-select-other-input>
  </ng-container>
  <ng-container *ngSwitchCase="'radioOtherControl'">
    <hlt-radio-other-input
      [form]="form"
      [question]="question"
    ></hlt-radio-other-input>
  </ng-container>
  <ng-container *ngSwitchCase="'autocompleteControl'">
    <hlt-address-input [question]="question" [form]="form"></hlt-address-input>
  </ng-container>
  <ng-container *ngSwitchCase="'mapControl'">
    <hlt-address-input
      [question]="question"
      [form]="form"
      [map]="true"
    ></hlt-address-input>
  </ng-container>
  <ng-container *ngSwitchCase="'timeControl'">
    <hlt-form-time
      [control]="control"
      [form]="form"
      [label]="question.getLabel() | translate"
    ></hlt-form-time>
  </ng-container>
  <ng-container *ngSwitchCase="'formArrayControl'">
    <hlt-array-question
      [form]="form"
      [question]="question"
    ></hlt-array-question>
  </ng-container>
  <ng-container *ngSwitchCase="'datalistControl'">
    <hlt-address-input
      [question]="question"
      [form]="form"
      [datalist]="true"></hlt-address-input>
  </ng-container>
</ng-container>
