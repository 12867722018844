<button
  [ngClass]="{
    'btn-ghost': ghost,
    btn__undo: undo,
    'btn-nav': nav,
    'btn-menu': menu,
    'btn-link': link,
    'btn-underline': underline,
    'btn-edit': edit,
    'btn-after-input': afterInput
  }"
  class="btn"
  [type]="type === 'submit' ? 'submit' : 'button'"
  (click)="click()"
  [disabled]="disabled"
>
  <ng-container *ngIf="icon && beforeText">
    <mat-icon aria-hidden="false" [attr.aria-label]="icon.label">{{
      icon.icon
    }}</mat-icon>
  </ng-container>
  {{ text }}
  <ng-container *ngIf="icon && !beforeText">
    <mat-icon
      [class]="icon.icon"
      aria-hidden="false"
      [attr.aria-label]="nav ? '' : icon.label"
      >{{ icon.icon }}</mat-icon
    >
  </ng-container>
</button>
