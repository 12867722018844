import { Component, OnInit } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { select, Store } from '@ngrx/store';
import {
  AuthState,
  getChangePasswordStatus,
  getPasswordResetCodeError,
  PasswordManagementActions
} from '@xtream/firebase-ngrx-user-management';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'hlt-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public error$ = this.store.pipe(select(getPasswordResetCodeError));
  public requestStatus$ = this.store.pipe(select(getChangePasswordStatus));

  constructor(
    private store: Store<AuthState>,
    private localize: LocalizeRouterService
  ) {}

  ngOnInit(): void {}

  onSubmit(credentials: { email: string; redirectUrl: string }): void {
    this.store.dispatch(
      new PasswordManagementActions.ResetPasswordRequest({
        email: credentials.email,
        redirectUrl:
          environment.baseUrl + this.localize.translateRoute('/login')
      })
    );
  }
}
