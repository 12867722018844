import { createAction } from '@ngrx/store';

export const logout = createAction('[Auth/Logout] Logout');

export const logoutConfirmation = createAction(
  '[Auth/Logout] Logout Confirmation'
);

export const logoutConfirmationTrue = createAction(
  '[Auth/Logout] Logout Confirmation True'
);

export const logoutConfirmationDismiss = createAction(
  '[Auth/Logout] Logout Confirmation Dismiss'
);
