import {
  PreDiaryQuestionsFeatureKey,
  PreDiaryQuestionsReducer
} from '@hlt-app/study/reducers/prediary-questions.reducer';
import {
  UserFeatureKey,
  UserReducer,
  UserState
} from '@hlt-app/study/reducers/user.reducer';
import { Answers } from '@hlt-shared/Questions/interfaces/type.answer';
import {
  Action,
  combineReducers,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import {
  BackgroundQuestionsFeatureKey,
  BackgroundQuestionsReducer
} from './background-questions.reducer';
import {
  DiaryQuestionsFeatureKey,
  DiaryQuestionsReducer
} from './diary-questions.reducer';
import {
  HabitQuestionsFeatureKey,
  HabitQuestionsReducer
} from './habit-questions.reducer';

export const studyFeatureKey = 'study';

export interface QuestionsState {
  answers: Answers;
  index?: number | null;
  error: string | null;
  pending: boolean;
  trips?: Array<Answers>;
  completed?: boolean;
  noTripsDone?: boolean;
}

export interface StudyState {
  [BackgroundQuestionsFeatureKey]: QuestionsState;
  [HabitQuestionsFeatureKey]: QuestionsState;
  [PreDiaryQuestionsFeatureKey]: QuestionsState;
  [DiaryQuestionsFeatureKey]: QuestionsState;
  [UserFeatureKey]: UserState;
}

export interface State extends StudyState {
  [studyFeatureKey]: StudyState;
}

export function reducers(state: StudyState | undefined, action: Action) {
  return combineReducers({
    [BackgroundQuestionsFeatureKey]: BackgroundQuestionsReducer.reducer,
    [HabitQuestionsFeatureKey]: HabitQuestionsReducer.reducer,
    [PreDiaryQuestionsFeatureKey]: PreDiaryQuestionsReducer.reducer,
    [DiaryQuestionsFeatureKey]: DiaryQuestionsReducer.reducer,
    [UserFeatureKey]: UserReducer.reducer
  })(state, action);
}

export const selectStudyState = createFeatureSelector<State, StudyState>(
  studyFeatureKey
);

const selectStudyBackground = createSelector(
  selectStudyState,
  (state) => state[BackgroundQuestionsFeatureKey]
);

const selectStudyBackgroundAnswers = createSelector(
  selectStudyBackground,
  BackgroundQuestionsReducer.getAnswers
);

const selectStudyBackgroundPending = createSelector(
  selectStudyBackground,
  BackgroundQuestionsReducer.pending
);

const selectStudyBackgroundError = createSelector(
  selectStudyBackground,
  BackgroundQuestionsReducer.error
);

export const BackgroundReducers = {
  selectStudyBackground,
  selectStudyBackgroundAnswers,
  selectStudyBackgroundPending,
  selectStudyBackgroundError
};

const selectStudyHabit = createSelector(
  selectStudyState,
  (state) => state[HabitQuestionsFeatureKey]
);

const selectStudyHabitAnswers = createSelector(
  selectStudyHabit,
  HabitQuestionsReducer.getAnswers
);

const selectStudyHabitPending = createSelector(
  selectStudyHabit,
  HabitQuestionsReducer.pending
);

const selectStudyHabitError = createSelector(
  selectStudyHabit,
  HabitQuestionsReducer.error
);

export const HabitReducers = {
  selectStudyHabit,
  selectStudyHabitAnswers,
  selectStudyHabitPending,
  selectStudyHabitError
};

const selectStudyPreDiary = createSelector(
  selectStudyState,
  (state) => state[PreDiaryQuestionsFeatureKey]
);

const selectStudyPreDiaryAnswers = createSelector(
  selectStudyHabit,
  PreDiaryQuestionsReducer.getAnswers
);

const selectStudyPreDiaryPending = createSelector(
  selectStudyHabit,
  PreDiaryQuestionsReducer.pending
);

const selectStudyPreDiaryError = createSelector(
  selectStudyHabit,
  PreDiaryQuestionsReducer.error
);

export const PreDiaryReducers = {
  selectStudyPreDiary,
  selectStudyPreDiaryAnswers,
  selectStudyPreDiaryPending,
  selectStudyPreDiaryError
};

const selectStudyDiary = createSelector(
  selectStudyState,
  (state) => state[DiaryQuestionsFeatureKey]
);

const selectStudyDiaryAnswers = createSelector(
  selectStudyDiary,
  DiaryQuestionsReducer.getAnswers
);

const selectStudyDiaryIndex = createSelector(
  selectStudyDiary,
  DiaryQuestionsReducer.getIndex
);

const selectStudyDiaryTrips = createSelector(
  selectStudyDiary,
  DiaryQuestionsReducer.getTrips
);

const selectStudyDiaryPending = createSelector(
  selectStudyDiary,
  DiaryQuestionsReducer.pending
);

const selectStudyDiaryError = createSelector(
  selectStudyDiary,
  DiaryQuestionsReducer.error
);

const selectStudyDiaryCompleted = createSelector(
  selectStudyDiary,
  DiaryQuestionsReducer.completedState
);

export const DiaryReducers = {
  selectStudyDiary,
  selectStudyDiaryAnswers,
  selectStudyDiaryIndex,
  selectStudyDiaryTrips,
  selectStudyDiaryPending,
  selectStudyDiaryError,
  selectStudyDiaryCompleted
};

const selectStudyUser = createSelector(
  selectStudyState,
  (state) => state[UserFeatureKey]
);

const selectUserClaims = createSelector(selectStudyUser, UserReducer.getClaims);

const selectUserGuardian = createSelector(
  selectStudyUser,
  UserReducer.getGuardian
);

export const UserReducers = {
  selectStudyUser,
  selectUserClaims,
  selectUserGuardian
};
