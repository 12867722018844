import { NgModule, Provider } from '@angular/core';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import {
  AngularFireFunctionsModule,
  ORIGIN,
  REGION
} from '@angular/fire/functions';
import { FireGuardModule } from '@hlt-app/auth/guards/fire-guard.module';
import 'firebase/firestore';
import { environment } from '../../environments/environment';

const MODULES = [
  AngularFirestoreModule,
  AngularFireAuthModule,
  AngularFireFunctionsModule,
  FireGuardModule
];

let PROVIDERS: Provider[] = [{ provide: REGION, useValue: 'europe-west1' }];
if (!environment.production) {
  PROVIDERS = [
    { provide: REGION, useValue: 'europe-west1' },
    { provide: ORIGIN, useValue: 'http://localhost:5001' }
  ];
}

@NgModule({
  imports: MODULES,
  exports: MODULES,
  providers: PROVIDERS
})
export class FireModule {}
