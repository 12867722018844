<label class="form-radio" [formGroup]="group">
  <input
    [formControlName]="name"
    [attr.name]="name"
    [value]="value"
    [attr.disabled]="disabled"
    class="form-radio__input"
    type="radio"
  />
  <span class="form-radio__dummy" aria-hidden="true"></span>
  <span class="form-radio__label">{{ label }}</span>
</label>
