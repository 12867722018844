import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'hlt-form-time-input',
  templateUrl: './form-time-input.component.html',
  styleUrls: ['./form-time-input.component.scss']
})
export class FormTimeInputComponent implements AfterViewInit, OnDestroy {
  @Input() public hours: FormControl;
  @Input() public minutes: FormControl;
  @Input() public form: FormGroup;
  @Input() public label: string;

  @ViewChild('minutesEl') minutesEl;
  @ViewChild('hourEl') hourEl;

  private inspectKeyCodeToKeyMap = {
    96: '0',
    97: '1',
    98: '2',
    99: '3',
    100: '4',
    101: '5',
    102: '6',
    103: '7',
    104: '8',
    105: '9'
  };

  private hourSub: Subscription;
  private minuteSub: Subscription;
  private initNoFocus = true;

  constructor() {}

  normalizeValues(value) {
    return ('0' + value).slice(-2);
  }

  ngAfterViewInit(): void {
    this.hourSub = this.hours.valueChanges
      .pipe(
        startWith(this.hours.value as string),
        filter((value) => {
          if (!value) {
            this.initNoFocus = false;
          }
          return !!value;
        }),
        map((value) => {
          let valueNumber = value;
          if (typeof valueNumber === 'string') {
            valueNumber = Number(valueNumber);
          }

          const lastChar = value.slice(-1);
          const values = Object.values(this.inspectKeyCodeToKeyMap);
          if (values.indexOf(lastChar) === -1 || value.length > 2) {
            return this.hours.setValue(value.slice(0, -1));
          }

          if (value.length === 1 && valueNumber >= 3) {
            this.hours.setValue(this.normalizeValues(value));
            this.minutesEl.nativeElement.focus();
            return;
          }

          if (value.length === 2 && valueNumber >= 24) {
            this.hours.setValue('23');
            this.minutesEl.nativeElement.focus();
            return;
          }

          if (value.length === 2 && !this.initNoFocus) {
            this.minutesEl.nativeElement.focus();
          } else {
            this.initNoFocus = false;
          }
        })
      )
      .subscribe();

    this.minuteSub = this.minutes.valueChanges
      .pipe(
        startWith(this.minutes.value as string),
        filter((value) => !!value),
        map((value) => {
          let valueNumber = value;
          if (typeof valueNumber === 'string') {
            valueNumber = Number(valueNumber);
          }

          const lastChar = value.slice(-1);
          const values = Object.values(this.inspectKeyCodeToKeyMap);
          if (values.indexOf(lastChar) === -1 || value.length > 2) {
            return this.minutes.setValue(value.slice(0, -1));
          }

          if (value.length === 1 && valueNumber >= 6) {
            this.minutes.setValue(this.normalizeValues(value));
            return;
          }

          if (value.length === 2 && valueNumber >= 60) {
            this.minutes.setValue('59');
            return;
          }
        })
      )
      .subscribe();
  }

  focusHour() {
    this.hourEl.nativeElement.focus();
  }

  leadingZeroes(control: FormControl) {
    if (!!control.value && control.value.length === 1) {
      control.setValue(this.normalizeValues(control.value));
    }
  }

  select($event) {
    if (($event.target as Element).tagName.toLowerCase() === 'input') {
      $event.target.select();
    }
  }

  ngOnDestroy() {
    this.hourSub.unsubscribe();
    this.minuteSub.unsubscribe();
  }
}
