<mat-form-field appearance="none">
  <mat-select
    [value]="currentLang"
    (selectionChange)="changeLang($event.value)"
  >
    <mat-option *ngFor="let lang of languages" [value]="lang">
      <a class="mat-button language-links" [href]="changeLang(lang)">{{
        lang
      }}</a>
    </mat-option>
  </mat-select>
</mat-form-field>
