import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Answers } from '@hlt-shared/Questions/interfaces/type.answer';
import { map, switchMap } from 'rxjs/operators';

interface UserAnswers {
  background?: Answers;
  habits?: Answers;
  preDiary?: Answers;
  diary?: {
    completed: boolean;
    noTripsDone: boolean;
    trips: Array<any>;
    currentTrip: Answers;
    currentTripIndex: number | null;
  };
}

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  constructor(
    private afStore: AngularFirestore,
    private fns: AngularFireFunctions
  ) {}

  getAnswers(hNettitunnus: string) {
    return this.afStore
      .collection('users')
      .doc<UserAnswers>(hNettitunnus)
      .valueChanges();
  }

  getNettitunnus(uid) {
    return this.afStore
      .collection('phonemeta')
      .doc<{ current: string }>(uid)
      .get()
      .pipe(map((value) => value.data()));
  }

  getUser(hNettitunnus: string) {
    const callable = this.fns.httpsCallable('getUser');
    return callable({ h_nettitunnus: hNettitunnus });
  }

  getSelectedUser(uid: string) {
    return this.getNettitunnus(uid).pipe(
      switchMap((value) => {
        return this.getUser(value.current);
      })
    );
  }

  getGuardian(hNettitunnus: string) {
    return this.afStore
      .collection('usermeta')
      .doc(hNettitunnus)
      .get()
      .pipe(
        map((data) => data.data()),
        map((value: any) => {
          if (typeof value === 'undefined') {
            return null;
          }
          return typeof value.guardian === 'undefined'
            ? null
            : !!value.guardian;
        })
      );
  }

  setGuardian(value: { guardian: boolean; h_nettitunnus: string }) {
    const callable = this.fns.httpsCallable('setMeta');
    return callable(value);
  }

  setLogin(value: {
    login: { time: number; browser: string };
    h_nettitunnus: string;
  }) {
    const callable = this.fns.httpsCallable('setMeta');
    return callable(value);
  }

  saveBackgroundAnswers(value: { answers: Answers; h_nettitunnus: string }) {
    const callable = this.fns.httpsCallable('saveBackground');
    return callable(value);
  }

  saveHabitsAnswers(value: { answers: Answers; h_nettitunnus: string }) {
    const callable = this.fns.httpsCallable('saveHabits');
    return callable(value);
  }

  savePreDiaryAnswers(value: { answers: Answers; h_nettitunnus: string }) {
    const callable = this.fns.httpsCallable('savePreDiary');
    return callable(value);
  }

  saveTripAnswers(value: {
    answers: Answers;
    index: number | null;
    h_nettitunnus: string;
  }) {
    const callable = this.fns.httpsCallable('saveTrip');
    return callable(value);
  }

  saveDiaryAnswers(value: { trips: Answers[]; h_nettitunnus: string }) {
    const callable = this.fns.httpsCallable('saveDiary');
    return callable(value);
  }

  setDiaryCompleted(value: { completed: boolean; h_nettitunnus: string; noTripsDone: boolean }) {
    const callable = this.fns.httpsCallable('setDiaryCompleted');
    return callable(value);
  }

  setQuestionareCompleted(value: { h_nettitunnus: string }) {
    const callable = this.fns.httpsCallable('setQuestionareCompleted');
    return callable(value);
  }

  setDraw(value: { data: any }) {
    const callable = this.fns.httpsCallable('setDraw');
    return callable(value);
  }
}
